import GoogleMapReact from 'google-map-react';
import marker from '../../../svg/marker.svg';
import '../../../Styles/FrecuentLocationsMap.css';



export const FrecuentLocationsMap = (props:any) => {
  
     
     const Marker = ({ text }:any) => <div><img  className="marker" alt='marker' src={marker} style={{ width: '30px' }}/>{text}</div>;

    return (
        <div style={{ height: '100%', width: '100%', borderRadius: 10 }} className="map-div">
            <GoogleMapReact
          bootstrapURLKeys={{ key:`${process.env.REACT_APP_GOOGLE_MAPS_KEY}`}}
          defaultCenter={props.center}
          defaultZoom={props.zoom}
        >
          <Marker
            lat={props.data.latitude}
            lng={props.data.longitude}
            text="marker"
          />
        </GoogleMapReact>
        </div>
    )
}