import Modal from '@mui/material/Modal';
import axios from 'axios';

//Services
import { succesVariable, errorDeLogin } from '../Toastifys/EspecialityToasts';
//svg
import seeNew from '../../../svg/seeNew.svg';
import resetPass from '../../../svg/resetPass.svg';



const UsersModalResetPass = ({userSelected, openModalResetPass, setOpenModalResetPass, userToken}:any) => {

    //Reset the password (button accept)
    const resetPassword = () => {

        const resetP = async(id:string) => {
            await axios.put(`${process.env.REACT_APP_ORGANIZATION_AVE_RESET_PASS_USERADMIN}${id}`,{},{headers:{"Authorization":`Bearer ${userToken}`}})
            .then((response)=>{
                succesVariable("La contraseña fue reiniciada")
                setOpenModalResetPass(false)
            }).catch((error)=>{
                console.log(error);
                errorDeLogin("Error reiniciando la contraseña")
            })
        }
        resetP(userSelected.id)
    };

    return(
        <Modal open={openModalResetPass}
            onClose={()=>setOpenModalResetPass(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-Modal">
                    <div className="modal-confirmation-title-reset-pass" id="modal-modal-title">
                        <img src={resetPass} alt="desactivate icon" className="reset-pass-modal-img"/>
                        <p className="title">¿Desea reiniciar la contraseña de <span className='text-highlight'>{userSelected.nombre}</span>?</p>
                    </div>  
                    <div className='modal-description-text' id="modal-modal-subtitlee">
                        <p > Se enviara una nueva a su correo <span className='text-highlight'>{userSelected.email}</span></p>
                    </div>               
                    <div className="modal-container-btns">
                        <button className='modal-btn-cancelar' onClick={()=>setOpenModalResetPass(false)}>Cancelar</button>
                        <button className="modal-btn-aceptar" onClick={()=>resetPassword()}>Aceptar</button>
                    </div> 
                </div>
        </Modal>
    )
}

export default UsersModalResetPass;