import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import '../../Styles/Pagination.css';
import arrow from '../../svg/Page.svg';
import arrowInactive from '../../svg/arrowPaginationInactive.svg';


export default function Pagination({total=0, itemsPerPage=9, currentPage=1, onPageChange}:any) {

  const [totalPages, setTotalPages] = useState(0);
  
  useEffect(()=>{
      if(total > 0 && itemsPerPage > 0)
          setTotalPages(Math.ceil(total / itemsPerPage));
             
  }, [total, itemsPerPage]);

  const paginationItems = useMemo(()=>{
      const pages = [];
      for(let i=1; i <= totalPages; i++){
          pages.push(
          <li 
          key={i} 
          active={i===currentPage} 
          className={i === currentPage ? "page-item active" : "page-item"}>
              <a className="page-link" href='javascript:void(0)' onClick={()=> onPageChange(i)} >{i}</a>
          </li>)
      }
      return pages;
  }, [totalPages, currentPage]);

  if(totalPages === 0 )return null;

  

  return (
    <nav className="pagination-nav" aria-label="Page navigation example" >
  <ul className="pagination">
    <li className="page-item">
      <a className={currentPage === 1 ? "page-link disabled" : "page-link" } href='javascript:void(0)' aria-label="Previous" onClick={()=> onPageChange(currentPage - 1)} >
        <img src={currentPage ===1 ? arrowInactive : arrow} className="arrow-back" alt="arrow navigation"/>
      </a>
    </li>
        {paginationItems}
    <li className="page-item">
      <a className={currentPage === totalPages ? "page-link disabled" : "page-link"} href='javascript:void(0)'  aria-label="Next"  onClick={()=> onPageChange(currentPage + 1)}>
      <img src={currentPage === totalPages ? arrowInactive : arrow} alt="arrow navigation" />
      </a>
    </li>
  </ul>
</nav>
  );
}