import Eliminar from '../../../svg/trashNew.svg';
import Desactivar from '../../../svg/dontNew.svg';
import Activar from '../../../svg/checkNew.svg';
import Editar from '../../../svg/pencilNew.svg';
import Agregar from '../../../svg/addNew.svg';
import Ver from '../../../svg/seeNew.svg';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { templateRoles } from './rolesTemplate';

const RolesCheckboxes = ({actions, checkHandlerFather, rolesFunctions}:any) => {
    let icons:any= [{name:"Eliminar", src:Eliminar}, {name:"Desactivar", src:Desactivar}, {name:"Activar", src:Activar}, {name:"Editar", src:Editar}, {name:"Agregar", src:Agregar}, {name:"Ver", src:Ver} ];

    //UseSelector of Roles Functions to render the modal of new roles
    const roleByIdToEdit = useSelector((state:any)=> state.users.roleToEdit[0]);
    
    const[editState, setEditState]= useState(roleByIdToEdit);
    const changinEditState = (e:any)=>{
    }

    

    return(
                        <div className="modalAddRole-father-container" >
                            {rolesFunctions.length > 1 && rolesFunctions.map((f:any)=>{
                                return(
                                    <div className="inputs-container-per-role" id={f.code}>
                                        <div className="modalAddRole-input-icon-label-container">
                                            <input name={f.code} className="modalAddRole-check-father"  type='checkbox' onChange={(e)=>{checkHandlerFather(e, "father"); changinEditState(e)}}></input>
                                            <label className="modalAddRole-label-father">{f.name}</label> 
                                        </div>
                                        {f.options.map((o:any)=>{
                                            return(
                                                <div className="modalAddRole-input-icon-label-container">
                                                    <input className="modalAddRole-check" name={f.code} type='checkbox' id={o.icon} disabled={actions[f.code]=== true ? false : true} onChange={(e:any)=>checkHandlerFather(e, "son")}></input>
                                                    {icons.map((i:any)=>{if(o.name===i.name)return (<img className="modalAddRole-icon" alt='icon' src={`${i.src}`}/>)})}
                                                    <label className="modalAddRole-label">{o.name}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
    )
}

export default RolesCheckboxes;