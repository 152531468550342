import {types} from '../types/types';
import actions from "../actions_types/actions_types";
import jwt_decode from 'jwt-decode';
import { logout } from '../actions/actions';


const initialState = {
    logData:{},
    users:[]
};


const token = localStorage.getItem('token')
if(token){
    try{
        const jwt_decoded:any= jwt_decode(token);
        const timeToExpire:any = jwt_decoded.exp - Date.now();

        if(timeToExpire > 0){
            setTimeout(()=>{
                logout()
            }, 5000);
        }
    }catch(error){
        console.log("error parsing token:",error)
    }
}





export const loginReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actions.LOGIN:
          return {        
            ...state,
            logData:{
                token: action.payload.token
            },
          }; 
        case actions.LOG_OUT:
          return {
            state: {}
          };

    
        default:
          return state;
      }
    };