import Modal from '@mui/material/Modal';
import axios from 'axios';
//Hooks
import { useDispatch } from 'react-redux';
//Functions and services
import { getUsers } from '../../../actions/actions';
import { errorActivandoUsuarioAdmin, usuarioAdminActivado } from '../Toastifys/EspecialityToasts';
//Styles
import '../../../Styles/Users.css';
//SVG and Images
import checkNew from '../../../svg/checkNew.svg';

const UserModalActive = ({openModalActive, setOpenModalActive, userSelected, userToken}:any)=>{

    //Use dispatch hook
    const dispatch = useDispatch();
    //Button of Modal Activate User ACCEPT
    const handleClickBtnModalAceptarActive= ( ) =>{
        const activateUser = async (userSelected:any)=> {
            let postToSend = {is_verified: true};
            try{
                const {data}:{data:any}= await axios.put(`${process.env.REACT_APP_ORGANIZATION_AVE_CHANGE_STATUS_USER_ADMIN}${userSelected.id}`, postToSend, {headers:{"Authorization":`Bearer ${userToken}`}});
                if(data.msg==="Se proceso correctamente"){
                    dispatch(getUsers(userToken));
                    setOpenModalActive(false)
                    usuarioAdminActivado()
                }else{
                    dispatch(getUsers(userToken));
                    setOpenModalActive(false)
                    errorActivandoUsuarioAdmin()
                }
            }catch(error){
                console.log(error)
            }
        }
        activateUser(userSelected)
    };

    return(
        <Modal open={openModalActive}
            onClose={()=>setOpenModalActive(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-Modal">
                    <div id="modal-modal-title">
                        <img src={checkNew} alt="activate Icon" className="check-modal-icon"/>
                         <p>¿Desea activar al usuario?</p>
                    </div>
                    <div id="modal-modal-description">
                        <div className='modal-description-text'>
                            <p>Si acepta, el usuario <span className='text-highlight'>{userSelected.nombre} {userSelected.apellido}</span>  podrá utilizar la app con normalidad. ¿Esta seguro que desea continuar?</p>               
                        </div>
                        <div className="modal-container-btns">
                            <button className='modal-btn-cancelar' onClick={()=>setOpenModalActive(false)}>Cancelar</button>
                            <button className="modal-btn-aceptar" onClick={()=>handleClickBtnModalAceptarActive()}>Aceptar</button>
                        </div>
                    </div>
                </div>
        </Modal>
    )
}

export default UserModalActive;