//All the icons
import Actividad from '../svg/icons/ACTIVIDAD FISICA.svg';
import Adelante from '../svg/icons/ADELANTE.svg';
import Adjuntar from '../svg/icons/ADJUNTAR DOCUMENTO.svg';
import Agua from '../svg/icons/AGUA.svg';
import Alcohol from '../svg/icons/ALCOHOL.svg';
import ALERTA1 from '../svg/icons/ALERTA 1.svg';
import ALERTA2 from '../svg/icons/ALERTA 2.svg';
import anticonceptivo from '../svg/icons/ANTICONCEPTIVO.svg';
import Atrás from '../svg/icons/ATRAS.svg';
import Baño from '../svg/icons/BAÑO.svg';
import Bebé from '../svg/icons/BEBE.svg';
import Bolsa from '../svg/icons/BOLSA.svg';
import Búsqueda from '../svg/icons/BUSQUEDA.svg';
import Calendario from '../svg/icons/CALENDARIO.svg';
import Cámara from '../svg/icons/CAMARA.svg';
import Cancelar from '../svg/icons/CANCELAR.svg';
import Candado from '../svg/icons/CANDADO.svg';
import Cargando from '../svg/icons/CARGANDO.svg';
import Carpeta from '../svg/icons/CARPETA.svg';
import Centro from '../svg/icons/CENTRO ATENCION.svg';
import CerrarSesión from '../svg/icons/CERRAR SESION.svg';
import Cerrar from '../svg/icons/CERRAR.svg';
import Canal from '../svg/icons/CHAT.svg';
import Check from '../svg/icons/CHECK.svg';
import Cirugía from '../svg/icons/CIRUGIA.svg';
import Cobertura from '../svg/icons/COBERTURA.svg';
import Comentario from '../svg/icons/COMENTARIO.svg';
import Configuración from '../svg/icons/CONFIGURACION.svg';
import Consulta from '../svg/icons/CONSULTA.svg';
import dental from '../svg/icons/CONTROL DENTAL.svg';
import Controlmédico from '../svg/icons/CONTROL MEDICO.svg';
import Copiar from '../svg/icons/COPIAR.svg';
import Corazón from '../svg/icons/CORAZON.svg';
import DATOSPERSONALES2 from '../svg/icons/DATOS PERSONALES 2.svg';
import DATOSPERSONALES from '../svg/icons/DATOS PERSONALES.svg';
import Descanso from '../svg/icons/DESCANSO.svg';
import Discapacidad from '../svg/icons/DISCAPACIDAD.svg';
import Discord from '../svg/icons/DISCORD.svg';
import Editar from '../svg/icons/EDITAR.svg';
import Embarazo from '../svg/icons/EMBARAZO.svg';
import Enfermedad from '../svg/icons/ENFERMEDADES.svg';
import Enviar from '../svg/icons/ENVIAR.svg';
import ESPECIALIDAD2 from '../svg/icons/ESPECIALIDAD 2.svg';
import ESPECIALIDAD from '../svg/icons/ESPECIALIDAD.svg';
import Estatura from '../svg/icons/ESTATURA.svg';
import Estrella from '../svg/icons/ESTRELLA.svg';
import Estudio from '../svg/icons/ESTUDIO.svg';
import Exportar from '../svg/icons/EXPORTAR.svg';
import Filtros from '../svg/icons/FILTROS.svg';
import Flechaatrás from '../svg/icons/FLECHA ATRAS.svg';
import Gmail from '../svg/icons/GMAIL.svg';
import Hijos from '../svg/icons/HIJOS.svg';
import Home from '../svg/icons/HOME.svg';
import Horario from '../svg/icons/HORARIO.svg';
import Flechaadelante from '../svg/icons/ICONO FLECHA ADELANTE.svg';
import Perfil from '../svg/icons/PERFIL2.svg';
import Info from '../svg/icons/INFO.svg';
import Internación from '../svg/icons/INTERNACION.svg';
import Intolerancia from '../svg/icons/INTOLERANCIA.svg';
import Llamada from '../svg/icons/LLAMADA.svg';
import Mail from '../svg/icons/MAIL CONTACTO.svg';
import Mapa from '../svg/icons/MAPA.svg';
import Matrícula from '../svg/icons/MATRICUA.svg';
import Medicación from '../svg/icons/MEDICACION.svg';
import Menstruación from '../svg/icons/MENSTRUACION.svg';
import MenuHamburguesa from '../svg/icons/Menu hamburg off.svg';
import MenuHamburguesa2 from '../svg/icons/Menu hamburg on.svg';
import Menútrespuntos from '../svg/icons/MENU TRES PUNTOS.svg';
import Pacientes from '../svg/icons/PACIENTES.svg';
import Pausa from '../svg/icons/PAUSA.svg';
import PDF from '../svg/icons/PDF.svg';
import Peso from '../svg/icons/PESO.svg';
import Plus from '../svg/icons/PLUS.svg';
import Firmadigital from '../svg/icons/Property 1=firma digital.svg';
import Historiaclínica from '../svg/icons/Property 1=Historia clinica 1.svg';
import Historiaclínica2 from '../svg/icons/Property 1=Historia clinica 2.svg';
import Borrar from '../svg/icons/Property 1=ICONO BORRAR 2.svg';
import CheckCuadrolleno from '../svg/icons/Property 1=ICONO CHECK LLENO.svg';
import Borrar2 from '../svg/icons/Property 1=ICONO, Property 2=BORRAR.svg';
import CheckCuadrovacio from '../svg/icons/Property 1=ICONO, Property 2=CHECK VACIO.svg';
import Firmadigital2 from '../svg/icons/Property 1=ICONO, Property 2=FIRMA DIGITAL 2.svg';
import Verificado1 from '../svg/icons/Property 1=NO VERIFICADO.svg';
import Visible2 from '../svg/icons/Property 1=NO VISIBLE.svg';
import Mail1 from '../svg/icons/Property 1=Off.svg';
import Mail2 from '../svg/icons/Property 1=On.svg';
import Verificado2 from '../svg/icons/Property 1=VERIFICADO.svg';
import Visible1 from '../svg/icons/Property 1=VISIBLE.svg';
import QR from '../svg/icons/QR.svg';
import Receta from '../svg/icons/RECETA.svg';
import Recreacion from '../svg/icons/RECREACION.svg';
import Reseña from '../svg/icons/RESEÑA.svg';
import Sal from '../svg/icons/SAL.svg';
import Síntomas from '../svg/icons/SINTOMAS.svg';
import Notificación1 from '../svg/icons/Status=Off.svg';
import Notificación2 from '../svg/icons/Status=On.svg';
import Tabaco from '../svg/icons/TABACO.svg';
import Tarjeta from '../svg/icons/TARJETA.svg';
import Teléfonocontacto from '../svg/icons/TELEFONO CONTACTO.svg';
import Telegram from '../svg/icons/TELEGRAM.svg';
import Ubicación from '../svg/icons/UBICACION.svg';
import Vacaciones from '../svg/icons/VACIONES.svg';
import Virus from '../svg/icons/VIRUS.svg';
import Whatsapp from '../svg/icons/WHATSAPP.svg';





export const iconsFolder = [
    {
        path:'../../svg/icons/ACTIVIDAD FISICA.svg',
        name:"Actividad física",
        src: Actividad
        
    },
    {
        path:'../../svg/icons/ADELANTE.svg',
        name:"Adelante (16x16 px)",
        src: Adelante
    },
    {
        path:'../../svg/icons/ADJUNTAR DOCUMENTO.svg',
        name:"Adjuntar doc",
        src: Adjuntar
    },
    {
        path:'../../svg/icons/AGUA.svg',
        name:"Agua",
        src:Agua
    },
    {
        path:'../../svg/icons/ALCOHOL.svg',
        name:"Alcohol",
        src:Alcohol
    },
    {
        path:'../../svg/icons/ALERTA 1.svg',
        name:"ALERTA1",
        src:ALERTA1
    },
    {
        path:'../../svg/icons/ALERTA 2.svg',
        name:"ALERTA2",
        src:ALERTA2
    },
    {
        path:'../../svg/icons/ANTICONCEPTIVO.svg',
        name:"Método anticonceptivo",
        src:anticonceptivo
    },
    {
        path:'../../svg/icons/ATRAS.svg',
        name:"Atrás (16x16 px)",
        src:Atrás
    },
    {
        path:'../../svg/icons/BAÑO.svg',
        name:"Baño",
        src:Baño
    },
    {
        path:'../../svg/icons/BEBE.svg',
        name:"Bebé",
        src:Bebé
    },
    {
        path:'../../svg/icons/BOLSA.svg',
        name:"Bolsa",
        src:Bolsa
    },
    {
        path:'../../svg/icons/BUSQUEDA.svg',
        name:"Búsqueda",
        src:Búsqueda
    },
    {
        path:'../../svg/icons/CALENDARIO.svg',
        name:"Calendario",
        src:Calendario
    },
    {
        path:'../../svg/icons/CAMARA.svg',
        name:"Cámara",
        src:Cámara
    },
    {
        path:'../../svg/icons/CANCELAR.svg',
        name:"Cancelar",
        src:Cancelar
    },
    {
        path:'../../svg/icons/CANDADO.svg',
        name:"Candado",
        src:Candado
    },
    {
        path:'../../svg/icons/CARGANDO.svg',
        name:"Cargando",
        src:Cargando
    },
    {
        path:'../../svg/icons/CARPETA.svg',
        name:"Carpeta",
        src:Carpeta
    },
    {
        path:'../../svg/icons/CENTRO ATENCION.svg',
        name:"Centro de atención",
        src:Centro
    },
    {
        path:'../../svg/icons/CERRAR SESION.svg',
        name:"Cerrar Sesión",
        src:CerrarSesión
    },
    {
        path:'../../svg/icons/CERRAR.svg',
        name:"Cerrar",
        src:Cerrar
    },
    {
        path:'../../svg/icons/CHAT.svg',
        name:"Canal",
        src:Canal
    },
    {
        path:'../../svg/icons/CHECK.svg',
        name:"Check",
        src:Check
    },
    {
        path:'../../svg/icons/CIRUGIA.svg',
        name:"Cirugía",
        src:Cirugía
    },
    {
        path:'../../svg/icons/COBERTURA.svg',
        name:"Cobertura",
        src:Cobertura
    },
    {
        path:'../../svg/icons/COMENTARIO.svg',
        name:"Comentario",
        src:Comentario
    },
    {
        path:'../../svg/icons/CONFIGURACION.svg',
        name:"Configuración",
        src:Configuración
    },
    {
        path:'../../svg/icons/CONSULTA.svg',
        name:"Consulta",
        src:Consulta
    },
    {
        path:'../../svg/icons/CONTROL DENTAL.svg',
        name:"Control dental",
        src:dental
    },
    {
        path:'../../svg/icons/CONTROL MEDICO.svg',
        name:"Control médico",
        src:Controlmédico
    },
    {
        path:'../../svg/icons/COPIAR.svg',
        name:"Copiar",
        src:Copiar
    },
    {
        path:'../../svg/icons/CORAZON.svg',
        name:"Corazón",
        src:Corazón
    },
    {
        path:'../../svg/icons/DATOS PERSONALES 2.svg',
        name:"DATOS PERSONALES 2",
        src:DATOSPERSONALES2
    },
    {
        path:'../../svg/icons/DATOS PERSONALES.svg',
        name:"DATOS PERSONALES",
        src:DATOSPERSONALES
    },
    {
        path:'../../svg/icons/DESCANSO.svg',
        name:"Descanso",
        src:Descanso
    },
    {
        path:'../../svg/icons/DISCAPACIDAD.svg',
        name:"Discapacidad",
        src:Discapacidad
    },
    {
        path:'../../svg/icons/DISCORD.svg',
        name:"Discord",
        src:Discord
    },
    {
        path:'../../svg/icons/EDITAR.svg',
        name:"Editar",
        src:Editar
    },
    {
        path:'../../svg/icons/EMBARAZO.svg',
        name:"Embarazo",
        src:Embarazo
    },
    {
        path:'../../svg/icons/ENFERMEDADES.svg',
        name:"Enfermedad",
        src:Enfermedad
    },
    {
        path:'../../svg/icons/ENVIAR.svg',
        name:"Enviar",
        src:Enviar
    },
    {
        path:'../../svg/icons/ESPECIALIDAD 2.svg',
        name:"ESPECIALIDAD 2",
        src:ESPECIALIDAD2
    },
    {
        path:'../../svg/icons/ESPECIALIDAD.svg',
        name:"ESPECIALIDAD",
        src:ESPECIALIDAD
    },
    {
        path:'../../svg/icons/ESTATURA.svg',
        name:"Estatura",
        src:Estatura
    },
    {
        path:'../../svg/icons/ESTRELLA.svg',
        name:"Estrella",
        src:Estrella
    },
    {
        path:'../../svg/icons/ESTUDIO.svg',
        name:"Estudio",
        src:Estudio
    },
    {
        path:'../../svg/icons/EXPORTAR.svg',
        name:"Exportar",
        src:Exportar
    },
    {
        path:'../../svg/icons/FILTROS.svg',
        name:"Filtros",
        src:Filtros
    },
    {
        path:'../../svg/icons/FLECHA ATRAS.svg',
        name:"Flecha atrás",
        src:Flechaatrás
    },
    {
        path:'../../svg/icons/GMAIL.svg',
        name:"Gmail",
        src:Gmail
    },
    {
        path:'../../svg/icons/HIJOS.svg',
        name:"Hijos",
        src:Hijos
    },
    {
        path:'../../svg/icons/HOME.svg',
        name:"Home",
        src:Home
    },
    {
        path:'../../svg/icons/HORARIO.svg',
        name:"Horario",
        src:Horario
    },
    {
        path:'../../svg/icons/ICONO FLECHA ADELANTE.svg',
        name:"Flecha adelante",
        src:Flechaadelante
    },
    {
        path:'../../svg/icons/PERFIL2.svg',
        name:"Perfil",
        src:Perfil
    },
    {
        path:'../../svg/icons/INFO.svg',
        name:"Info",
        src:Info
    },
    {
        path:'../../svg/icons/INTERNACION.svg',
        name:"Internación",
        src:Internación
    },
    {
        path:'../../svg/icons/INTOLERANCIA.svg',
        name:"Intolerancia/Alergia",
        src:Intolerancia
    },
    {
        path:'../../svg/icons/LLAMADA.svg',
        name:"Llamada",
        src:Llamada
    },
    {
        path:'../../svg/icons/MAIL CONTACTO.svg',
        name:"Mail",
        src:Mail
    },
    {
        path:'../../svg/icons/MAPA.svg',
        name:"Mapa",
        src:Mapa
    },
    {
        path:'../../svg/icons/MATRICULA.svg',
        name:"Matrícula",
        src:Matrícula
    },
    {
        path:'../../svg/icons/MEDICACION.svg',
        name:"Medicación",
        src:Medicación
    },
    {
        path:'../../svg/icons/MENSTRUACION.svg',
        name:"Menstruación",
        src:Menstruación
    },
    {
        path:'../../svg/icons/Menu hamburg off.svg',
        name:"Menu Hamburguesa",
        src:MenuHamburguesa
    },
    {
        path:'../../svg/icons/Menu hamburg on.svg',
        name:"Menu Hamburguesa 2",
        src:MenuHamburguesa2
    },
    {
        path:'../../svg/icons/MENU TRES PUNTOS.svg',
        name:"Menú tres puntos",
        src:Menútrespuntos
    },
    {
        path:'../../svg/icons/PACIENTES.svg',
        name:"Pacientes",
        src:Pacientes
    },
    {
        path:'../../svg/icons/PAUSA.svg',
        name:"Pausa",
        src:Pausa
    },
    {
        path:'../../svg/icons/PDF.svg',
        name:"PDF",
        src:PDF
    },
    {
        path:'../../svg/icons/PESO.svg',
        name:"Peso",
        src:Peso
    },
    {
        path:'../../svg/icons/PLUS.svg',
        name:"Plus",
        src:Plus
    },
    {
        path:'../../svg/icons/Property 1=firma digital.svg',
        name:"Firma digital",
        src:Firmadigital
    },
    {
        path:'../../svg/icons/Property 1=Historia clinica 1.svg',
        name:"Historia clínica",
        src:Historiaclínica
    },
    {
        path:'../../svg/icons/Property 1=Historia clinica 2.svg',
        name:"Historia clínica2",
        src:Historiaclínica2
    },
    {
        path:'../../svg/icons/Property 1=ICONO BORRAR 2.svg',
        name:"Borrar",
        src:Borrar
    },
    {
        path:'../../svg/icons/Property 1=ICONO CHECK LLENO.svg',
        name:"Check Cuadro lleno",
        src:CheckCuadrolleno
    },
    {
        path:'../../svg/icons/Property 1=ICONO, Property 2=BORRAR.svg',
        name:"Borrar2",
        src:Borrar2
    },
    {
        path:'../../svg/icons/Property 1=ICONO, Property 2=CHECK VACIO.svg',
        name:"Check Cuadro vacio",
        src:CheckCuadrovacio
    },
    {
        path:'../../svg/icons/Property 1=ICONO, Property 2=FIRMA DIGITAL 2.svg',
        name:"Firma digital2",
        src:Firmadigital2
    },
    {
        path:'../../svg/icons/Property 1=NO VERIFICADO.svg',
        name:"Verificado1",
        src:Verificado1
    },
    {
        path:'../../svg/icons/Property 1=NO VISIBLE.svg',
        name:"Visible 2",
        src:Visible2
    },
    {
        path:'../../svg/icons/Property 1=Off.svg',
        name:"Mail1",
        src:Mail1
    },
    {
        path:'../../svg/icons/Property 1=On.svg',
        name:"Mail2",
        src:Mail2
    },
    {
        path:'../../svg/icons/Property 1=VERIFICADO.svg',
        name:"Verificado2",
        src:Verificado2
    },
    {
        path:'../../svg/icons/Property 1=VISIBLE.svg',
        name:"Visible 1",
        src:Visible1
    },
    {
        path:'../../svg/icons/QR.svg',
        name:"QR",
        src:QR
    },
    {
        path:'../../svg/icons/RECETA.svg',
        name:"Receta",
        src:Receta
    },
    {
        path:'../../svg/icons/RECREACION.svg',
        name:"Recreacion",
        src:Recreacion
    },
    {
        path:'../../svg/icons/RESEÑA.svg',
        name:"Reseña",
        src:Reseña
    },
    {
        path:'../../svg/icons/SAL.svg',
        name:"Sal",
        src:Sal
    },
    {
        path:'../../svg/icons/SINTOMAS.svg',
        name:"Síntomas",
        src:Síntomas
    },
    {
        path:'../../svg/icons/Status=Off.svg',
        name:"Notificación1",
        src:Notificación1
    },
    {
        path:'../../svg/icons/Status=On.svg',
        name:"Notificación2",
        src:Notificación2
    },
    {
        path:'../../svg/icons/TABACO.svg',
        name:"Tabaco",
        src:Tabaco
    },
    {
        path:'../../svg/icons/TARJETA.svg',
        name:"Tarjeta",
        src:Tarjeta
    },
    {
        path:'../../svg/icons/TELEFONO CONTACTO.svg',
        name:"Teléfono contacto",
        src:Teléfonocontacto
    },
    {
        path:'../../svg/icons/TELEGRAM.svg',
        name:"Telegram",
        src:Telegram
    },
    {
        path:'../../svg/icons/UBICACION.svg',
        name:"Ubicación",
        src:Ubicación
    },
    {
        path:'../../svg/icons/VACACIONES.svg',
        name:"Vacaciones",
        src:Vacaciones
    },
    {
        path:'../../svg/icons/VIRUS.svg',
        name:"Virus",
        src:Virus
    },
    {
        path:'../../svg/icons/WHATSAPP.svg',
        name:"Whatsapp",
        src:Whatsapp
    },
]