//Hooks
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import {useTransition, animated} from 'react-spring';
//Components
import { TailSpin } from  'react-loader-spinner'
import { AnyObject } from 'immer/dist/internal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Pagination from '../Pagination';
import Modal from '@mui/material/Modal';
import RolesModalAdd from './RolesModalAdd';
//SVG and images
import dontNew from '../../../svg/dontNew.svg';
import checkNew from '../../../svg/checkNew.svg';
import pencilNew from '../../../svg/pencilNew.svg';
import trashNew from '../../../svg/trashNew.svg';
//Services and functions
import { rolActivado, EspecialityToasts, errorDeLogin, rolDesactivado, rolEliminado } from '../Toastifys/EspecialityToasts';
import rolesData from '../../../data/rolesHardCoded.json';
import { getRoleById, getRoles, getRolesFunctions } from '../../../actions/actions';
import axios from 'axios';

const RolesTable = ( {roleToEditState, roleByIdToEdit,search,functions, checkHandlerFatherEdit,popperFiltersSelected, rolesFunctions, setOpenModalConfirmation2,onSortingChange, sortingField, sortingOrder, setGoToEdit,goToEdit, setOpenModalAddRole,openModalAddRole, typeOfTable, opacity, setOpenModalConfirmation, actions, checkHandlerFather, newRole, setNewRole}:any) => {

    //UseDispatch (activate the actions of redux)
    const dispatch = useDispatch();
    //States
    const [itemsSelected, setItemsSelected] = useState<AnyObject>({usersSelected: []});
    const [totalItemsRole, setTotalItemsRole] =useState(0);
    const [currentPageRole, setCurrentPageRole] = useState(1);
    const [sorting, setSorting] = useState({field:"", order:""});
    const ITEMS_PER_PAGE =  5;
    const [openModalActiveRole, setOpenModalActiveRole] = useState(false);
    const [openModalDesactiveRole, setOpenModalDesactiveRole] = useState(false);
    const [openModalDeleteRole, setOpenModalDeleteRole] = useState(false);
    const [roleSelected, setRoleSelected] = useState({nombre:"",  id:""});
    const [roleToEdit, setRoleToEdit] = useState()

    //Fetch the users data
    const [roleState, setRoleState]:any[] = useState(rolesData);
    const fetchUsers = () =>{
        setRoleState(rolesRedux)    
    };
    //UseSelector of Roles Functions to render the modal of new roles
    const rolesRedux = useSelector((state:any)=> state.users.roles);
    //user token in redux state
    const userToken: any = useSelector((state: any) => state.login.logData.token);
    //Fetch Data
    useEffect(()=>{
        dispatch(getRoles(userToken))
        fetchUsers(); 
    }, []);
    
    //useMemo of users for the table
    const usersMemo = useMemo(()=>{
        let data = rolesRedux;
        let mappedData:any= data.map((u:any)=>{
            if(Array.isArray(u.sections) ){
                let aux = u.sections;
                let final = "";
                let rolesMapped=aux.map((s:any)=>{final= final + ` ${s}`});
                u.cosa= final ;
            }else if (u.sections === null){
                u.cosa= "No tiene secciones"
            }
            if(u.is_enable === true)u.is_enable="Activado";
            if(u.is_enable === false)u.is_enable="Desactivado";
            
            return u
        });
        let computedUsers = mappedData;
        if(search.length){
            computedUsers = computedUsers.filter(
                (user:any) => 
                    String(user.name).toLowerCase().includes(search.toLowerCase()) ||
                    String(user.status).toLowerCase() === (search.toLowerCase())
            )
        }
        if(popperFiltersSelected.length > 0){
            popperFiltersSelected.forEach((e:any)=>{
                return computedUsers = computedUsers.filter(
                    (user:any) =>
                        user[e.firstFilter] === e.secondFilter
                )
            })
        }
        setTotalItemsRole(computedUsers.length);
        //sorting Users
        if (sorting.field){
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedUsers = computedUsers.sort((a:any, b:any)=> reversed * a[sorting.field]?.localeCompare(b[sorting.field]))
        };
        //CURRENT PAGES SLICE
        return computedUsers.slice( (currentPageRole - 1) * ITEMS_PER_PAGE, (currentPageRole - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE );
    },[roleState, currentPageRole, search, sorting, popperFiltersSelected, rolesRedux]);



    //Change color of Row when you check the checkbox
    const changeColor = () =>{
        let dataTable:any = document.getElementById('data-table');
        let inputs:any = dataTable?.querySelectorAll('tbody>tr>td>input');

        inputs?.forEach((input:any)=>{
            if(input.checked){
                let parentRow = input.parentNode?.parentNode
                parentRow.classList.add("bg-selected")
            }
            if (!input.checked){
                let parentRow = input.parentNode?.parentNode
                parentRow.classList.remove("bg-selected")
            }
        })
        
    };

     //Table checkboxes Individualy
     const individualCheck = (target:any) =>{
        if(target.checked=== true){
            if(!itemsSelected.usersSelected.includes(target.id)){
                setItemsSelected((prevState) => ({usersSelected: [...prevState.usersSelected, {id:target.id, status: target.dataset.status}]}))
                changeColor()                 
            }
        }
        if(target.checked=== false){
                    setItemsSelected((prevState)=> ({usersSelected: [...prevState.usersSelected.filter((u:any)=> u.id !== target.id)] }))
                    changeColor()   
        }

    };
    //Table checkboxes Select All 
    const checkAll = (e:any) => {
        let dataTable = document.getElementById('data-table');
        let inputs:any = dataTable?.querySelectorAll('tbody>tr>td>input');
        if(e.target.checked===true){
            inputs.forEach((input:any)=>{
                input.checked = true;
                changeColor();
                individualCheck(input);
            });
        };
        if(e.target.checked ===false){
            inputs.forEach((input:any)=>{
                input.checked = false;
                changeColor();
                individualCheck(input);
            })
        };
    };

    //Button in table Activate role
    const handleOpenModalActiveRole = (name:string, id:any, setRoleSelected:any, setOpenModalActiveRole:any)=>{
        setRoleSelected({nombre: name, id:id});
        setOpenModalActiveRole(true)
    }
    //Modal Activate Role CANCEL button
    const modalActiveRoleCancelar = (setRoleSelected:any, setOpenModalActiveRole:any) => {
        setRoleSelected({name:"", id:""});
        setOpenModalActiveRole(false);
    }
    //Modal active Role ACCEPT button
    const modalActiveRoleAceptar = (setRoleSelected:any, roleSelected:any ,setOpenModalActiveRole:any) => {
        const activeRole = async(id:any, token:any)=> {
            let postToSend = {is_enabled: true};
            try{
                const {data}:{data:any}= await axios.patch(`${process.env.REACT_APP_AUTH_AVE_CHANGE_ROLE_ACTIVATED_DESACTIVATED}${id}`,postToSend,{headers:{"Authorization":`Bearer ${token}`}});
                if(data.msj==="Se actualizo el rol"){
                    dispatch(getRoles(userToken));
                    setRoleSelected({nombre: "", id:""});
                    setOpenModalActiveRole(false);
                    rolActivado()
                }else{
                    setRoleSelected({nombre: "", id:""});
                    setOpenModalActiveRole(false);
                    errorDeLogin(data.msj)
                }
            }catch(error){
                console.log(error)
            }
        }
        activeRole(roleSelected.id, userToken)
    };
    //Button in table Desactive Role
    const handleOpenModalDesactiveRole = (name:string, id:any, setRoleSelected:any, setOpenModalDesactiveRole:any) => {
        setRoleSelected({nombre:name, id:id});
        setOpenModalDesactiveRole(true);
    };
    //Modal Desactive Role CANCEL button
    const modalDesactiveRoleCancelar = (setRoleSelected:any, setOpenModalDesactiveRole:any) => {
        setRoleSelected({name:"", id:""});
        setOpenModalDesactiveRole(false);
    };
    //Modal Desactive Role ACCEPT button
    const modalDesactiveRoleAceptar = (setRoleSelected:any, roleSelected:any, setOpenModalDesactiveRole:any) => {
        const desactiveRole= async(id:any, token:any)=>{
            let postToSend = {is_enabled: false};
            try{
                const {data}:{data:any}= await axios.patch(`${process.env.REACT_APP_AUTH_AVE_CHANGE_ROLE_ACTIVATED_DESACTIVATED}${id}`,postToSend,{headers:{"Authorization":`Bearer ${token}`}});
                if(data.msj==="Se actualizo el rol"){
                    dispatch(getRoles(userToken));
                    setRoleSelected({nombre: "", id:""});
                    setOpenModalDesactiveRole(false);
                    rolDesactivado()
                }else{
                    setRoleSelected({nombre: "", id:""});
                    setOpenModalDesactiveRole(false);
                    errorDeLogin(data.msj)
                }
            }catch(error){
                console.log(error)
            }
        }
        desactiveRole(roleSelected.id, userToken);
    };
    //Button in table Delete Role
    const handleOpenModalDeleteRole = (name:string, id:any, setRoleSelected:any, setOpenModalDeleteRole:any)=>{
        setRoleSelected({nombre:name, id:id});
        setOpenModalDeleteRole(true);
    };
    //Modal Delete Role CANCEL button
    const modalDeleteRoleCancelar = (setRoleSelected:any, setOpenModalDeleteRole:any )=>{
        setRoleSelected({name:"", id:""});
        setOpenModalDeleteRole(false);
    };
    //Modal Delete Role ACCEPT Button
    const modalDeleteRoleAceptar = (setRoleSelected:any, roleSelected:any, setOpenModalDeleteRole:any)=>{
        const deleteRole = async(id:any, token:string)=>{
            try{
                const{data}:{data:any}= await axios.delete(`${process.env.REACT_APP_AUTH_AVE_CHANGE_ROLE_ACTIVATED_DESACTIVATED}${id}`,{headers:{"Authorization":`Bearer ${token}`}});
                if(data.msj==="Se elimino el rol"){
                    dispatch(getRoles(userToken));
                    setRoleSelected({nombre: "", id:""});
                    setOpenModalDeleteRole(false);
                    rolEliminado();
                }else {
                    setRoleSelected({nombre: "", id:""});
                    setOpenModalDeleteRole(false);
                    errorDeLogin(data.msj);
                }
            }catch(error){
                console.log(error)
            }
        }
        deleteRole(roleSelected.id, userToken);
    };
    //Button in table EDIT role
    const [roleIdToEditOnly, setRoleIdToEditOnly]= useState("");
    const handleEditRoles = (setGoToEdit:any, setOpenModalAddRole:any, id:any) => {
        dispatch(getRolesFunctions(userToken));
        dispatch(getRoleById(userToken, id))
        setGoToEdit(true);
        setOpenModalAddRole(true);
        setNewRole({name:roleByIdToEdit.name, description: roleByIdToEdit.description})
        setRoleIdToEditOnly(id)
    }




    return(
    <div>
        {rolesRedux.length === 0 ? <div className='loader-container-roles'><TailSpin color='#6767AD'></TailSpin></div>  :
            <div className="table-and-pagination-container">
                <animated.div style={{ position: 'relative',width:'100%',opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })}}>
                <div className='table-container'>
                    <table id='data-table'>
                        <thead>
                            <tr className="tr-table-tittles">
                                <th  id="first-row-table"><input name="select_all" value="1" type="checkbox" onChange={(e:any)=>checkAll(e)}></input></th>   
                                <th onClick={()=>onSortingChange("name")}>Nombre   {sortingField && sortingField === "name" && (
                                    <FontAwesomeIcon icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}/>
                                )}</th>
                                <th >Secciones</th>
                                <th className="collumnCenterAlign" onClick={()=>onSortingChange("is_actived")}>Estado   {sortingField && sortingField === "is_actived" && (
                                    <FontAwesomeIcon icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}/>
                                )}</th>
                                <th className="collumnCenterAlign" id="last-row-table">Acciones</th>
                            </tr>
                        </thead>
                         <tbody>
                            {usersMemo.length === 0 &&
                            
                                <tr>
                                    <td >
                                        <p className="no-results-td-table">No hay resultados</p>
                                    </td>
                                </tr>
                            }
                            {usersMemo && usersMemo.map((user:any)=>(
                           
                                <tr key={user.id} id={user.id} className="" >
                                    <td ><input type="checkbox" id={user.id} data-status={user.status} onChange={(e:any)=>individualCheck(e.target)}></input></td>
                                    <td>{user.name}</td>
                                    <td>{user.cosa}</td>
                                    <td className="collumnCenterAlign">{user.is_enable === "Activado" ? <button className='btn-activo'>Activado</button> : <button className='btn-inactivo'>Desactivado</button>}</td>
                                    <td className="collumnCenterAlign"><img className="dont brigthHover"src={user.is_enable === "Activado" ? dontNew : checkNew} alt="desactiveIcon" onClick={user.is_enable === "Activado" ? ()=>handleOpenModalDesactiveRole(user.name, user.id, setRoleSelected, setOpenModalDesactiveRole) : ()=>handleOpenModalActiveRole(user.name, user.id, setRoleSelected, setOpenModalActiveRole)}/> <img className="trashNew brigthHover"src={trashNew} alt="deleteIcon" onClick={()=>handleOpenModalDeleteRole(user.name, user.id, setRoleSelected, setOpenModalDeleteRole)}/> <img alt='editIcon' className="brigthHover" src={pencilNew} onClick={()=>handleEditRoles(setGoToEdit, setOpenModalAddRole, user.id)}/></td>
                                </tr>
                           
                            ))}
                            
                        </tbody>
                    </table>
                    
                </div>
                </animated.div>
                <div className='pagination-container'>
                        <Pagination 
                            total={totalItemsRole}
                            itemsPerPage={ITEMS_PER_PAGE}
                            currentPage={currentPageRole}
                            onPageChange={(page:any) => setCurrentPageRole(page)}/>
                </div>
                
                <Modal open={openModalActiveRole}
                onClose={()=>setOpenModalActiveRole(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                    <div className="father-Modal">
                        <div id="modal-modal-title">
                            <img src={checkNew} alt="activate Icon" className="check-modal-icon"/>
                            <p>¿Desea activar el rol?</p> 
                        </div>
                        <div id="modal-modal-description">
                            <div className='modal-description-text'>
                                <p>Si acepta, el rol<span className='text-highlight'>{roleSelected.nombre}</span> se activará con normalidad. ¿Está seguro que desea continuar?</p>                
                            </div>
                            <div className="modal-container-btns">
                                <button className='modal-btn-cancelar' onClick={()=>modalActiveRoleCancelar(setRoleSelected, setOpenModalActiveRole)}>Cancelar</button>
                                <button className="modal-btn-aceptar" onClick={()=>modalActiveRoleAceptar(setRoleSelected,roleSelected,setOpenModalActiveRole)}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal open={openModalDesactiveRole}
                onClose={()=>setOpenModalDesactiveRole(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                    <div className="father-Modal">
                        <div id="modal-modal-title">
                            <img src={dontNew} alt="desactivate icon" className="dont-modal-icon"/>
                             <p>¿Desea desactivar el rol?</p> 
                        </div>
                        <div id="modal-modal-description">
                            <div className='modal-description-text'>
                                <p>Si acepta, el rol  <span className='text-highlight'>{roleSelected.nombre}</span> quedará desactivado. ¿Está seguro que desea continuar?</p>               
                            </div>
                            <div className="modal-container-btns">
                                <button className='modal-btn-cancelar' onClick={()=>modalDesactiveRoleCancelar(setRoleSelected, setOpenModalDesactiveRole)}>Cancelar</button>
                                <button className="modal-btn-aceptar" onClick={()=>modalDesactiveRoleAceptar(setRoleSelected, roleSelected, setOpenModalDesactiveRole)}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal open={openModalDeleteRole}
                onClose={()=>setOpenModalDeleteRole(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                    <div className="father-Modal">
                        <div id="modal-modal-title">
                            <img src={trashNew} alt="delete Icon" className="trash-modal-icon"/>
                             <p >¿Desea eliminar el rol?</p> 
                        </div>
                        <div id="modal-modal-description">
                            <div className='modal-description-text'>
                                <p>Si acepta, eliminará el rol <span className='text-highlight'>{roleSelected.nombre} </span> ¿Está seguro que desea continuar?</p>               
                            </div>
                            <div className="modal-container-btns">
                                <button className='modal-btn-cancelar' onClick={()=>modalDeleteRoleCancelar(setRoleSelected, setOpenModalDeleteRole )}>Cancelar</button>
                                <button className="modal-btn-aceptar" onClick={()=>modalDeleteRoleAceptar(setRoleSelected, roleSelected, setOpenModalDeleteRole)}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <RolesModalAdd roleIdToEditOnly={roleIdToEditOnly} userToken={userToken} roleToEditState={roleToEditState} functions={functions} checkHandlerFatherEdit={checkHandlerFatherEdit} openModalAddRole={openModalAddRole} setOpenModalAddRole={setOpenModalAddRole} rolesFunctions={rolesFunctions} setOpenModalConfirmation2={setOpenModalConfirmation2} setOpenModalConfirmation={setOpenModalConfirmation} actions={actions} checkHandlerFather={checkHandlerFather} newRole={newRole} setNewRole={setNewRole} goToEdit={goToEdit}></RolesModalAdd>
                <EspecialityToasts></EspecialityToasts>
            </div>
        }</div>
    )
}

export default RolesTable;