
import axios from "axios";
import actions from "../actions_types/actions_types";
import auxtemplate from '../data/mi-salud-get-all-pages.json'
/********PASOS PARA CREAR UNA ACTION NUEVA***********
 1-> carpeta Interfaces: añadir a la interface IActions el nombre de la action y el tipo String
 2-> carpeta actions_type: añadir al objeto actions una la nueva action 
 3-> carpeta actions: crear la nueva action y en type colocar action.NOMBRE_DE_LA_ACTION
 4-> reducer correspndiente: en case colocar actions.NOMBRE_DE_LA_ACTION
**********************/



export const login = (data: any) => ({
    type: actions.LOGIN,
    payload: data
  });
  

export const logout = () => {
    return {
      type: actions.LOG_OUT,
    };
  }    

export function getUsers(token:string):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_ORGANIZATION_AVE}/admin/list?validated=1,0`, {headers:{"Authorization": `Bearer ${token}`}});
    dispatch({
      type: actions.GET_USERS,
      payload: res.data.data.items,
    });
  }
};

export function getSpecialitys(token:string):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_ORGANIZATION_AVE_GET_SPECIALITY}`, {headers:{"Authorization": `Bearer ${token}`}});
    dispatch({
      type: actions.GET_SPECIALITYS,
      payload: res.data.data.items,
    });
  }
};

export function getFrequentLocations(token:string):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_ORGANIZATION_AVE_GET_FREQUENT_LOCATIONS}`, {headers:{"Authorization": `Bearer ${token}`}} );
    dispatch({
      type: actions.GET_FREQUENT_LOCATIONS,
      payload: res.data.data.items
    })
  }
};

export function getDoctorsToApprove(token:string):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_ORGANIZATION_AVE_GET_DOCTOR_TO_APPROVE}`, {headers:{"Authorization": `Bearer ${token}`}});
    dispatch({
      type: actions.GET_DOCTORS_TO_APPROVE,
      payload: res.data.data.items
    })
  }
};

export function getNotifications(token:string):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_ORGANIZATION_AVE_NOTIFICATIONS}`, {headers:{"Authorization": `Bearer ${token}`}});
    dispatch({
      type: actions.GET_NOTIFICATIONS,
      payload: res.data.data.items
    })
  }
};

export function  getEspecialityXdoctor(token:string):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_ORGANIZATION_AVE_GET_ESPECIALITY_X_DOCTOR}`, {headers:{"Authorization": `Bearer ${token}`}});
    dispatch({
      type: actions.GET_ESPECIALITYXDOCTOR,
      payload: res.data.data.items
    })
  }
};

export function getDoctorPendingApproveDocuments (token:string, id:any):any{
  return async function (dispatch:any){
    let postToSend = {"doctorId" : id};
  
    const res = await axios.post(`${process.env.REACT_APP_ORGANIZATION_AVE_DOCTOR_PENDING_APPROVE_DOCUMENTS}`, postToSend,{headers:{"Authorization": `Bearer ${token}`}});
    dispatch({
      type: actions.GET_DOCTOR_PENDING_APPROVE_DOCUMENTS,
      payload: res.data.data.items
    })
  }
};

export function getRolesFunctions (token:string):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_AUTH_AVE_GET_FUNCTIONS_ROLES}`, {headers:{"Authorization":`Bearer ${token}`}});
    dispatch({
      type:actions.GET_ROLES_FUNCTIONS,
      payload: res.data.data.result
    })
  }
};

export function getRoles (token:string):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_AUTH_AVE_GET_ROLES}`, {headers:{"Authorization":`Bearer ${token}`}});
    dispatch({
      type: actions.GET_ROLES,
      payload: res.data.data.result
    })
  }
};

export function getRoleById (token:string, id:any):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_AUTH_AVE_GET_ROLE_BY_ID}${id}`, {headers:{"Authorization":`Bearer ${token}`}});
    dispatch({
      type: actions.GET_ROLE_BY_ID,
      payload: res.data.data.result
    })
  }
};

export function getBeneficiaryList (token:string):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_ORGANIZATION_AVE_GET_BENEFICIARY_LIST}`,  {headers:{"Authorization":`Bearer ${token}`}});
    dispatch({
      type: actions.GET_BENEFICIARY_LIST,
      payload: res.data.data.items
    })
  }
};

export function getEnsuranceList (token:string):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_ORGANIZATION_AVE_GET_ENSURANCE_LIST}`,  {headers:{"Authorization":`Bearer ${token}`}});
    dispatch({
      type: actions.GET_ENSURANCE_LIST,
      payload: res.data.data.items
    }) 
  }
};

export function getBeneficiaryById (token:string, id:string):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_ORGANIZATION_GET_BENEFICIARY_ID}${id}`, {headers:{"Authorization":`Bearer ${token}`}});
    dispatch({
      type: actions.GET_BENEFICIARY_ID,
      payload: res.data.data
    })
  }
}

export function cleanBeneficiaryById ():any {
  return async function (dispatch:any){
    dispatch({
      type: actions.CLEAN_BENEFICIARY_ID,
      payload: []
    })
  }
};

export function cleanPendingDocuments ():any {
  return async function (dispatch:any){
    dispatch({
      type: actions.CLEAN_PENDING_APPROVE_DOCUMENTS,
      payload: []
    })
  }
};

export function getDoctorById (token:string, id:string):any {
  return async function (dispatch:any){
    const res = await axios.get(`${process.env.REACT_APP_GET_DOCTOR_BY_ID}${id}`,{headers:{"Authorization":`Bearer ${token}`}});
    dispatch({
      type: actions.GET_DOCTOR_BY_ID,
      payload: res.data.data
    })
  }
};

export function cleanDoctorById ():any {
  return async function (dispatch:any){
    dispatch({
      type: actions.CLEAN_DOCTOR_BY_ID,
      payload: []
    })
  }
};

export function getMyHealth (token:string):any {
  return async function (dispatch:any){
    const res = await axios.post(`${process.env.REACT_APP_CONSULT_AVE_GENERATE_MYHEALTH_PAGE}`, {pages: [0]}, {headers:{"Authorization":`Bearer ${token}`}})
    const sorted = res.data.data.sort((a:any,b:any) => a.page - b.page);
    const noValues = sorted.map((s:any)=>{
      s.json.data = s.json.data.map((v:any)=>{if(v.value){v.value = ""}return v})
      return s 
    })
    dispatch({
      type: actions.GET_MY_HEALTH_TEMPLATE,
      payload: noValues
    })
  }
};
