import {useTransition} from 'react-spring';//Animations hook
import { useMemo, useState } from 'react';//React hooks
import { AnyObject } from 'immer/dist/internal';




//Change color of Row when you check the checkbox
export const changeColor = () =>{
    //Select the inputs elements
    let dataTable:any = document.getElementById('data-table');
    let inputs:any = dataTable?.querySelectorAll('tbody>tr>td>input');
    
    //Loop the inputs and if this input has checked true/false add a style
    inputs?.forEach((input:any)=>{
        if(input.checked){
            let parentRow = input.parentNode?.parentNode
            parentRow.classList.add("bg-selected")
        }
        if (!input.checked){
            let parentRow = input.parentNode?.parentNode
            parentRow.classList.remove("bg-selected")
        }
    })
};


//Use Transitions allow the react_spring library
export const TransitionSettings= (typeOfTable:string) =>{
    return useTransition(typeOfTable, {
            from: {opacity: 0,position:'relative' ,x:0, y:0},
            enter: {opacity:1,position:'relative', x:0, y:0},
            leave: {opacity:0,position:'relative' ,x:0, y:0},
            config: {duration: 100},
            exitBeforeEnter:true 
        });
};  


//Configure the settings of usersMemo, this manipulate 
export const UsersMemoSettings = (usersRedux:any[],search:string,popperFiltersSelected:null|any,setTotalItems:any, sorting:any, currentPage:number, ITEMS_PER_PAGE:number, userState:any ) => {
    return useMemo(()=>{
        let data= usersRedux;
        let mappedData:any= data.map((u:any)=>{
            if(u.is_verified==="1")u.is_verified="Activado";
            if(u.is_verified==="0")u.is_verified="Desactivado";
            if(u.last_login){
                let aux=u.last_login.slice(0, 10)
                let aux1 = u.last_login.slice(11,19)
                u.last_login=aux + " " + aux1
            }
            // if(u.role ==="admin")u.role="Admin";
            // if(u.role ==="super_admin")u.role="Super admin" 
            return u
        });
        let computedUsers = mappedData;
        //SearchBar 
        if(search.length){
            computedUsers = computedUsers.filter(
                (user:any) => 
                    String(user.name).toLowerCase().includes(String(search).toLowerCase()) ||
                    String(user.role).toLowerCase() === String(search).toLowerCase() ||
                    String(user.email).toLowerCase().includes(String(search).toLowerCase()) ||
                    String(user.status).toLowerCase() === String(search).toLowerCase()
            )
        };
        //Filters
        if(popperFiltersSelected){
            popperFiltersSelected.forEach((e:any)=>{
                return computedUsers = computedUsers.filter(
                    (user:any) =>
                        user[e.firstFilter] === e.secondFilter
                )
            })
        };
        setTotalItems(computedUsers.length);
        //sorting Users
        if (sorting.field){
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedUsers = computedUsers.sort((a:any, b:any)=> reversed * a[sorting.field]?.localeCompare(b[sorting.field]))
        };
        //CURRENT PAGES SLICE
        return computedUsers.slice( (currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE );
    },[userState, currentPage, search, sorting, popperFiltersSelected, usersRedux]);
};


//Table checkboxes Individualy
export const individualCheckLogic = (target:any, itemsSelected:any, setItemsSelected:any ) =>{
    if(target.checked=== true){
        if(!itemsSelected.usersSelected.some((u:any)=>u.id===target.id)){
            setItemsSelected((prevState:any) => ({usersSelected: [...prevState.usersSelected, {id:target.id, status: target.dataset.status}]}))
            changeColor()                 
        }
    }
    if(target.checked=== false){
        if(itemsSelected.usersSelected.some((u:any)=>u.id===target.id)){
                setItemsSelected((prevState:any)=> ({usersSelected: [...prevState.usersSelected.filter((u:any)=> u.id !== target.id)] }))
                changeColor()   
        }        
    }

};

//Table checkboxes Select All (first checkbox of each row table)
export const checkAllLogic = (e:any, itemsSelected:any, setItemsSelected:any) => {
    let dataTable = document.getElementById('data-table');
    let inputs:any = dataTable?.querySelectorAll('tbody>tr>td>input');//Select all inputs

    //if inputs are true/false checks or deschecks and change color
    if(e.target.checked===true){
        inputs.forEach((input:any)=>{
            input.checked = true;
            changeColor();
            individualCheckLogic(input, itemsSelected, setItemsSelected);
        });
    };
    if(e.target.checked ===false){
        inputs.forEach((input:any)=>{
            input.checked = false;
            changeColor();
            individualCheckLogic( input, itemsSelected, setItemsSelected);
        })
    };
};





