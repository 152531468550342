import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import axios from 'axios';
//Functions and services
import { usuarioAdminCreado, errorDeLogin } from '../Toastifys/EspecialityToasts';
import { getUsers } from '../../../actions/actions';

const UsersModalAdd2 = ({openModalAdd2, setOpenModalAdd2, roleOrUser, setNewUser, newUser, userToken}:any)=>{

     //Use dispatch hook
     const dispatch = useDispatch();
     //Modal adduser2 agregar button handler
     const modalAddUser2Agregarbutton = (roleOrUser:any, newUser:any ) => {
        const addUser = async (roleOrUser:any, newUser:any) => {
            let postToSend= {names: newUser.names, last_name:newUser.last_name, second_last_name: newUser.second_last_name, email:newUser.email, number: newUser.number, username: newUser.username, role: roleOrUser};
            await axios.post(`${process.env.REACT_APP_ORGANIZATION_AVE_CREATE_NEW_ADMIN_USER}`, postToSend, {headers:{"Authorization":`Bearer ${userToken}`}})
            .then((response)=>{
                dispatch(getUsers(userToken));
                usuarioAdminCreado();
                setOpenModalAdd2(false);
            }).catch((error)=>{
                if(error.response.data===undefined){
                    console.log(error)
                    errorDeLogin("Error creando el usuario")
                }else{
                    errorDeLogin(error.response.data.error.message)  
                }             
            })
        }
        addUser(roleOrUser, newUser)  
    };


    return(
        <Modal open={openModalAdd2}
            onClose={()=>setOpenModalAdd2(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-Modal-add2">
                    <div className="modal-add2-title-container" id="modal-modal-title">
                        <p>Nuevo {roleOrUser}</p>
                    </div>
                    <div className="modal-add2-inputs-buttons-container" id="modal-modal-description">
                        <div className="modal-add2-input-container">
                            <label className="modal-add2-labels">Nombre de Usuario</label>
                            <input className="modal-add2-inputs" placeholder='Usuario' onChange={(e:any)=>setNewUser({...newUser, username: e.target.value})}></input>
                        </div>
                        <div className="modal-add2-input-container">
                            <label className="modal-add2-labels">Nombre</label>
                            <input className="modal-add2-inputs" placeholder='Nombre' onChange={(e:any)=>setNewUser({...newUser, names: e.target.value})}></input>
                        </div>
                        <div className="modal-add2-input-container">
                            <label className="modal-add2-labels">Apellido</label>
                            <input className="modal-add2-inputs" placeholder='Apellido' onChange={(e:any)=>setNewUser({...newUser, last_name: e.target.value})}></input>
                        </div>
                        <div className="modal-add2-input-container">
                            <label className="modal-add2-labels">Otros apellidos</label>
                            <input className="modal-add2-inputs" placeholder='Segundo apellido' onChange={(e:any)=>setNewUser({...newUser, second_last_name: e.target.value})}></input>
                        </div>
                        <div className="modal-add2-input-container">
                            <label className="modal-add2-labels">Teléfono</label>
                            <input className="modal-add2-inputs" placeholder='Teléfono' onChange={(e:any)=>setNewUser({...newUser, number: e.target.value.toString()})}></input>
                        </div>
                        <div className="modal-add2-input-container marginBotton40">
                            <label className="modal-add2-labels">Email</label>
                            <input className="modal-add2-inputs" placeholder='Email' onChange={(e:any)=>setNewUser({...newUser, email: e.target.value})}></input>
                        </div>

                        <div className="modal-container-btns">
                            <button className='modal-btn-cancelar' onClick={()=>setOpenModalAdd2(false)}>Atras</button>
                            <button className={roleOrUser=== "" || newUser.names ==="" || newUser.email === "" || newUser.last_name === ""  || newUser.username === "" || newUser.number === ""  ? "modal-btn-aceptar-desabled" : "modal-btn-aceptar"} disabled={roleOrUser=== "" || newUser.names ==="" || newUser.email === "" || newUser.email === "" || newUser.last_name === "" || newUser.username === "" || newUser.number === ""? true:false} onClick={()=>modalAddUser2Agregarbutton(roleOrUser, newUser)}>Agregar</button>
                        </div>
                    </div>
                </div>
            </Modal>
    )
};

export default UsersModalAdd2;