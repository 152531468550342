import deleteField from '../../../svg/deleteField.svg';
import { useSpring, animated } from 'react-spring';

const Titulo = ({sections, setSections,sectionPosition, id}:any) => {

    //Delete this input
    const deleteInput = () => {
        let deleted = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data[id].type = "deleted"
            }   
            return section
        })
        setSections(deleted)
    }
    //Change name of the input
    const changeNameInput = (e:any) => {
        let updated = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data[id].name= e.target.value
                }
            return section   
        })
        setSections(updated)
    };

    //Find value
    const findValueName = () => {
        let index = sections[sectionPosition].json.data[id].name
        return index;
    }

    const styles = useSpring({
        from:{opacity: 0, x:0},
        to: {opacity: 1, x:0},
        leave: {opacity: 0, x:0},
        config: {duration: 150}
     })


    return (
        <animated.div style={{...styles}}>
            <div className="titulo-container">
                <input placeholder="Titulo" value={findValueName()} onChange={(e)=>changeNameInput(e)}></input>
                <img alt='deleteSection' src={deleteField} onClick={()=>deleteInput()}></img>
            </div>
        </animated.div>
    )
}

export default Titulo;