import { useSpring, animated } from 'react-spring';
//SVG and images
import collapseIcon from '../../../svg/icons/ATRAS.svg';
//Components
import Text from './Text';
import Select from './Select';

const ClinicSection = ({sectionPosition,setSections,sections, id}:any) => {

    //Handle button to delete section
    const deleteSection = () => {
        let deleted = sections.map((s:any,index:string)=>{
            if(index === sectionPosition){
                s.page_name = "deleted"
            }
            return s
        })
        setSections(deleted)
    };

    //Change the name of the section
    const changeInputName = (e:any)=> {
        let updated = sections.map((s:any, index:string)=>{
            if(index===sectionPosition){
                s.page_name = e.target.value
            }
            return s
        })
        setSections(updated)
    };

    //Animations config
    const styles = useSpring({
        from:{opacity: 0, x:0},
        to: {opacity: 1, x:0},
        leave: {opacity: 0, x:0},
        config: {duration: 150}
     });

    //Collapse handler 
    const collapse = (e:any) => {
        const arrow = e.target;
        const sectionContainer = document.getElementById(`toCollapse${id}`);
        const sectionContainerInput = document.getElementById(`toCollapseContainer1${id}`);
        const sectionContainerButtons = document.getElementById(`toCollapseContainer2${id}`);
        sectionContainer?.classList.toggle('clinicSection-father-container');
        sectionContainerInput?.classList.toggle('clinicSection-input-container');
        sectionContainerButtons?.classList.toggle('clinicSection-buttonsContainer');
        arrow?.classList.toggle('rotateArrow');
    } 

    return(
        <animated.div style={{...styles}}>
            <div className="collapsedsection" id={`toCollapse${id}`}>
                <div className="clinicSection-title-container">
                    <input placeholder='Nombre de sección' onChange={(e)=>changeInputName(e)}/>
                    <img alt='collapseSection' src={collapseIcon} className="section-collapese-icon" onClick={(e)=>collapse(e)}/>
                </div>
                <div className="noDisplay" id={`toCollapseContainer1${id}`}>
                    {sections[sectionPosition].json.data.map((s:any,index:string)=>{
                        if(s.type === "Text"){return <Text sectionPosition={sectionPosition} setSections={setSections} sections={sections} id={index}/>}
                        if(s.type === "Combo"){return <Select sectionPosition={sectionPosition} setSections={setSections} sections={sections} id={index}/>}
                    })}
                </div>
                <div className="noDisplay" id={`toCollapseContainer2${id}`}>
                    <button className="section-button-cancelar" onClick={()=>deleteSection()}>Eliminar</button>
                    <button className="section-button-guardar" >Guardar</button>
                </div>
            </div>
        </animated.div>
    )
}

export default ClinicSection;