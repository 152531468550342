export const templateRoles = {
    name:"", 
    description:"", 
    functions:[
        {
            code:"BENEFICIARY",
            name:"Beneficiarios",
            status: false,
            options: [
                {
                    code:"ACTIVATE",
                    icon: "activate",
                    name: "Activar",
                    status: false 
                },
                {
                    code:"DEACTIVATE",
                    icon: "deactivate",
                    name: "Desactivar",
                    status: false
                },
                {
                    code:"DELETE",
                    icon: "delete",
                    name: "Eliminar",
                    status: false
                },
                {
                    code:"EDIT",
                    icon: "edit",
                    name: "Editar",
                    status: false
                },
                {
                    code:"ADD",
                    icon: "add",
                    name: "Agregar",
                    status: false
                },
                {
                    code:"WATCH",
                    icon: "watch",
                    name: "Ver",
                    status: false
                }
            ]
        },
        {
            code:"USERS",
            name:"Usuarios",
            status: false,
            options: [
                {
                    code:"ACTIVATE",
                    icon: "activate",
                    name: "Activar",
                    status: false
                },
                {
                    code:"DEACTIVATE",
                    icon: "deactivate",
                    name: "Desactivar",
                    status: false
                },
                {
                    code:"DELETE",
                    icon: "delete",
                    name: "Eliminar",
                    status: false
                },
                {
                    code:"EDIT",
                    icon: "edit",
                    name: "Editar",
                    status: false
                },
                {
                    code:"ADD",
                    icon: "add",
                    name: "Agregar",
                    status: false
                },
                {
                    code:"WATCH",
                    icon: "watch",
                    name: "Ver",
                    status: false
                }
            ]
        },
        {
            code:"ROLES",
            name:"Roles",
            status: false,
            options: [
                {
                    code:"ACTIVATE",
                    icon: "activate",
                    name: "Activar",
                    status: false
                },
                {
                    code:"DEACTIVATE",
                    icon: "deactivate",
                    name: "Desactivar",
                    status: false
                },
                {
                    code:"DELETE",
                    icon: "delete",
                    name: "Eliminar",
                    status: false
                },
                {
                    code:"EDIT",
                    icon: "edit",
                    name: "Editar",
                    status: false
                },
                {
                    code:"ADD",
                    icon: "add",
                    name: "Agregar",
                    status: false
                },
                {
                    code:"WATCH",
                    icon: "watch",
                    name: "Ver",
                    status: false
                }
            ]
        },
        {
            code:"DOCTOR",
            name:"Doctor",
            status: false,
            options: [
                {
                    code:"ACTIVATE",
                    icon: "activate",
                    name: "Activar",
                    status: false
                },
                {
                    code:"DEACTIVATE",
                    icon: "deactivate",
                    name: "Desactivar",
                    status: false
                },
                {
                    code:"DELETE",
                    icon: "delete",
                    name: "Eliminar",
                    status: false
                },
                {
                    code:"EDIT",
                    icon: "edit",
                    name: "Editar",
                    status: false
                },
                {
                    code:"ADD",
                    icon: "add",
                    name: "Agregar",
                    status: false
                },
                {
                    code:"WATCH",
                    icon: "watch",
                    name: "Ver",
                    status: false
                }
            ]
        },
        {
            code:"HEALTH_CARE_CENTER",
            name:"Centro de cuidado de salud",
            status: false,
            options: [
                {
                    code:"ACTIVATE",
                    icon: "activate",
                    name: "Activar",
                    status: false
                },
                {
                    code:"DEACTIVATE",
                    icon: "deactivate",
                    name: "Desactivar",
                    status: false
                },
                {
                    code:"DELETE",
                    icon: "delete",
                    name: "Eliminar",
                    status: false
                },
                {
                    code:"EDIT",
                    icon: "edit",
                    name: "Editar",
                    status: false
                },
                {
                    code:"ADD",
                    icon: "add",
                    name: "Agregar",
                    status: false
                },
                {
                    code:"WATCH",
                    icon: "watch",
                    name: "Ver",
                    status: false
                }
            ]
        },
        {
            code:"MY_HEALTH",
            name:"Mi salud",
            status: false,
            options: [
                {
                    code:"ACTIVATE",
                    icon: "activate",
                    name: "Activar",
                    status: false
                },
                {
                    code:"DEACTIVATE",
                    icon: "deactivate",
                    name: "Desactivar",
                    status: false
                },
                {
                    code:"DELETE",
                    icon: "delete",
                    name: "Eliminar",
                    status: false
                },
                {
                    code:"EDIT",
                    icon: "edit",
                    name: "Editar",
                    status: false
                },
                {
                    code:"ADD",
                    icon: "add",
                    name: "Agregar",
                    status: false
                },
                {
                    code:"WATCH",
                    icon: "watch",
                    name: "Ver",
                    status: false
                }
            ]
        },
        {
            code:"CLINIC_HISTORY",
            name:"Historia clinica",
            status: false,
            options: [
                {
                    code:"ACTIVATE",
                    icon: "activate",
                    name: "Activar",
                    status: false
                },
                {
                    code:"DEACTIVATE",
                    icon: "deactivate",
                    name: "Desactivar",
                    status: false
                },
                {
                    code:"DELETE",
                    icon: "delete",
                    name: "Eliminar",
                    status: false
                },
                {
                    code:"EDIT",
                    icon: "edit",
                    name: "Editar",
                    status: false
                },
                {
                    code:"ADD",
                    icon: "add",
                    name: "Agregar",
                    status: false
                },
                {
                    code:"WATCH",
                    icon: "watch",
                    name: "Ver",
                    status: false
                }
            ]
        },
        {
            code:"NOTIFICATIONS",
            name:"Notificaciones",
            status: false,
            options: [
                {
                    code:"ACTIVATE",
                    icon: "activate",
                    name: "Activar",
                    status: false
                },
                {
                    code:"DEACTIVATE",
                    icon: "deactivate",
                    name: "Desactivar",
                    status: false
                },
                {
                    code:"DELETE",
                    icon: "delete",
                    name: "Eliminar",
                    status: false
                },
                {
                    code:"EDIT",
                    icon: "edit",
                    name: "Editar",
                    status: false
                },
                {
                    code:"ADD",
                    icon: "add",
                    name: "Agregar",
                    status: false
                },
                {
                    code:"WATCH",
                    icon: "watch",
                    name: "Ver",
                    status: false
                }
            ]
        }
    ]}