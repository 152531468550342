import {  useState } from 'react';
import '../../../Styles/Layout.css';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import home from '../../../svg/usersAdmin.svg';
import doctor from '../../../svg/CONSULTA.svg';
import patient from '../../../svg/PACIENTES.svg';
import Corazon from '../../../svg/Corazon.svg';
import historiaclinica from '../../../svg/historiaclinica.svg';
import logOut from '../../../svg/Export.svg';
import notificacion from '../../../svg/NOTIFICACION.svg';
import approveDoctor from '../../../svg/approveDoctor.svg';
import { Outlet} from 'react-router';
import {  Link } from 'react-router-dom';
import Drawer from './Drawer.styled';
import { ThemeProvider } from '@mui/material';
import { ListTextTheme } from './LayoutThemeButton';
import { useDispatch } from 'react-redux';
import { logout } from '../../../actions/actions';
import { useNavigate } from 'react-router-dom';
import speciality from '../../../svg/ESPECIALIDAD.svg';
import hospital from '../../../svg/CENTRO ATENCION.svg';
import logoShort from '../../../images/logoShort.png';
export const drawerWidth: number = 220;


const DashboardContent = () => {

 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  
  //Abrir Cerrar NavBar y mostrar ocultar subtitulo de botones de navs
  const [open, setOpen] = useState(false); 
 
  const handleLogOut = () => {
    dispatch(logout());
    localStorage.removeItem('token');
    navigate("/");
  }


  //Seleccionar Boton de Nav
const [selectedIndex, setSelectedIndex] = useState(1);
const handleListItemClick = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  index: number,
) => {
  setSelectedIndex(index);
};

  return (
    <div className="layout-container">
      <div id="container-box-drawer-list" className='container-box-drawer-list'>
          
            <Box className="boxDrawer" sx={{ display: 'flex', bgcolor:'#fff0'}}>
              
              <Drawer  variant="permanent" open={open}  sx={{ bgcolor:'#fff0'}}>

              <ThemeProvider theme={ListTextTheme}>

                <List
                  className='list'
                  sx={{ width: 205, maxWidth: 360, bgcolor: '#41416C', borderStyle: 'hidden'}}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <div>

                  
                  <ListItemButton
                    className="header-container"
                    sx={{pl:"0px", pr:"0px", mb:"20px"}}>
                          <ListItemIcon >
                            <div className="item-list-container">
                              <img src={logoShort} alt="aveicon" className="aveIconHeader" />
                            </div>
                    </ListItemIcon>
                  </ListItemButton>



                  <Link to="/home/usersAdmin">
                    <ListItemButton
                     className="home-icon-container"                   
                      onClick={(event) => handleListItemClick(event, 1)}>
                        <ListItemIcon className={selectedIndex=== 1 ? 'iconSelected' : ""}>
                            <div className="item-list-container-approveDoctor">
                              <img src={home} alt="homeIcon" className="homeIcon" />
                              <p id="item-list-title1" className='item-list-title' >Usuarios<br/>Admin</p>
                            </div>
                        </ListItemIcon>
                    </ListItemButton>
                  </Link>

                  

                  <Link to="/home/beneficiaries">
                    <ListItemButton
                      className="user-icon-container"
                      onClick={(event) => handleListItemClick(event, 2)}>
                        <ListItemIcon className={selectedIndex=== 2 ? 'iconSelected' : ""}>
                          
                            <div className="item-list-container">
                              <img src={patient} alt="UserIcon" className="userIcon"/>
                              <p id="item-list-title2">Beneficiarios</p>
                            </div> 
                            
                        </ListItemIcon>
                        
                    </ListItemButton>
                  </Link> 

                  

                  <Link to="/home/doctors">
                    <ListItemButton
                    className="doctors-icon-container"
                      onClick={(event) => handleListItemClick(event, 3)}>
                        <ListItemIcon className={selectedIndex=== 3 ? 'iconSelected' : ""}>
                          <div className="item-list-container">
                            <img src={doctor} alt="doctorIcon" className="doctorIcon"/>
                            <p id="item-list-title3">Médicos</p>
                          </div> 
                        </ListItemIcon>
                        
                    </ListItemButton>
                  </Link>

                  

                  <Link to="/home/frequentLocation">
                    <ListItemButton
                     className="locations-icon-container"
                      onClick={(event) => handleListItemClick(event, 7)}>
                        <ListItemIcon className={selectedIndex=== 7 ? 'iconSelected' : ""}>
                          <div className="item-list-container-approveDoctor">
                            <img src={hospital} alt="turnsIcon" className="specialityIcon"/>
                            <p id="item-list-title7">Centros <br/>de <br/> atención</p>
                          </div>
                        </ListItemIcon>
                        
                    </ListItemButton>
                  </Link>

                  <Link to="/home/specialitys">
                    <ListItemButton
                     className="speciality-icon-container"
                      onClick={(event) => handleListItemClick(event, 6)}>
                        <ListItemIcon className={selectedIndex=== 6 ? 'iconSelected' : ""}>
                          <div className="item-list-container">
                            <img src={speciality} alt="especialityIcon" className="specialityIcon"/>
                            <p id="item-list-title6">Especialidades</p>
                          </div>
                        </ListItemIcon>
                        
                    </ListItemButton>
                  </Link>

                  
                  <Link to="/home/myhealth">
                    <ListItemButton
                    className="patient-icon-container"
                      onClick={(event) => handleListItemClick(event, 4)}>
                        <ListItemIcon className={selectedIndex=== 4 ? 'iconSelected' : ""}>
                          <div className="item-list-container">
                            <img src={Corazon} alt="patientIcon" className="patientIcon"/>
                            <p id="item-list-title4">Mi Salud</p>
                          </div>
                        </ListItemIcon>
                        
                    </ListItemButton>
                  </Link>
                  
                  <Link to="/home/clinicHistory">
                    <ListItemButton
                     className="turns-icon-container"
                      onClick={(event) => handleListItemClick(event, 5)}>
                        <ListItemIcon className={selectedIndex=== 5 ? 'iconSelected' : ""}>
                          <div className="item-list-container-approveDoctor">
                            <img src={historiaclinica} alt="turnsIcon" className="calendarIcon"/>
                            <p id="item-list-title5" >Historia <br/>Clínica</p>
                          </div>
                        </ListItemIcon>
                        
                    </ListItemButton>
                  </Link>

                  <Link to="/home/notifications">
                    <ListItemButton
                     className="notifications-icon-container"
                      onClick={(event) => handleListItemClick(event, 10)}>
                        <ListItemIcon className={selectedIndex=== 10 ? 'iconSelected' : ""}>
                          <div className="item-list-container-approveDoctor">
                            <img src={notificacion} alt="approveDocIcon" className="notificationIcon"/>
                            <p id="item-list-title10">Notificaciones</p>
                          </div>
                        </ListItemIcon>
                        
                    </ListItemButton>
                  </Link>

                  </div>
                  <div>

                  
                    <ListItemButton
                    className='logOut-icon'
                      selected={selectedIndex === 5}
                      onClick={(event) => handleListItemClick(event, 5)}>
                        <ListItemIcon onClick={()=>handleLogOut()}>
                          <div className="item-list-container">
                            <img src={logOut} alt="logOutIcon" className="logOuticon"/>
                          </div>
                        </ListItemIcon>
                        
                    </ListItemButton>
                  </div>

                </List>
                </ThemeProvider>
              </Drawer>
              
            </Box>
          
      </div>

      <div id="content-div" className="content-div">
        <Outlet></Outlet>
      </div> 


    </div>
    
  );
};

export default function Dashboard() {
  return <DashboardContent />;
}
