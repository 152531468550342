import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useSpring, animated } from 'react-spring';
//SVG and images
import mihealthedit from '../../../svg/mihealthedit.svg';
import mihealthcross from '../../../svg/mihealthcross.svg';
import minusbutton from '../../../svg/minusbutton.svg';
//Components
import { StyledMenu } from '../MyHealth/StyledMenu';

const Select = ({sectionPosition,setSections,sections,id}:any) => {

    //State and Handlers to open and close the ToolTip/Select
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //Select option handler
    const addNewInput = (e:any)=>{
        let typeOfIntput = e.target.innerText;
        switch(typeOfIntput) {
            case "Respuesta con texto":
                let newQuestion = sections.map((s:any,index:string)=>{
                    if(index===sectionPosition){
                        s.json.data[id]= {name:"", type:"Text", value:"", enabled: false}
                    }
                    return s
                })
                setSections(newQuestion)
                handleClose()
                break;
            
            case "Respuesta única":
                let newSelect = sections.map((s:any,index:string)=>{
                    if(index===sectionPosition){
                        s.json.data[id]= {name:"", type:"Combo", value:"", hint:"Selecciona", values:[{value:"", id:""}] }
                    }
                    return s;
                })
                setSections(newSelect)
                handleClose()
                break;   
            
            default:
                console.log("default")    
        }
    };

    //Change option name
    const changeOptionName = (e:any) => {
        let optionIndex = e.target.dataset.index;
        let updated= sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data[id].values[optionIndex].value = e.target.value;
                section.json.data[id].values[optionIndex].id = e.target.value
            }
            return section
        })
        setSections(updated)
    };

    //Add new option to this input
    const addNewOption = () => {
        let updated = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data.map((i:any,index:any)=>{
                    if(index === id){
                        i.values = [...i.values, {value: '', id: ''}]
                    }
                    return i
                })
            }
            return section
        })
        setSections(updated)
    };

    //Delete option
    const deleteOption = () => {
        let updated = sections.map((section:any, index:string)=>{
            if(index === sectionPosition){
                section.json.data[id].values = section.json.data[id].values.filter((i:any,index:number)=> index < section.json.data[id].values.length-1)
            }
            return section
        })
        setSections(updated)
    };

    //Animations config
    const styles = useSpring({
        from:{opacity: 0, x:0},
        to: {opacity: 1, x:0},
        leave: {opacity: 0, x:0},
        config: {duration: 200}
     });

    return(
        <animated.div style={{...styles}}>
            <div className="select-father-container">
                <div className="select-options-container">
                    <div className="select-options-containerA">
                        {sections[sectionPosition].json.data[id].values.map((s:any,index:string)=>{
                            if(s.value !== "deleted"){
                                return <input key={index} placeholder={"Opción " + String(index+1)} data-index={index} onChange={(e)=>changeOptionName(e)}/>
                            }
                        })}
                    </div>
                    <div className="select-options-addAnother-container">
                        <img alt='addAnotherOption' className="addAnotherOption" src={mihealthcross} onClick={()=>addNewOption()}/>
                        <img alt='removeoption' src={minusbutton} onClick={()=>deleteOption()}/>
                    </div>
                </div>
                <div>
                    <img alt='editField' src={mihealthedit} onClick={handleClick}/>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem sx={{fontFamily:'Poppins', display:'flex', justifyContent:'center' }} onClick={(e)=>addNewInput(e)} >
                            Respuesta con texto
                        </MenuItem>
                        <MenuItem sx={{fontFamily:'Poppins', display:'flex', justifyContent:'center' }}  onClick={(e)=>addNewInput(e)}>
                            Respuesta única
                        </MenuItem>
                    </StyledMenu>
                </div>
            </div>
        </animated.div>
    )
};

export default Select;