import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import axios from 'axios';

//Styles
import '../../../Styles/Users.css';
//functions
import { getUsers } from '../../../actions/actions';
import { usuarioAdminDesactivado, errorDeLogin } from '../Toastifys/EspecialityToasts';
//SVG and Images
import dontNew from '../../../svg/dontNew.svg';


const UserModalDesactivate = ({openModalDesactive, setOpenModalDesactive, userSelected, userToken}:any) => {

    //Use dispatch hook
    const dispatch = useDispatch();
    //Modal button Accept to desactivate user
    const handleClickBtnModalAceptarDesactive = ()=>{
        const desactivateUser = async(userSelected:any)=>{
            let postToSend = {is_verified: false};

            try{
                const {data}:{data:any}= await axios.put(`${process.env.REACT_APP_ORGANIZATION_AVE_CHANGE_STATUS_USER_ADMIN}${userSelected.id}`, postToSend, {headers:{"Authorization":`Bearer ${userToken}`}});
                if(data.msg==="Se proceso correctamente"){
                    dispatch(getUsers(userToken));
                    setOpenModalDesactive(false);
                    usuarioAdminDesactivado();
                }else{
                    setOpenModalDesactive(false);
                    errorDeLogin(data.msg);
                }
            }catch(error){
                console.log(error)
            }
        }
        desactivateUser(userSelected);  
    }
    return(
        <Modal open={openModalDesactive}
            onClose={()=>setOpenModalDesactive(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-Modal">
                    <div className="modal-desactivate-user-title-container" >
                        <img src={dontNew} alt="desactivate icon" className="dont-modal-icon"/>
                        <p>¿Desea desactivar al usuario?</p>
                    </div>
                    <div className="modal-desactivate-user-contain" >
                        <div className='modal-description-text'>
                            <p>Si acepta, el usuario <span className='text-highlight'>{userSelected.nombre} {userSelected.apellido}</span> no podrá utilizar la app hasta que vuelva a activarlo. ¿Esta seguro que desea continuar?</p>          
                        </div>
                        <div className="modal-container-btns">
                            <button className='modal-btn-cancelar' onClick={()=>setOpenModalDesactive(false)}>Cancelar</button>
                            <button className="modal-btn-aceptar" onClick={()=>handleClickBtnModalAceptarDesactive()}>Aceptar</button>
                        </div>
                    </div>
                </div>
        </Modal>
    )
}

export default UserModalDesactivate;