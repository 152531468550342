import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useSpring, animated } from 'react-spring';
//SVG and images
import mihealthedit from '../../../svg/mihealthedit.svg';
//Components
import { StyledMenu } from '../MyHealth/StyledMenu';


const Text = ({sectionPosition,setSections,sections,id}:any) => {

    //State and Handlers to open and close the ToolTip/Select
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //Select option handler
    const addNewInput = (e:any)=>{
        let typeOfIntput = e.target.innerText;
        switch(typeOfIntput) {
            case "Respuesta con texto":
                let newQuestion = sections.map((s:any,index:string)=>{
                    if(index===sectionPosition){
                        s.json.data[id]= {name:"", type:"Text", value:"", enabled: false}
                    }
                    return s
                })
                setSections(newQuestion)
                handleClose()
                break;
            
            case "Respuesta única":
                let newSelect = sections.map((s:any,index:string)=>{
                    if(index===sectionPosition){
                        s.json.data[id]= {name:"", type:"Combo", value:"", hint:"Selecciona", values:[{value:"", id:""}] }
                    }
                    return s;
                })
                setSections(newSelect)
                handleClose()
                break;   
            
            default:
                console.log("default")    
        }
    };

    //Animations config
    const styles = useSpring({
        from:{opacity: 0, x:0},
        to: {opacity: 1, x:0},
        leave: {opacity: 0, x:0},
        config: {duration: 200} 
     });


    return(
        <animated.div style={{...styles}}>
            <div className="text-father-container">
                <textarea placeholder='Respuesta del paciente' disabled={true}/>
                <img alt='editField' src={mihealthedit} onClick={handleClick}/>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem sx={{fontFamily:'Poppins', display:'flex', justifyContent:'center' }} onClick={(e)=>addNewInput(e)} >
                        Respuesta con texto
                    </MenuItem>
                    <MenuItem sx={{fontFamily:'Poppins', display:'flex', justifyContent:'center' }}  onClick={(e)=>addNewInput(e)}>
                        Respuesta única
                    </MenuItem>
                </StyledMenu>
            </div>
        </animated.div>
    )
};

export default Text;