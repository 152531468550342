import Switch from '@mui/material/Switch';
import { useSpring, animated } from 'react-spring';
//Svg and images
import deleteField from '../../../svg/deleteField.svg';

const PreguntaSwitch = ({sections, setSections,sectionPosition, id}:any) => {

    //Delete this input
    const deleteInput = () => {
        let deleted = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data[id].type = "deleted"
            }   
            return section
        })
        setSections(deleted)
    };

    //Change name of the input
    const changeNameInput = (e:any) => {
        let updated = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data[id].name= e.target.value
                }
            return section   
        })
        setSections(updated)
    };

    //Find value
    const findValueName = () => {
        let index = sections[sectionPosition].json.data[id].name
        return index;
    };

    //Animations config
    const styles = useSpring({
        from:{opacity: 0, x:0},
        to: {opacity: 1, x:0},
        leave: {opacity: 0, x:0},
        config: {duration: 150}
     });


    return(
        <animated.div style={{...styles}}>
            <div className="switch-container">
                <div className="switch-name-container">
                    <input placeholder="Pregunta..." value={findValueName()} onChange={(e)=>changeNameInput(e)}/>
                    <img alt='deleteSection' src={deleteField} onClick={()=>deleteInput()}></img>
                </div>
                <div className="switch-switch-container">
                    <label>SI</label>
                    <Switch                            
                        sx={{width:'65px',
                            '& .MuiSwitch-track':{
                            maxWidth:'50px',
                            width:'50px',
                            height:'24px',
                            borderRadius:'14px'},
                            '& .MuiSwitch-switchBase':{
                                height:'20px',
                                marginTop:'14px',
                                marginLeft:'3px'
                            },
                            '& .MuiSwitch-input':{
                                left:'14px'
                            }
                        }}/>
                        <label>NO</label>
                </div>
            </div>
        </animated.div>
    )
};

export default PreguntaSwitch;