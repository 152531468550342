import actions from "../actions_types/actions_types";


const initialState = {
  users: [],
  doctorsToApprove: [],
  doctorById: [],
  especialityXdoctor: [],
  documents: [],
  rolesFunctions: [],
  roles: [],
  roleToEdit: [],
  beneficiaries: [],
  beneficiaryById:[]
};

export const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.GET_USERS:
        return {
        ...state,
        users: action.payload,
    };
    case actions.GET_DOCTORS_TO_APPROVE:
        return {
          ...state,
          doctorsToApprove: action.payload,
    };
    case actions.GET_ESPECIALITYXDOCTOR:
        return {
          ...state,
          especialityXdoctor: action.payload,
    };
    case actions.GET_DOCTOR_PENDING_APPROVE_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
    };
    case actions.GET_ROLES_FUNCTIONS:
      return {
        ...state,
        rolesFunctions: action.payload,
    };
    case actions.GET_ROLES:
      return {
        ...state,
        roles: action.payload,
    };
    case actions.GET_ROLE_BY_ID:
      return {
        ...state,
        roleToEdit: action.payload,
    };
    case actions.GET_BENEFICIARY_LIST:
      return {
        ...state,
        beneficiaries: action.payload
    };
    case actions.GET_BENEFICIARY_ID:
      return {
        ...state,
        beneficiaryById: action.payload
    };
    case actions.CLEAN_BENEFICIARY_ID:
      return{
        ...state,
        beneficiaryById : action.payload
    };
    case actions.CLEAN_PENDING_APPROVE_DOCUMENTS:
      return{
        ...state,
        documents: action.payload
    };
    case actions.GET_DOCTOR_BY_ID:
      return{
        ...state,
        doctorById: action.payload
    };
    case actions.CLEAN_DOCTOR_BY_ID:
      return{
        ...state,
        doctorById: action.payload
    };
    default:
    return state;
  }
};