import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { drawerWidth } from './Layout';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    backgroundColor:"#e9ecef00",
    flex: 1,
    border: "0px",
    overflowX: 'hidden',
    overflowY: 'hidden',
    borderRadius: '0px 0px 30px 0px',
    whiteSpace: 'nowrap',
    width: "110px",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      overflowY: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "110px",
      [theme.breakpoints.up('sm')]: {
        width: "110px",
      },
    }),
  },
}));

export default Drawer;
