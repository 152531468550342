//Consts
import {expresiones} from '../../../data/formValidations.js';
import { login } from '../../../actions/actions';
//Styles
import '../../../Styles/Login.css';
import '../../../Styles/ProgressBar.css';
//Components
import {  IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {EspecialityToasts, errorDeLogin } from '../Toastifys/EspecialityToasts';
//Hooks and services
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  useState } from 'react';
import { useSelector } from "react-redux";
import { getSpecialitys, getNotifications,getDoctorsToApprove, getUsers,getEspecialityXdoctor,getFrequentLocations } from '../../../actions/actions';
//SVG and Images
import aveA from '../../../svg/aveA.svg';
import aveV from '../../../svg/aveV.svg';
import aveE from '../../../svg/aveE.svg';
import pill from '../../../svg/Pill.svg';
import incorrect from '../../../svg/Close.svg';
import check from '../../../svg/Check.svg'; 
import Logo from '../../../images/LogoAVE.png';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import userSVG from '../../../svg/Profile.svg';
import passwordSVG from '../../../svg/Lock.svg';

const Login = () => {

    //consts
    const userToken: any = useSelector((state: any) => state.login); 
    const urlLogin:string= process.env.REACT_APP_LOGIN;
    //UseNavigate
    let navigate = useNavigate();

    //States
    const [values, setValues] = useState({
        username: '',
        password: '',
        showPassword: false,
      });
    const [completed, setCompleted] = useState(0);

    //UseDispatch (activate the actions of redux)
    const dispatch = useDispatch();
    

    //SHOW OR NOT PASSWORD  
    const handleClickShowPassword = () =>{
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      };

 

    //Validate auxiliar inputs texts  
    // const formulario = document.getElementById('loginForm');
    const inputs = document.querySelectorAll('#loginForm input');
    const ingresarButton = document.getElementById('buttonLarge');
    const usernameGoodText = document.getElementById('auxTextCorrect');
    const usernameErrorText = document.getElementById('auxTextIncorrect');
    const passwordGoodText = document.getElementById('auxTextCorrectPassword');
    const passwordErrorText = document.getElementById('auxTextIncorrectPassword');
    const validateForm = (e:any) =>{
        switch(e.target.name){
            case "username":
                if(expresiones.usuario.test(e.target.value)){
                    usernameGoodText?.classList.add('form-input-username-correct');
                    usernameErrorText?.classList.remove('form-input-username-error');                    
                    ingresarButton?.classList.add('buttonLargeActiveUsername');
                }else{
                    usernameErrorText?.classList.add('form-input-username-error'); 
                    usernameGoodText?.classList.remove('form-input-username-correct');   
                    ingresarButton?.classList.remove('buttonLargeActiveUsername');  
                };
                if(ingresarButton?.classList.contains('buttonLargeActiveUsername') && ingresarButton?.classList.contains('buttonLargeActivePassword')){
                    ingresarButton?.classList.add('buttonLargeActive');
                }else{
                    ingresarButton?.classList.remove('buttonLargeActive');           
                };
            break;

            case "password":
                if(expresiones.password.test(e.target.value)){
                    passwordGoodText?.classList.add('form-input-username-correct');
                    passwordErrorText?.classList.remove('form-input-username-error');
                    ingresarButton?.classList.add('buttonLargeActivePassword');
                }else{
                    passwordErrorText?.classList.add('form-input-username-error'); 
                    passwordGoodText?.classList.remove('form-input-username-correct');                                      
                    ingresarButton?.classList.remove('buttonLargeActivePassword');
                };
                if(ingresarButton?.classList.contains('buttonLargeActiveUsername') && ingresarButton?.classList.contains('buttonLargeActivePassword')){
                    ingresarButton?.classList.add('buttonLargeActive');
                }else{
                    ingresarButton?.classList.remove('buttonLargeActive');  
                };
            break;
        }
    };

    inputs.forEach((input)=>{
        input.addEventListener('change', validateForm )
        input.addEventListener('input', validateForm )
        // input.addEventListener('keyup', validateForm )
        input.addEventListener('blur', validateForm )
        });

    //SEND LOGIN DATA  
    const handleSubmitLogin = (e:any) =>{
        e.preventDefault();
        let packageToLogin = {
            username: values.username,
            password: values.password,
            type_user: "admin"
        };
        //function with post
        const sendLogin = async (post:object)=>{
            try{
                const { data }: { data: any } = await axios.post(urlLogin, post);
                if(data.success=== true){
                    dispatch(login(data));
                    setValues({...values, username:"", password:""});
                    window.localStorage.setItem(
                        "token", JSON.stringify(data.token)
                    );
                    

                    document.getElementById("loginBox")?.classList.add('display-hidden');
                    setTimeout(()=> {
                        document.getElementById("progressBar")?.classList.add('containerStyles');
                        document.getElementById("progressBar-icon")?.classList.remove("display-hidden");
                        document.getElementById("loadingLogo")?.classList.remove("display-hidden");
                        document.getElementById("loadingText")?.classList.add("loadingText");
                        document.getElementById("loadingText")?.classList.remove("display-hidden");
                       for(let i = 0; i<=100; i++) {
                           setTimeout(()=>{
                               setCompleted(i);
                           },500)  
                        }   
                    }, 1000);
                                  
                    setTimeout( ()=>{      
                        document.getElementById("overlayID")?.classList.toggle('visible'); 
                        document.getElementById("progressBar")?.classList.toggle('finish');
                        const token = userToken.logData.token;              
                        dispatch(getUsers(token));
                        dispatch(getSpecialitys(token));
                        dispatch(getNotifications(token));
                        dispatch(getFrequentLocations(token));
                        dispatch(getEspecialityXdoctor(token));
                        dispatch(getDoctorsToApprove(token))                      
                    }, 3000)
                     setTimeout( ()=>navigate("/home/usersAdmin"), 4000)   
                }else{
                    errorDeLogin(data.error);
                }   
            }catch(error){
                
                console.log("send login post error:", error)
            }
        };
        
        sendLogin(packageToLogin);
    };

   
    //RETURN of the Component
    return(
        <div className="login-container" id="container-login">
            <div className="overlay" id='overlayID'></div>
            <div className='login-box' id="loginBox">
                <div className="logo-container-login">
                    <img className="logo" alt="logoAve" src={Logo} width="200px"/>
                </div>

                <div className='inputs'>
                    <form  id="loginForm" onSubmit={handleSubmitLogin}>
                        <div className='input-username-container'>
                            <div className='input-username-fields'>
                                <TextField
                                    
                                    name='username'
                                    autoComplete='off'
                                    className='inputUser'
                                    id="input-with-icon-textfield-username"
                                    value={values.username}
                                    onChange={(e:any)=>setValues({...values, username: e.target.value})}
                                    placeholder='Usuario'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={userSVG} alt='user icon' />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                />
                            </div>
                            <div className='input-username-message'>
                                <p id="auxTextCorrect" className='form-input-hidden-mesagge'>Usuario correcto {<img src={check} alt="checkIcon" />}</p>
                                 <p id="auxTextIncorrect" className='form-input-hidden-mesagge'>Error nombre de usuario {<img src={incorrect} alt="incorrectIcon" className="filterRed"/>}</p> 
                            </div>                
                        </div>
                      
                        <div className='input-password-container'>
                            <div>
                                <TextField
                                    name='password'
                                    autoFocus={true}
                                    autoComplete='off'
                                    className='inputPassword'
                                    id="input-with-icon-textfield-password"
                                    value={values.password}
                                    onChange={(e:any)=>setValues({...values, password: e.target.value})}
                                    placeholder="Contraseña"
                                    type={values.showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={passwordSVG} alt="passwordIcon"/>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    variant="standard"
                                />
                                <input type='submit' style={{display:'none'}}></input>
                            </div>
                            <div className='input-password-message-container'>
                                <p id="auxTextCorrectPassword" className='form-input-hidden-mesagge'>Contraseña Correcta {<img src={check} alt="checkIcon" />}</p>
                                <p id="auxTextIncorrectPassword" className='form-input-hidden-mesagge'>Contraseña incorrecta {<img src={incorrect} alt="incorrectIcon" className="filterRed"/>}</p>
                            </div>
                                
                            
                        </div>
                        
                    </form>
                </div>

                <div className="button-login-container">
                    <button onClick={handleSubmitLogin} className='buttonLarge' id="buttonLarge" disabled={ingresarButton?.classList.contains('buttonLargeActiveUsername') && ingresarButton?.classList.contains('buttonLargeActivePassword') ? false : true} >Ingresar</button>
                </div>
                
            </div>

            <div className="loading" id="loading">
                <div className="display-hidden loadingLogo" id="loadingLogo">
                    <img src={aveA} alt="logoAveAletter"/>
                    <img src={aveV} alt="logoAveVletter"/>
                    <img src={aveE} alt="logoAveEletter" />
                </div>
                <div className="display-hidden " id="loadingText">
                    <p>Estamos procesando tu registro.</p>
                    <p>Sólo nos llevará unos segundos.</p>
                </div>
                <div className="progressBar" id="progressBar">
                    <div id="fillerStyles" className="fillerStyles" style={{width: `${completed}%`}} >
                        <div className='container-bar-progress' id='container-bar-progress'>
                            <span id="labelStyles" className="labelStyles"></span>
                        </div>
                        <div >
                            <img className="display-hidden icon" alt="userIcon" id="progressBar-icon"src={pill}/>
                        </div>
                         
                    </div>
                </div>
            </div>
            <EspecialityToasts/>
       
       
        
        </div>
    )
}


export default Login;