import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/actions';


function PrivateRoute(props:any) {

  const dispatch = useDispatch();
  const userLogged: any = useSelector((state: any) => state.login);
  const isAuthenticated = !userLogged.logData ? false : true;

  if (isAuthenticated === false){
    return <Navigate to={"/"} />
  } 
 
  const token:string = userLogged.logData.token;
  const tokenDecodedTime = jwt_decode<JwtPayload>(token).exp; 
  const thisMomentDate = Date.now() / 1000;                   

  if(tokenDecodedTime  && tokenDecodedTime < thisMomentDate){
    dispatch(logout());
    localStorage.removeItem('token');
    return <Navigate to={"/"}/>
  }

  

  return props.component  

}

export default PrivateRoute;