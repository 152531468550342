import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
//Svg and images
import deleteField from '../../../svg/deleteField.svg';
import addPurple from '../../../svg/addPurple.svg';
//Components
import { StyledMenu } from './StyledMenu';
import Pregunta from './Pregunta';
import Titulo from './Titulo';
import Separador from './Separador';
import OpcionUnica from './OpcionUnica';
import PreguntaFecha from './PreguntaFecha';
import PreguntaSwitch from './PreguntaSwitch';
import SubTitulo from './SubTitulo';
import PreguntaValidacion from './PreguntaValidacion';
//Services


const Field = ({ setFields, setSections,sections, sectionPosition, fields, inputsInSection, setInputsInSection}:any) => {


    //State and Handlers to open and close the ToolTip/Select
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //Button to delete Field
    const deleteFieldF = () => {
        // setFields((prevState:any)=>prevState.filter((f:any, index:any)=>index !== id))
        let cleaned = sections.map((section:any,index:any)=>{
            if(index === sectionPosition){
                section.json.data = []
            }
            return section
        })
        setSections(cleaned)
    };

   

    

    //Handler to add New inputs (menu items onClick)
    const addNewInput = (e:any)=>{
        let typeOfIntput = e.target.innerText;
        switch (typeOfIntput) {
            case "Título":
                let newInput = sections.map((s:any,index:any)=>{
                    if(index===sectionPosition){
                        s.json.data = [...s.json.data, {name:"", type:"Title"}]         
                    }
                    return s
                })
                setSections(newInput)
                handleClose()
                break
            
            case "Sub título":
                let newSubtitle = sections.map((s:any, index:any)=>{
                    if(index === sectionPosition){
                        s.json.data = [...s.json.data, {name:"", type:"Subtitle"}]
                    }
                    return s
                })
                setSections(newSubtitle)
                handleClose()
                break 
                
            case "Pregunta":
                let newQuestion = sections.map((s:any, index:any)=>{
                    if(index=== sectionPosition){
                        s.json.data = [...s.json.data, {name:"", type:"Text", value:"", enabled: false}]
                    }
                    return s
                })
                setSections(newQuestion)
                handleClose()
                break;

            case "Opción única":
                // setInputsInSection((prevState:any)=>[...prevState, {name:"", type:"Combo", value:"", hint:"Selecciona", values:[{value:"", id:""}] }])
                let newSelect = sections.map((s:any, index:any)=>{
                    if(index === sectionPosition){
                        s.json.data= [...s.json.data, {name:"", type:"Combo", value:"", hint:"Selecciona", values:[{value:"", id:""}] }]
                    }
                    return s
                })
                setSections(newSelect)
                handleClose()
                break; 
            
            case "Pregunta con fecha":
                let newQuestionRes = sections.map((s:any, index:any)=>{
                    if(index === sectionPosition){
                        s.json.data = [...s.json.data, {name:"", type:"DateTime", value:"",  enabled: false }]
                    }
                    return s
                })
                setSections(newQuestionRes)
                handleClose()
                break;
            
            case "Opciones Switch":    
                let newSwitch = sections.map((s:any,index:any)=>{
                    if(index === sectionPosition){
                        s.json.data = [...s.json.data, {name:"", type:"Switcher", value:false }]
                    }
                    return s
                })
                setSections(newSwitch)
                handleClose()
                break;
              
            case "Switch con validación":
                // setInputsInSection((prevState:any)=>[...prevState, {name:"", type:"TrueSwitcher",trueName:"",trueHintValue:"Tu respuesta", value:false, trueValue:"" }])
                let newValidation = sections.map((s:any,index:any)=>{
                    if(index === sectionPosition){
                        s.json.data = [...s.json.data,  {name:"", type:"TrueSwitcher",trueName:"",trueHintValue:"Tu respuesta", value:false, trueValue:"" }]
                    }
                    return s
                })
                setSections(newValidation)
                handleClose()
                break;   

            case "Separador":
                // setInputsInSection((prevState:any)=>[...prevState, {name:"", type:"Line", value:"horizontal"}])
                let newSeparator = sections.map((s:any,index:any)=>{
                    if(index === sectionPosition){
                        s.json.data = [...s.json.data, {name:"", type:"Line", value:"horizontal"}]
                    }
                    return s
                })
                setSections(newSeparator)
                handleClose()
                break;                     
                
            default:
                console.log("default")      
        }
    }
    
    
    return(
        
        <div className="field-father-container">
            <div className="field-display-container">
                <div>
                <Box sx={{ }}>
                    <List>
                        <TransitionGroup>
                            {sections[sectionPosition].json.data.map((i:any, index:string)=>{
                                if(i.type === "Title"){ return<Collapse key={index}>{<Titulo sectionPosition={sectionPosition} sections={sections} setSections={setSections} key={index} id={index}/>}</Collapse>}
                                if(i.type ==="Text"){return <Collapse key={index}>{<Pregunta sectionPosition={sectionPosition} sections={sections} setSections={setSections} key={index} id={index}/>}</Collapse>}
                                if(i.type === "Line"){return <Collapse key={index}>{<Separador  sectionPosition={sectionPosition} sections={sections} setSections={setSections} key={index} id={index}/>}</Collapse>}
                                if(i.type === "Combo"){return <Collapse key={index}>{<OpcionUnica sectionPosition={sectionPosition} sections={sections} setSections={setSections} key={index} id={index}/>}</Collapse>}
                                if(i.type === "DateTime"){return <Collapse key={index}>{<PreguntaFecha sectionPosition={sectionPosition} sections={sections} setSections={setSections} key={index} id={index}/>}</Collapse>}
                                if(i.type ==="Switcher"){return <Collapse key={index}>{<PreguntaSwitch sectionPosition={sectionPosition} sections={sections} setSections={setSections} key={index} id={index}/>}</Collapse>}
                                if(i.type ==="Subtitle"){return <Collapse key={index}>{<SubTitulo sectionPosition={sectionPosition} sections={sections} setSections={setSections} key={index} id={index}/>}</Collapse>}
                                if(i.type ==="TrueSwitcher"){return <Collapse key={index}>{<PreguntaValidacion sectionPosition={sectionPosition} sections={sections} setSections={setSections} key={index} id={index}/>}</Collapse>}
                            })}
                        </TransitionGroup>
                    </List>
                </Box>


                </div>
                <div className="field-tooltip-container">
                    <Tooltip title="">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <img alt="addInput" src={addPurple}/>
                        </IconButton>
                    </Tooltip>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                            <MenuItem sx={{fontFamily:'Poppins', display:'flex', justifyContent:'center' }}  onClick={(e)=>addNewInput(e)}>
                                Título
                            </MenuItem>
                            <MenuItem sx={{fontFamily:'Poppins', display:'flex', justifyContent:'center' }}  onClick={(e)=>addNewInput(e)}>
                                Sub título
                            </MenuItem>
                            <MenuItem sx={{fontFamily:'Poppins', display:'flex', justifyContent:'center' }}  onClick={(e)=>addNewInput(e)}>
                                Pregunta
                            </MenuItem>
                            <MenuItem sx={{fontFamily:'Poppins', display:'flex', justifyContent:'center' }}  onClick={(e)=>addNewInput(e)}>
                                Pregunta con fecha
                            </MenuItem>
                            <MenuItem sx={{fontFamily:'Poppins', display:'flex', justifyContent:'center'}} onClick={(e)=>addNewInput(e)}>
                                Opción única
                            </MenuItem>
                            <MenuItem sx={{fontFamily:'Poppins', display:'flex', justifyContent:'center'}} onClick={(e)=>addNewInput(e)}>
                                Switch con validación
                            </MenuItem>
                            <MenuItem sx={{fontFamily:'Poppins', display:'flex', justifyContent:'center'}} onClick={(e)=>addNewInput(e)}>
                                Opciones Switch
                            </MenuItem>
                            <MenuItem sx={{fontFamily:'Poppins', display:'flex', justifyContent:'center'}} onClick={(e)=>addNewInput(e)}>
                                Separador
                            </MenuItem>
                    </StyledMenu>
                </div>
            </div>
            <div className="field-buttondelete-container">
                <img alt="deleteField" src={deleteField} onClick={()=>deleteFieldF()}/>
            </div>
        </div>
        
    )
};

export default Field;