//Styles
import '../../../Styles/Specialitys.css';
//Hooks
import { useState, useEffect, useMemo } from 'react';
import {useTransition, animated} from 'react-spring';
//Data HardCoded
import usersData from '../../../data/tableHardCoded.json';
//Components
import SpecialitysModalAdd from './SpecialitysModalAdd';
import SpecialitysModalDelete from './SpecialitysModalDelete';
import Pagination from '../Pagination';
import "../FontawsomeIcons/Index";
import { EspecialityToasts } from '../Toastifys/EspecialityToasts';
import {Scrollbar} from 'smooth-scrollbar-react';
import { TailSpin } from 'react-loader-spinner';
import OrderSelect from '../Frequent Locations/OrderSelect';
//SVG and Images
import ATRAS from '../../../svg/ATRAS.svg';
import lupa from '../../../svg/lupa.svg';
import trashNew from '../../../svg/trashNew.svg';
import dontNew from '../../../svg/dontNew.svg';
import checkNew from '../../../svg/checkNew.svg';
import editNew from '../../../svg/pencilNew.svg';
//Functions
import {handleOpenModalDesactive, handleOpenModalDelete,  handleOpenModalActive,  handleOpenModalAdd} from '../../services/useFunctions';
import { useSelector } from "react-redux";
import { AnyObject } from 'immer/dist/internal';
import { useDispatch } from 'react-redux';
import { getSpecialitys } from '../../../actions/actions';
import SpecialitysModalActive from './SpecialitysModalActive';
import SpecialitysModalDesactive from './SpecialitysModalDesactive';
import EspecialitysModalEdit from './SpecialitysModalEdit';


const Specialitys = () =>{

    //UseDispatch (activate the actions of redux)
    const dispatch = useDispatch();

    //Fetch the users data
    const [userState, setUserState]:any[] = useState(usersData);
    const fetchSpecialitys = () =>{
        setUserState(usersData)    
    };

    //UseSelector of Users state in redux (Brings the state of redux to the component)
    const specialitysRedux:any = useSelector((state:any) => state.specialitys.specialitys);

    //user token in redux state
    const userToken: any = useSelector((state: any) => state.login.logData.token);

    //Fetch Data
    useEffect(()=>{
        dispatch(getSpecialitys(userToken))
        fetchSpecialitys(); 
    }, []);


    //MODAL STATES
    //Open close modal (and hidde the HUD)
    const [openModalDesactive, setOpenModalDesactive] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalActive, setOpenModalActive] = useState(false);
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [openModalEditSpeciality, setOpenModalEditSpeciality] = useState(false);
    const [userSelected, setUserSelected] = useState({nombre:"", apellido:"", id:"", code:"", description:""});

    //STATES
    const [totalItems, setTotalItems] =useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE =  4;
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({field:"", order:""});
    const [sortingOrder, setSortingOrder] = useState("asc");
    const [sortingField, setSortingField] =useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popperFiltersSelected, setPopperFiltersSelected] = useState([]);
    const [itemsSelected, setItemsSelected] = useState<AnyObject>({usersSelected: []});
    const [addSpecialityPost, setAddSpecialityPost] = useState<AnyObject>({nombre:"", descripcion:"", code:""});
    const [specialityToEdit, setSpecialityToEdit] = useState({id:"", code:"", name:"", description:""});

    
    //useMemo of users for the table
    const usersMemo = useMemo(()=>{
        let data= specialitysRedux;
        let mappedData:any= data.map((u:any)=>{
            if(u.status ==="1")u.status= "activo";
            if(u.status ==="0")u.status="inactivo";
            if(u.is_validated=== "1")u.is_validated="Validado";
            if(u.is_validated==="2")u.is_validated="Rechazado";
            return u
        });
        let computedUsers = mappedData;
        if(search){
            computedUsers = computedUsers.filter(
                (user:any) => 
                    user.name.toLowerCase().includes(search.toLowerCase()) ||
                    user.description?.toLowerCase().includes(search.toLowerCase()) ||
                    user.code?.toLowerCase().includes(search.toLowerCase()) ||
                    user.status.toLowerCase() === (search.toLowerCase())
            )
        };
        if(popperFiltersSelected){
            popperFiltersSelected.forEach((e:any)=>{
                return computedUsers = computedUsers.filter(
                    (user:any) =>
                        user[e.firstFilter]?.toLowerCase().includes(e.secondFilter.toLowerCase()) ||
                        user.first_surname?.toLowerCase().includes(e.secondFilter.toLowerCase())
                )
            })
        }
        setTotalItems(computedUsers.length);
        //sorting Users
        if (sorting.field){
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedUsers = computedUsers.sort((a:any, b:any)=> reversed * a[sorting.field]?.localeCompare(b[sorting.field]))
        };
        //CURRENT PAGES SLICE
        return computedUsers.slice( (currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE );
    },[userState, currentPage, search, sorting, popperFiltersSelected, specialitysRedux]);

    //SORT CHANGING STATES (recives asc, desc or wich collumn sort)
    const onSortingChange = (field:any) =>{
        const order = field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
        setSortingField(field);
        setSortingOrder(order);
        setSorting({field: field, order:order})
    }

    //SEARCH controller
    const onChangeSearch = (e:any) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

//Use Transitions allow the react_spring library
const transition = useTransition(specialitysRedux.length, {
    from: {opacity: 0 },
    enter: {opacity:1},
    leave: {},
    config: {duration: 100},
});

//Edit speciality controller (edit button in the table)
const handleEditSpeciality = (id:string, code:string, name:string, description:string) => {
    setSpecialityToEdit({id, code, name, description});
    setOpenModalEditSpeciality(true);
}

    return(
        <div>
            {transition(({opacity}:any, item)=>
            item === 0 ? <div className='loader-container'><TailSpin color='#6767AD'></TailSpin></div> :
            <animated.div style={{ opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })}}>
                <div id="usersContainerFather" className="users-container-father">
                            <div className='title-container'>
                                <h1 className='title-panel'>Especialidades</h1>
                            </div>

                            <div className="header_especialitys">
                                <div className="header-left">
                                    <div className="search-and-icon-container">
                                        <img className="input-search-icon" alt='searchIcon' src={lupa}></img>
                                        <input placeholder='Buscar' onChange={onChangeSearch} value={search} className="search-input"></input>
                                    </div>
                                    <div >             
                                        <OrderSelect setSorting={setSorting} onSortingChange={onSortingChange} ></OrderSelect>                                              
                                    </div>  
                                </div>
                                <div >
                                    <button className='add-button' onClick={()=>handleOpenModalAdd(setOpenModalAdd)}>+ Nueva Especialidad</button>
                                </div>
                            </div>

                            <div className="table-and-pagination-container">
                                <div className='table-container'>
                                    <table id='data-table'>
                                        <thead>
                                            <tr>
                                                <th id="first-row-table" onClick={()=>onSortingChange("code")}><span className="nowrap">Código   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "code" ? "arrow-down" : sortingOrder === "desc" && sortingField === "code" ? "arrow-up" : "visibilityHidden"} />
                                                )}</span></th> 
                                                <th id="second-row-table" onClick={()=>onSortingChange("name")}><span className="nowrap">Nombre   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "name" ? "arrow-down" : sortingOrder === "desc" && sortingField === "name" ? "arrow-up" : "visibilityHidden"} />
                                                )}</span></th>
                                                
                                                <th id="third-row-table" onClick={()=>onSortingChange("description")}><span className="nowrap">Descripción   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "description" ? "arrow-down" : sortingOrder === "desc" && sortingField === "description" ? "arrow-up" : "visibilityHidden"} />
                                                )}</span></th>
                                                <th className="collumnCenterAlign" onClick={()=>onSortingChange("is_validated")}><span className="nowrap">Estado   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "is_validated" ? "arrow-down" : sortingOrder === "desc" && sortingField === "is_validated" ? "arrow-up" : "visibilityHidden"} />
                                                )}</span></th>
                                                <th className="collumnCenterAlign" id="last-row-table">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersMemo.length === 0 &&
                                            
                                                <tr>
                                                    <td >
                                                        <p className="no-results-td-table">No hay resultados</p>
                                                    </td>
                                                </tr>
                                            }
                                            {usersMemo && usersMemo.map((user:any)=>(
                                        
                                                <tr key={user.id} id={user.id} >
                                                    <td>{user.code}</td>
                                                    <td>{user.name}</td>
                                                    <td ><Scrollbar ><div className='table-text-container'> {user.description}</div></Scrollbar></td>
                                                    <td className="collumnCenterAlign">{user.is_validated === "Validado" ? <button className='btn-activo'>Validado</button> : <button className='btn-inactivo'>Rechazado</button>}</td>
                                                    <td > <div className="collumnCenterAlignSpecial"><img className="trashNew brigthHover"src={trashNew} alt="deleteIcon" onClick={()=>handleOpenModalDelete(user.name, user.id, setUserSelected, setOpenModalDelete)}/> <img className="dont brigthHover"src={user.is_validated === "Validado" ? dontNew : checkNew} alt="desactiveIcon" onClick={user.is_validated === "Validado" ? ()=>handleOpenModalDesactive(user.name, user.first_surname, user.id, setUserSelected, setOpenModalDesactive) : ()=>handleOpenModalActive(user.name, user.id, setUserSelected, setOpenModalActive)}/> <img alt="editIcon" src={editNew} className="trashNew brigthHover" onClick={()=>handleEditSpeciality(user.id, user.code, user.name, user.description)}/></div></td>
                                                </tr>
                                        
                                            ))}
                                            
                                        </tbody>
                                    </table>
                                    
                                </div>
                                
                                <div className='pagination-container'>
                                        <Pagination 
                                            total={totalItems}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            currentPage={currentPage}
                                            onPageChange={(page:any) => setCurrentPage(page)}/>
                                </div>
                                

                            </div>
                            <SpecialitysModalDesactive openModalDesactive={openModalDesactive} setOpenModalDesactive={setOpenModalDesactive} userSelected={userSelected} userToken={userToken}/>
                            <SpecialitysModalActive openModalActive={openModalActive}setOpenModalActive={setOpenModalActive}userSelected={userSelected} userToken={userToken}/>
                            <SpecialitysModalDelete openModalDelete={openModalDelete} setOpenModalDelete={setOpenModalDelete} userSelected={userSelected}  userToken={userToken} />
                            <SpecialitysModalAdd openModalAdd={openModalAdd} setOpenModalAdd={setOpenModalAdd} setAddSpecialityPost={setAddSpecialityPost} addSpecialityPost={addSpecialityPost} userToken={userToken}/>
                            <EspecialitysModalEdit openModalEditSpeciality={openModalEditSpeciality} setOpenModalEditSpeciality={setOpenModalEditSpeciality} specialityToEdit={specialityToEdit} setSpecialityToEdit={setSpecialityToEdit} userToken={userToken}/>
                        
                        
                </div>
            </animated.div>
            )}
            <EspecialityToasts/>
        </div>
        
    )
};

export default Specialitys;