import { useSpring, animated } from 'react-spring';
//Svg and images
import deleteField from '../../../svg/deleteField.svg';
import addPurple from '../../../svg/addPurple.svg';

const OpcionUnica = ({sections, setSections,sectionPosition, id}:any) => {

    //Delete this input or delete the last option
    const deleteInput = () => {    
        let deleted = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data.map((i:any, index:any)=>{
                    if(index === id){
                        if(i.values[1].value ==="deleted" && i.values[i.values.length - 1].value ===  "deleted"){
                            i.type = "deleted"
                        }else if (i.values[1].value !=="deleted" && i.values[i.values.length - 1].value ==="deleted"){
                            i.values[1].value = "deleted"
                        }else if (i.values && i.values.length > 1){
                            i.values[i.values.length - 1].value = "deleted"
                            i.values[i.values.length - 1].id = "deleted"
                        }
                    }
                    return i
                })
            }
            return section
        })
        setSections(deleted)
    };

    //Change the name of the input
    const changeNameInput = (e:any) => {
        let updated = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data[id].name = e.target.value      
            }
             return section
        })
        setSections(updated)
    }

    //Change option name
    const changeOptionName = (e:any) => {
        let optionIndex = e.target.dataset.index;
        let updated= sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data[id].values[optionIndex].value = e.target.value;
                section.json.data[id].values[optionIndex].id = e.target.value
            }
            return section
        })
        setSections(updated)
    };

    //Add new option to this input
    const addNewOption = () => {
        let updated = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data.map((i:any,index:any)=>{
                    if(index === id){
                        i.values = [...i.values, {value: '', id: ''}]
                    }
                    return i
                })
            }
            return section
        })
        setSections(updated)
    };
    //get value of input name
    const findValueName = () => {
        let index = sections[sectionPosition].json.data[id].name;
        return index
    }
    //get value of input name
    const findOptionName = (indexOption:any) => {
        let index = sections[sectionPosition].json.data[id].values[indexOption].value;
        return index
    };

    //Animations config
    const styles = useSpring({
        from:{opacity: 0, x:0},
        to: {opacity: 1, x:0},
        leave: {opacity: 0, x:0},
        config: {duration: 150}
     });

    return (
        <animated.div style={{...styles}}>
            <div className="opcionUnica-container">        
                <div className="opcionUnica-name-container">
                    <input placeholder='Pregunta de opción desplegable' value={findValueName()} onChange={(e)=>changeNameInput(e)}></input>
                    <img alt='deleteSection' src={deleteField} onClick={()=>deleteInput()}></img>
                </div>
                <div className="opcionUnica-options-container">
                    <div className="opcion-unica-options-A">
                        {sections[sectionPosition].json.data[id].values.map((input:any, index:any)=>{
                            if(input.value !== "deleted"){
                                return <input key={index} value={findOptionName(index)} placeholder={"Opción " + String(index+1)} data-index={index} onChange={(e)=>changeOptionName(e)}/>
                            }
                            
                        })}
                    </div>
                    <div className="opcionUnica-addOption-container">
                        <img alt='addAnotherOption' src={addPurple} onClick={()=>addNewOption()}/>
                    </div>
                </div>
                <div>

                </div>
            </div>
        </animated.div>
    )
};

export default OpcionUnica;