import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import {Scrollbar} from 'smooth-scrollbar-react';
import {  Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { useState} from 'react';
import axios from 'axios';
import { Buffer } from 'buffer';
//Services
import { handleCloseModalEspecialityXdoctor } from '../../services/useFunctions';
import { matriculaAprobada, matriculaDesaprobada, especialidadAprobada, especialidadDesaprobada, succesVariable, errorDeLogin } from '../Toastifys/EspecialityToasts';
import { getDoctorPendingApproveDocuments,getEspecialityXdoctor, getDoctorById  } from '../../../actions/actions';
//Images and SVG 
import Verificado from '../../../svg/Verificado.svg';
import Noverificado from '../../../svg/Noverificado.svg';
import auxPic from '../../../svg/auxPic.svg';
import pdfIcon from '../../../svg/PDF.svg';
import pngIcon from '../../../svg/PNG.svg';
import locationIcon from '../../../svg/map_icon.svg';
import noData from '../../../svg/icons/ALERTA 2.svg';
import closeModal from '../../../svg/closeModal.svg';


const DoctorModalDocuments = ({doctorByIdRedux, openModalEspecialityXdoctor, setOpenModalEspecialityXdoctor, setOpenModalConfirmation1, setOpenModalConfirmation2}:any) => {

    //UseDispatch const
    const dispatch = useDispatch();
    //UseSelector of Documents To approve in redux 
    const documentsRedux:any = useSelector((state:any)=> state.users.documents);
    //user token in redux state
    const userToken: any = useSelector((state: any) => state.login.logData.token);
    //States
    const [iframeSelected, setIframeSelected] = useState("");

    //Function to approve enrrolment in the modal
    const approveEnrollment = async(idEnrollment:any, idDoctor:any) => {
        try{
            let postToSend = {
                id: idEnrollment,
                is_validated: 1
            }
            const {data}:{data:any} = await axios.post(`${process.env.REACT_APP_ORGANIZATION_AVE_APPROVE_ENROLLMENT}`, postToSend,{headers:{"Authorization":`Bearer ${userToken}`}});
            if(data.msg ==="Se valido la especialidad "){
                setTimeout(()=>{
                    matriculaAprobada();
                    dispatch(getDoctorPendingApproveDocuments(userToken, idDoctor));
                }, 200)
            }else{
                console.log("error desde el back al aprobar esta matricula")
            }
        }catch(error){
            console.log(error)
        }
    };

    //Function to desapprove the enrollment in the modal
    const desapproveEnrollment = async(idEnrollment:any, idDoctor:any) => {
        try{
            let postToSend = {
                id: idEnrollment,
                is_validated: 2
            };
            const {data}:{data:any} = await axios.post(`${process.env.REACT_APP_ORGANIZATION_AVE_APPROVE_ENROLLMENT}`, postToSend,{headers:{"Authorization":`Bearer ${userToken}`}});
            
            if(data.msg ==="Se valido la especialidad "){
                setTimeout(()=>{
                    matriculaDesaprobada()
                    dispatch(getEspecialityXdoctor(userToken))
                    dispatch(getDoctorPendingApproveDocuments(userToken, idDoctor));
                }, 200)        
            }else{
                console.log("error desde el back al aprobar esta matricula")
            }
        }catch(err){
  
        }
    };

    //Function to approve the speciality in the Modal
    const approveSpeciality = async(idSpeciality:any, idDoctor:any) => {
        try{
            let postToSend = {
                id: idSpeciality,
                is_validated: 1
            };
            const {data}:{data:any} = await axios.post(`${process.env.REACT_APP_ORGANIZATION_AVE_APPROVE_SPECIALITY}`, postToSend,{headers:{"Authorization":`Bearer ${userToken}`}});
            if(data.msg === "Se actualizo correctamente la especialidad del doctor"){
                setTimeout(()=>{
                    especialidadAprobada()
                    dispatch(getEspecialityXdoctor(userToken))
                    dispatch(getDoctorPendingApproveDocuments(userToken, idDoctor));
                }, 200)  
            }
        }catch(error){
    
        }
    };

    //Function to desApprove the speciality in the modal
    const desapproveSpeciality = async(idSpeciality:any, idDoctor:any) => {
        try{
            let postToSend = {
                id: idSpeciality,
                is_validated: 2
            };
            const {data}:{data:any} = await axios.post(`${process.env.REACT_APP_ORGANIZATION_AVE_APPROVE_SPECIALITY}`, postToSend,{headers:{"Authorization":`Bearer ${userToken}`}});
            if(data.msg === "Se actualizo correctamente la especialidad del doctor"){
                setTimeout(()=>{
                    especialidadDesaprobada()
                    dispatch(getEspecialityXdoctor(userToken))
                    dispatch(getDoctorPendingApproveDocuments(userToken, idDoctor));
                }, 200)  
            }
        }catch(error){
     
        }
    };

    //Validate or desvalidate doctor (switch)
    const validateDoctor = (e:any)=>{
        let id= doctorByIdRedux.id_doctor;
        let checked = e.target.checked;
        if(checked === true){
            let postTosend = {is_enabled: true};
            const activateDoc = async(post:object)=>{
                await axios.put(`${process.env.REACT_APP_ORGANIZATION_AVE_CHANGE_DOCTOR_VALIDATED}${id}`,post,{headers:{"Authorization":`Bearer ${userToken}`}})
                .then((response)=>{
                    dispatch(getEspecialityXdoctor(userToken))
                    dispatch(getDoctorPendingApproveDocuments(userToken, id));
                    dispatch(getDoctorById(userToken, id))
                    succesVariable("El doctor fue aprobado")
                }).catch((error)=>{
                  
                    errorDeLogin("Error activando el doctor")
                })
            }
            activateDoc(postTosend);
        }else if(checked === false){
            let postTosend = {is_enabled: false};
            const desactivateDoc = async(post:object)=>{
                await axios.put(`${process.env.REACT_APP_ORGANIZATION_AVE_CHANGE_DOCTOR_VALIDATED}${id}`,post,{headers:{"Authorization":`Bearer ${userToken}`}})
                .then((response)=>{
                    dispatch(getEspecialityXdoctor(userToken))
                    dispatch(getDoctorPendingApproveDocuments(userToken, id));
                    dispatch(getDoctorById(userToken, id))
                    succesVariable("El doctor fue aprobado")
                }).catch((error)=>{

                    errorDeLogin("Error activando el doctor")
                })
            }
            desactivateDoc(postTosend);
        }
    }

    //Open iframe when you click in the files icons
    const openIframe = async(path:any, fileType:any) => {
        if(fileType === "pdf"){
            const getBase64 = await axios.get(`${process.env.REACT_APP_DOWNLOAD_FILE_TO_APPROVE + path}`, {headers:{"Authorization": `Bearer ${userToken}`}, responseType: 'arraybuffer'})
            .then((response:any):any => Buffer.from(response.data, 'binary').toString('base64'))
            setIframeSelected(`data:application/pdf;base64,${getBase64}`)
            let iframeContainer = document.getElementById("iframe-file-container");
            iframeContainer?.classList.remove("hidden");
        }else if (fileType !== "pdf"){
            setIframeSelected(`${process.env.REACT_APP_DOWNLOAD_FILE_TO_APPROVE + path}`)
            let iframeContainer = document.getElementById("iframe-file-container");
            iframeContainer?.classList.remove("hidden");
        }
    };

    //Close iframe when you click in the X
    const closeIframe = () => {
        let iframeContainer = document.getElementById("iframe-file-container");
        iframeContainer?.classList.add("hidden");
    };

    return (
        <Modal open={openModalEspecialityXdoctor}
                onClose={()=>handleCloseModalEspecialityXdoctor(setOpenModalEspecialityXdoctor)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                    <div className="father-Modal-EspecialityXdoctor">
                        <div className="left-and-rigth-container">
                            <div className="left-modal-data-container">                    
                                <div className='image-modal-specialityXdoctor-container'>
                                    <img className="image-modal-ApproveDoctor" alt='profilePic' src={doctorByIdRedux.image !== null ? `http://files.dev-ave.online/file/download?url=${doctorByIdRedux.image}`  : auxPic}/>
                                    <p className="text-modal-EspecialityXdotor-image-title">{doctorByIdRedux.name}</p>
                                </div>
                                <div className="left-modal-texts-container">
                                    <div className="left-modal-texts-container-inside">
                                        <p className="text-modal-EspecialityXdotor-subtitle">Datos Personales</p>
                                        <p className="text-modal-EspecialityXdotor">Nombre y apellido:  {doctorByIdRedux.name}</p>
                                        <p className="text-modal-EspecialityXdotor">Fecha de nacimiento:{doctorByIdRedux.birthdate}</p>
                                        <p className="text-modal-EspecialityXdotor">CUIL: {doctorByIdRedux.cuil}</p>
                                        <p className="text-modal-EspecialityXdotor">Dirección: {doctorByIdRedux.address !== null ? `${doctorByIdRedux.address.raw}`   : "No registrada"}</p>
                                        <p className="text-modal-EspecialityXdotor">Email: {doctorByIdRedux.email}</p>
                                        <p className="text-modal-EspecialityXdotor">Teléfono: {doctorByIdRedux.phone}</p>
                                        <p className="text-modal-EspecialityXdotor-subtitle">Coberturas</p>
                                        {doctorByIdRedux.doctor_speciality_insurance_company.length < 1 ? 
                                        <div className="modal-EspecialityXdotor-cobertura-container">
                                            <img alt='iconVerify' src={Noverificado} className="text-modal-EspecialityXdotor-icon"/>
                                            <p className="text-modal-EspecialityXdotor">No posee coberturas</p>
                                        </div>
                                         :
                                         <div className='modal-EspecialityXdotor-coberturas-list'>
                                            {doctorByIdRedux.doctor_speciality_insurance_company.map((c:any, index:number)=>{
                                                if(c.insurance_company){
                                                    return (
                                                        <div key={index} className="modal-EspecialityXdotor-cobertura-container">
                                                            <img alt='iconVerify' src={c.insurance_company.status === "1" ? Verificado : Noverificado} className="text-modal-EspecialityXdotor-icon"/>
                                                            <p className="text-modal-EspecialityXdotor">{c.insurance_company.name}</p>
                                                        </div>
                                                    )
                                                }
                                            })}
                                         </div>

                                         
                                         }
                                        <p className="text-modal-EspecialityXdotor-subtitle">Estado</p>
                                        <div className="modal-EspecialityXdotor-cobertura-container">
                                            <button className={doctorByIdRedux.is_validated === "1" ? "dinamic-state-button-especialityXdoctorActivo" : "dinamic-state-button-especialityXdoctorInactivo" }>{doctorByIdRedux.is_validated === "1" ? "ACTIVO" : "INACTIVO"}</button>
                                            <Switch 
                                            onChange={(e)=>validateDoctor(e)}
                                            checked={doctorByIdRedux.is_validated === "1" ? true : false}
                                                sx={{width:'65px',
                                                    '& .MuiSwitch-track':{
                                                    maxWidth:'50px',
                                                    width:'50px',
                                                    height:'24px',
                                                    borderRadius:'14px'},
                                                    '& .MuiSwitch-switchBase':{
                                                        height:'20px',
                                                        marginTop:'14px',
                                                        marginLeft:'3px'
                                                    },
                                                    '& .MuiSwitch-input':{
                                                        left:'14px'
                                                    }
                                            }}></Switch>
                                        </div>
                                        
                                    </div>
                                                            
                                </div>
                            </div>
                            
                            <div className="right-modal-displayAndNav-container">                      
                                <div id="matriculas-display" className="rigth-modal-display"><Scrollbar >
                                    { documentsRedux.length > 0 && documentsRedux[0].doctor_enrollments ? documentsRedux[0].doctor_enrollments.map((doc:any, index:number)=>(
                                        
                                            <div key={index} className="display-matriculas-container">
                                            <div className="display-matriculas-texts">
                                                    <div className="text-modal-EspecialityXdotor-line-container">
                                                        <p className="text-modal-EspecialityXdotor-card-title">Matrícula</p>
                                                    </div>
                                                    <div className="text-modal-EspecialityXdotor-line-container">
                                                        <p className="text-modal-EspecialityXdotor-card-subtitle">{doc.enrollment_province_name}</p>
                                                    </div>
                                                    <div className="text-modal-EspecialityXdotor-line-container-2">
                                                        <p className="text-modal-EspecialityXdotor-card-title">Matrícula N°:</p>
                                                        <p className="text-modal-EspecialityXdotor-card-title">{doc.enrollment_code}</p>
                                                    </div>
                                                    <div className="text-modal-EspecialityXdotor-line-container-3">
                                                        <p className="text-modal-EspecialityXdotor-card-title">Verificar Nº de matrícula con SISA:</p>
                                                    </div>
                                                    <div className="text-modal-EspecialityXdotor-line-container-3">
                                                    <Link to="/Manualdevalidacióndelmédico.pdf" target="blank" download><p className="text-modal-EspecialityXdotor-card-link">Descargar Manual de SISA</p></Link>
                                                    </div>
                                                    <div className="text-modal-EspecialityXdotor-line-container-3">
                                                        <a href='https://sisa.msal.gov.ar/sisa/' target="_blank">  <p className="text-modal-EspecialityXdotor-card-link">Ir a la web de SISA</p></a>
                                                    </div>
                                                    <div className="text-modal-EspecialityXdotor-line-container-3">
                                                        <p className="text-modal-EspecialityXdotor-card-title">Estado:</p>
                                                        <img className="verify-icon-modal" alt='verifyIcon' src={doc.is_validated === "1" ? Verificado : Noverificado}/>
                                                    </div>                                             
                                                    <div className="display-texts-buttons-container">
                                                        <button className="button-si brigthHover" onClick={()=>approveEnrollment(doc.doctor_enrollment_id, doctorByIdRedux.id_doctor)}>Validar</button>
                                                        <button className="button-no brigthHover" onClick={()=>desapproveEnrollment(doc.doctor_enrollment_id, doctorByIdRedux.id_doctor)}>No validar</button>
                                                    </div>
                                                </div>
                                                <div className="display-matriculas-files">
                                                    {doc.files.map((file:any)=>(
                                                        <div key={file.file_name} className="file-container">
                                                            <img className={file.file_format === "pdf"? "iconFile" : "iconFile2"} alt='icon' src={file.file_format === "pdf" ? pdfIcon : file.file_format === "png" ? pngIcon : locationIcon } onClick={()=>openIframe(file.path, file.file_format)}/>
                                                            <p className="text-modal-EspecialityXdotor-file"> {file.file_name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        
                                    )) :  <div className='containter-dont-message'> <img src={noData} className='iconDontData' alt='iconDont'/><p className="text-modal-EspecialityXdotor">No se han encontrado matriculas de este doctor</p></div>}

                                    {documentsRedux.length > 0 &&  documentsRedux[0].doctor_specialities ? documentsRedux[0].doctor_specialities.map((doc:any, index:number)=>(
                                        <div key={index} className="display-matriculas-container">    
                                            <div className="display-matriculas-texts">
                                                <div className="text-modal-EspecialityXdotor-line-container">
                                                    <p className="text-modal-EspecialityXdotor-card-title">Especialidad</p>
                                                </div>
                                                <div className="text-modal-EspecialityXdotor-line-container">
                                                    <p className="text-modal-EspecialityXdotor-card-subtitle">{doc.speciality_name}</p>
                                                </div>
                                                <div className="text-modal-EspecialityXdotor-line-container-3">
                                                    <p className="text-modal-EspecialityXdotor-card-title">Codigo:  {doc.speciality_code}</p>
                                                </div>
                                                <div className="text-modal-EspecialityXdotor-line-container-3">
                                                        <p className="text-modal-EspecialityXdotor-card-title">Estado:</p>
                                                        <img className="verify-icon-modal" alt='verifyIcon' src={doc.is_validated === "1" ? Verificado : Noverificado}/>
                                                </div>
                                                <div className="display-texts-buttons-container">
                                                    <button className="button-si brigthHover" onClick={()=>approveSpeciality(doc.doctor_speciality_id, doctorByIdRedux.id_doctor)}>Validar</button>
                                                    <button className="button-no brigthHover" onClick={()=>desapproveSpeciality(doc.doctor_speciality_id, doctorByIdRedux.id_doctor)}>No validar</button>
                                                </div>
                                            </div>
                                            <div className="display-matriculas-files">
                                                {doc.files.map((file:any)=>(
                                                    <div key={file.file_name} className="file-container">
                                                        <img className={file.file_format === "pdf"? "iconFile" : "iconFile2"} alt='icon' src={file.file_format === "pdf" ? pdfIcon : file.file_format === "png" ? pngIcon : locationIcon} onClick={()=>openIframe(file.path, file.file_format)}/>
                                                        <p className="text-modal-EspecialityXdotor-file"> {file.file_name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )) :  <div className='containter-dont-message'> <img src={noData} className='iconDontData' alt='iconDont'/><p className="text-modal-EspecialityXdotor">No se han encontrado especialidades de este doctor</p></div>}
                                    </Scrollbar>
                                </div>

                                {/* <div id='informacion-display' className="rigth-modal-display-especialidades hidden">
                                    {Object.keys(doctorSelectedArchives).length > 0  && 
                                        <div className="display-informacion-container">
                                            <div className="display-matriculas-texts">
                                                <p className="text-modal-EspecialityXdotor">Título Médico</p>
                                                <div className="display-texts-buttons-container">
                                                    <button className="button-si">SI</button>
                                                    <button className="button-no">NO</button>
                                                </div>
                                            </div>
                                            <div className="display-matriculas-files">
                                                {doctorSelectedArchives.general_documents.medical_degree.files.map((file:any)=>(
                                                    <div className="file-container">
                                                        <img className="iconFile" alt='icon' src={file.file_format === "pdf" ? pdfIcon : file.file_format === "png" ? pngIcon : locationIcon } onClick={()=>openIframe(file.path, file.file_format)}/>
                                                        <p className="text-modal-EspecialityXdotor-file"> {file.file_name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    {Object.keys(doctorSelectedArchives).length > 0  &&
                                        <div className="display-informacion-container">
                                            <div className="display-matriculas-texts">
                                                <p className="text-modal-EspecialityXdotor">Documento de Identidad Nacional</p>
                                                <div className="display-texts-buttons-container">
                                                    <button className="button-si">SI</button>
                                                    <button className="button-no">NO</button>
                                                </div>
                                            </div>
                                            <div className="display-matriculas-files">
                                                {doctorSelectedArchives.general_documents.dni.files.map((file:any)=>(
                                                    <div className="file-container">
                                                        <img className="iconFile" alt='icon' src={file.file_format === "pdf" ? pdfIcon : file.file_format === "png" ? pngIcon : locationIcon } onClick={()=>openIframe(file.path, file.file_format)}/>
                                                        <p className="text-modal-EspecialityXdotor-file"> {file.file_name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    {Object.keys(doctorSelectedArchives).length > 0   && 
                                        <div className="display-informacion-container">
                                            <div className="display-matriculas-texts">
                                                <p className="text-modal-EspecialityXdotor">Seguro de mala praxis</p>
                                                <div className="display-texts-buttons-container">
                                                    <button className="button-si">SI</button>
                                                    <button className="button-no">NO</button>
                                                </div>
                                            </div>
                                            <div className="display-matriculas-files">
                                                {doctorSelectedArchives.general_documents.insurance_proof.files.map((file:any)=>(
                                                    <div className="file-container" >
                                                        <img className="iconFile" alt='icon' src={file.file_format === "pdf" ? pdfIcon : file.file_format === "png" ? pngIcon : locationIcon } onClick={()=>openIframe(file.path, file.file_format)}/>
                                                        <p className="text-modal-EspecialityXdotor-file"> {file.file_name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    } 
                                </div>*/}
                            </div>
                            
                        </div>
                        <div className="modal-EspecialityXdoctor-buttons-container">
                            <button className="modal-EspecialityXdoctor-button-salir" onClick={()=>setOpenModalConfirmation1(true)}>Salir</button>
                            <button className="modal-EspecialityXdoctor-button-guardar" onClick={()=>setOpenModalConfirmation2(true)}>Guardar</button>
                        </div>
                        <div id="iframe-file-container" className="iframe-file-container hidden">
                            <img className='closeModal' alt='closeModal' src={closeModal} onClick={closeIframe}/>
                            <iframe src={`${iframeSelected}`}  className={iframeSelected?"iframe-file": "hidden" }></iframe>
                            
                        </div>
                    </div> 
                </Modal>
    )
}

export default DoctorModalDocuments;