import Modal from '@mui/material/Modal';
import { handleCloseModalEspecialityXdoctor } from '../../services/useFunctions';

const DoctorModalConfirmation1 = ({openModalConfirmation1, setOpenModalConfirmation1, setOpenModalEspecialityXdoctor}:any) => {
    return(
            <Modal open={openModalConfirmation1}
                onClose={()=>setOpenModalConfirmation1(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                    <div className="father-Modal-Confirmation1">
                        <div className="confirmation1-title-container">
                            <p className="confirmation1-title">¿Desea salir sin guardar?</p>
                        </div>
                        <div className="confirmation1-buttons-container">
                            <button className="confirmation1-button-cancelar" onClick={()=>setOpenModalConfirmation1(false)}>Cancelar</button>
                            <button className="confirmation1-button-aceptar" onClick={()=>{setOpenModalConfirmation1(false); handleCloseModalEspecialityXdoctor(setOpenModalEspecialityXdoctor)}}>Aceptar</button>
                        </div>
                    </div> 
            </Modal>
    )
}

export default DoctorModalConfirmation1;