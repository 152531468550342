import axios from "axios";
import { doctorApproved, doctorRejected } from "../components/Toastifys/EspecialityToasts";
import doctorArchives from '../../data/response_validation_doctor.json';
import { store } from "../../redux/configureStore";
import { cleanPendingDocuments, getDoctorById, getRolesFunctions, cleanDoctorById } from "../../actions/actions";
import { getDoctorPendingApproveDocuments } from "../../actions/actions";






//Hide the Panel Admin HUD
export const hiddeHUD = () =>{
    document.getElementById("usersContainerFather")?.classList.add("display-hidden");
    document.getElementById("content-div")?.classList.add("display-hidden");
    document.getElementById("container-box-drawer-list")?.classList.add("display-hidden");
};

//Show the Panel Admin HUD
export const showHUD = () =>{
    document.getElementById("usersContainerFather")?.classList.remove("display-hidden");
    document.getElementById("content-div")?.classList.remove("display-hidden");
    document.getElementById("container-box-drawer-list")?.classList.remove("display-hidden");
};


//Modal desactivate User  SET OPEN MODAL
export const handleOpenModalDesactive = (nombre:string, apellido:string, id:string, setUserSelected:any, setOpenModalDesactive:any) => {
    setUserSelected({nombre:nombre, apellido:apellido, id: id})
    setOpenModalDesactive(true);  
};

//Modal desactivate MAP MODAL (close modal)
export const handleCloseModalMap = (setOpenModalMap:any) =>{
    showHUD();
    setOpenModalMap(false);
};

//Modal desactivate User SET OPEN MODAL in false (close modal)
export const handleCloseModalDesactive = (setOpenModalDesactive:any) =>{
    showHUD();
    setOpenModalDesactive(false);
};
//Modal AGREGAR User SET OPEN MODAL in false (close modal)
export const handleCloseModalAddUser = (setOpenModalAdd:any) =>{
    
    setOpenModalAdd(false);
};
export const handleCloseModalAddUser2 = (setOpenModalAdd2:any) =>{
    showHUD();
    setOpenModalAdd2(false);
};
//Modal AGREGAR User  SET OPEN MODAL
export const handleOpenModalAddUser = ( setOpenModalAdd:any) => {
        setOpenModalAdd(true);  
};
//Modal adduser2 agregar button handler
export const modalAddUser2Agregarbutton = (setOpenModalAdd2:any) => {
    showHUD();
    setOpenModalAdd2(false);
}
//Modal adduser2 atras button handler
export const handleClickBtnModalAtrasAddUser2 = (setOpenModalAdd2:any, setOpenModalAdd:any) => {
    setOpenModalAdd2(false);
    setOpenModalAdd(true); 
}

//Modal Add Role in table users open
export const handleOpenModalAddRole = (setOpenModalAddRole:any, token:string) => {
    store.dispatch(getRolesFunctions(token))
    setOpenModalAddRole(true);
}
//Modal add role in table users CLOSING
export const handleCloseModalAddRole = (setOpenModalAddRole:any) => {
    showHUD();
    setOpenModalAddRole(false);
}
//Modal add role cancelar button
export const handleClickBtnModalCancelarAddRole = (setOpenModalAddRole:any) => {
    showHUD();
    setOpenModalAddRole(false);
}

//Modal Confirmation in Users adding new ROLE
export const handleCloseModalConfirmation = (setOpenModalConfirmation:any) => {
    setOpenModalConfirmation(false)
}
//Modal Confirmation in Users adding new ROLE
export const handleCloseModalConfirmation2 = (setOpenModalConfirmation2:any) => {
    setOpenModalConfirmation2(false)
}
//Modal Confirmation in Users Adding a new ROLE
export const confirmationModalAccept = (setOpenModalConfirmation:any, setOpenModalAddRole:any, setNewRole:any )=> {
    setOpenModalConfirmation(false);
    setOpenModalAddRole(false);
    setNewRole({description:"", name:""})
};




//Modal delete ESPECIALITY SET OPEN MODAL
export const handleOpenModalDeleteEspeciality = (nombre:string, apellido:string, setUserSelected:any, setOpenModalDelete:any) => {
    setUserSelected({nombre:nombre, apellido:apellido})
    hiddeHUD();
    setOpenModalDelete(true);  
};

//Modal delete User SET OPEN MODAL
export const handleOpenModalDelete = (nombre:string ,id:string, setUserSelected:any, setOpenModalDelete:any) => {
    setUserSelected({nombre:nombre, id:id})
    setOpenModalDelete(true);  
};


//Modal delete User SET MODAL OPEN in false (close Modal)
export  const handleCloseModalDelete = (setOpenModalDelete:any) =>{
    setOpenModalDelete(false);
};

//Modal Approve doctor 
export const handleCloseModalApproveDoctor = (setOpenModalApproveDoctor:any) =>{
    showHUD();
    setOpenModalApproveDoctor(false);
};

//Modal Approve doctor SET OPEN MODAL
export const handleOpenModalApproveDoctor = (provincia:string, dni:any, mp:any, cuil:any, nombre:string, apellido:string, segundoApellido:string, id:string, setDoctorToapproveSelected:any, setOpenModalApproveDoctor:any)=>{
    hiddeHUD();
    setDoctorToapproveSelected({provincia: provincia, dni: dni, mp:mp, cuil: cuil, nombre: nombre, apellido: apellido + " "+ segundoApellido, id: id});
    setOpenModalApproveDoctor(true);
}

//Modal Activate User SET MODAL OPEN
export const handleOpenModalActive = (nombre:string, id:string ,setUserSelected:any, setOpenModalActive:any) => {
    setUserSelected({nombre:nombre, id:id})
    setOpenModalActive(true);  
}; 

//Modal Activate User SET OPEN MODAL in false (close Modal)
export const handleCloseModalActive = (setOpenModalActive:any) =>{
    showHUD();
    setOpenModalActive(false);
};

//Modal add user SET OPEN MODAL
export const handleOpenModalAdd = (setOpenModalAdd:any) => {

    setOpenModalAdd(true);  
};

//Modal add user SET OPEN in false (close Modal)
export  const handleCloseModalAdd = (setOpenModalAdd:any) =>{

    setOpenModalAdd(false);
};

//Button of Modal Desactivate User CANCEL
export const handleClickBtnModalCancelarDesactive = (handleCloseModalDesactive:any, setOpenModalDesactive:any) =>{
    handleCloseModalDesactive(setOpenModalDesactive);
};


//Button of Modal Desactivate User Accept
export const handleClickBtnModalAceptarDesactive = (handleCloseModalDesactive:any, setOpenModalDesactive:any) =>{
    handleCloseModalDesactive(setOpenModalDesactive);
};


//Button of Modal AGREGAR User CANCEL
export const handleClickBtnModalCancelarAddUser = (setOpenModalAdd:any) =>{
    setOpenModalAdd(false);
    showHUD();
}

//Button of Modal AGREGAR User Accept
export const handleClickBtnModalAceptarAddUser = (handleCloseModalAddUser:any, setOpenModalAdd:any, setOpenModalAdd2:any) =>{
    handleCloseModalAddUser(setOpenModalAdd);
    setOpenModalAdd2(true)
};


//Button of Modal Activate User CANCEL
export const handleClickBtnModalCancelarActive = (handleCloseModalActive:any, setOpenModalActive:any) =>{
    handleCloseModalActive(setOpenModalActive);
};

//Button of Modal Activate User ACCEPT
export const handleClickBtnModalAceptarActive= (handleCloseModalActive:any ,setOpenModalActive:any) =>{
    handleCloseModalActive(setOpenModalActive);
};

//Button of Modal Delete CANCEL
export const handleClickBtnModalCancelarDelete = (handleCloseModalDelete:any, setOpenModalDelete:any) =>{
    handleCloseModalDelete(setOpenModalDelete);
};

//Button of Modal Delete ACCEPT
export const handleClickBtnModalAceptarDelete = (handleCloseModalDelete:any, setOpenModalDelete:any) =>{
    handleCloseModalDelete(setOpenModalDelete);
};

//Button of Modal Add CANCEL
export const handleClickBtnModalCancelarAdd = (handleCloseModalAdd:any, setOpenModalAdd:any ) =>{
    handleCloseModalAdd(setOpenModalAdd);
};
//Function to send the approve doctor to the back and change the "is_verificated" to 2 (rechazado)
export const changeIsVerificatedRejected = async (id:any, token:any)=>{
    try{
        let postToSend = {"id": id, "is_validated": 2};
        const {data}:{data:any} = await axios.post(`${process.env.REACT_APP_ORGANIZATION_AVE_APPROVE_DOCTOR}`, postToSend, {headers:{"Authorization":`Bearer ${token}`}} );
        if (data.msg ==="Se valido la especialidad "){
            doctorRejected()
        }
    }catch(error){
        console.log(error)
    }
};


//Button of Modal Approve Doctor NO VERIFICADO
export const handleClickBtnModalApproveDoctorNoVerificado = (handleCloseModalApproveDoctor:any,setOpenModalApproveDoctor:any, id:any, token:any )=>{
    handleCloseModalApproveDoctor(setOpenModalApproveDoctor);
    changeIsVerificatedRejected(id, token)
};

//Function to send the approve doctor to the back and change the "is_verificated" to 1
export const changeIsVerificated = async(id:any, token:any) => {
  
    try{
        let postToSend = {"id": id, "is_validated": 1};
        const {data}:{data:any} = await axios.post(`${process.env.REACT_APP_ORGANIZATION_AVE_APPROVE_DOCTOR}`, postToSend, {headers:{"Authorization":`Bearer ${token}`}} );
        if (data.msg==="Se valido la especialidad "){
            doctorApproved()
        }
    }catch(error){
    }
}

//Button of Modal Approve Doctor VERIFICADO
export const handleClickBtnModalApproveDoctorVerificado = (handleCloseModalApproveDoctor:any,setOpenModalApproveDoctor:any, id:any, token:any)=>{
    handleCloseModalApproveDoctor(setOpenModalApproveDoctor);
    changeIsVerificated(id, token);
};




//Modal Especiality X Doctor open Modal 
export const handleOpenModalEspecialitXdoctor = async (id:any, userToken:any, setOpenModalEspecialityXdoctor:any) => {
    await store.dispatch(getDoctorById(userToken, id))
    await store.dispatch(getDoctorPendingApproveDocuments(userToken, id))
    setTimeout(()=>{
        setOpenModalEspecialityXdoctor(true);
    }, 200)
};


//Modla Especiality X Doctor CLOSE Modal
export const handleCloseModalEspecialityXdoctor =  (setOpenModalEspecialityXdoctor:any) =>{
    setOpenModalEspecialityXdoctor(false)
    store.dispatch(cleanPendingDocuments())
    store.dispatch(cleanDoctorById())
};




//Open Modal Notifications Create/edit
export const handleOpenModalNotifications = (setGoToEdit:any ,setOpenModalNotifications:any, setNotificationSelected:any)=>{
    setGoToEdit(false);
    setNotificationSelected({id:"", icon:[], name:"", description:"", variables:"",when_notification:"", for_doctor:false, for_beneficiary:false});
    setOpenModalNotifications(true);
}

//Close Modal Notifications Create/edit
export const handleCloseModalNotifications = (setOpenModalNotifications:any)=>{
    setOpenModalNotifications(false)
}


//Click in GUARDAR button in Modal Notifications Create
export const handleClickGuardarModalNotifications = (goToEdit:any, notificationSelected:any, setNotificationSelected:any) => {
    if(goToEdit){
    }else{
    }
}

//Click in CANCELAR button in modal Notifications Create
export const handleClickCancelarModalNotifications = (notificationSelected:any, setNotificationSelected:any, setOpenModalNotifications:any)=>{
    setNotificationSelected({id:"", icon:[], name:"", description:"", variables:"",when_notification:"", for_doctor:false, for_beneficiary:false});
    setOpenModalNotifications(false);
    console.log(notificationSelected)
}


//Click in EDIT in Notifications
export const handleClickEditNotifications = (setGoToEdit:any,setOpenModalNotifications:any, name:any, description:any, variables:any, when_notification:any, for_doctor:any, for_beneficiary:any, setNotificationSelected:any, id:any, notificationSelected:any, icon:any) => {
    setGoToEdit(true);
    setNotificationSelected({...notificationSelected, id, icon, name, description, variables:parseInt(variables), when_notification, for_doctor:(for_doctor==="Si"? true : false), for_beneficiary:(for_beneficiary==="Si"? true : false)});
    setOpenModalNotifications(true)
}