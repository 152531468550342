import Modal from '@mui/material/Modal';
import axios from 'axios';
//Hooks
import { useDispatch } from 'react-redux';
//Functions and services
import { getUsers } from '../../../actions/actions';
import { succesVariable, errorDeLogin } from '../Toastifys/EspecialityToasts';
//Styles
import '../../../Styles/Users.css';
//SVG and Images
import trashNew from '../../../svg/trashNew.svg';

const UserModalDelete = ({openModalDelete, setOpenModalDelete, userSelected, userToken}:any) => {

    //Use dispatch hook
    const dispatch = useDispatch();
    //Modal ACCEPT button
    const modalDeleteAcceptButton = ()=> {
        const deleteUser = async () => {
            await axios.post(`${process.env.REACT_APP_AUTH_AVE}`, {headers:{"Authorization":`Bearer ${userToken}`}})
            .then((response)=>{
                dispatch(getUsers(userToken));
                succesVariable("El usuario fue creado con éxito!");
                setOpenModalDelete(false);
            }).catch((error)=>{

                errorDeLogin(error.response.data.error.message) 
            })
        };
        deleteUser()
    };

    return(
        <Modal open={openModalDelete}
            onClose={()=>setOpenModalDelete(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-Modal">
                    <div id="modal-modal-title">
                        <img src={trashNew} alt="delete Icon" className="trash-modal-icon"/>
                        <p >¿Desea eliminar al usuario?</p>
                    </div>
                    <div id="modal-modal-description">
                        <div className='modal-description-text'>
                            <p>Si acepta, eliminará a <span className='text-highlight'>{userSelected.nombre} {userSelected.apellido}</span> de usuarios. ¿Esta seguro que desea continuar?</p>           
                        </div>
                        <div className="modal-container-btns">
                            <button className='modal-btn-cancelar' onClick={()=>setOpenModalDelete(false)}>Cancelar</button>
                            <button className="modal-btn-aceptar" onClick={()=>modalDeleteAcceptButton()}>Aceptar</button>
                        </div>
                    </div>
                </div>
        </Modal>
    )
}

export default UserModalDelete;