import { combineReducers } from 'redux';
import { loginReducer } from './loginReducer';
import { usersReducer } from './usersReducer';
import {specialitysReducer} from './specialitysReducer';
import { frequentLocationReducer } from './frecuentLocationsReducer';
import { notificationReducer } from './notificationReducer';
import { templateReducer } from './templatesReducer';




export const rootReducer = combineReducers({
    login: loginReducer,
    users: usersReducer,
    specialitys: specialitysReducer,
    frequentLocations: frequentLocationReducer,
    notification: notificationReducer,
    template: templateReducer
});