import deleteField from '../../../svg/deleteField.svg';
import { useSpring, animated } from 'react-spring';


const Separador = ({sections, setSections,sectionPosition, id}:any) => {

    //Delete this input
    const deleteInput = () => {
        let deleted = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data.map((i:any, index:any)=>{
                    if(index === id){
                        i.type= "deleted"                   
                    }
                    return i
                })
            }
            return section
        })
        setSections(deleted)
    };

    //Animations config
    const styles = useSpring({
        from:{opacity: 0, x:0},
        to: {opacity: 1, x:0},
        leave: {opacity: 0, x:0},
        config: {duration: 150}
     });


    return (
        <animated.div style={{...styles}}>
            <div className="separador-container">
                <div className="separador-hr-container">
                    <hr></hr>
                </div>
                <div className='separador-icon-container'>
                    <img alt='deleteSection' src={deleteField} onClick={()=>deleteInput()}></img>
                </div>
            </div>
        </animated.div>
        
    )
}

export default Separador;