import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import axios from 'axios';
//Styles
import '../../../Styles/Users.css';
import '../../../Styles/Beneficiaries.css';
//SVG and images
import checkNew from '../../../svg/checkNew.svg';
import { getBeneficiaryList } from '../../../actions/actions';
import { succesVariable, errorDeLogin } from '../Toastifys/EspecialityToasts';

const BeneficiariesModlaActivate = ({userSelected, userToken, setOpenModalActive, openModalActive}:any) => {

    //Use dispatch hook
    const dispatch = useDispatch();
    //Modal Acept Button DESACTIVE BENEFICIARY
    const activateBeneficiary = () =>{
        let postToSend= {is_enabled: true};
        const activateBen = async(post:object)=>{
            await axios.put(`${process.env.REACT_APP_ORGANIZATION_AVE_CHANGE_VALIDATED_BENEFICIARY}${userSelected.id}`, post, {headers:{"Authorization":`Bearer ${userToken}`}})
            .then((response)=>{
                dispatch(getBeneficiaryList(userToken));
                succesVariable("El beneficiario fue desactivado con éxito");
                setOpenModalActive(false);
            }).catch((error)=>{
                errorDeLogin("Error activando al beneficiario")
            })
        }
        activateBen(postToSend);
    };


    return(
        <Modal open={openModalActive}
            onClose={()=>setOpenModalActive(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-Modal">
                    <div id="modal-modal-title">
                        <img src={checkNew} alt="activate Icon" className="check-modal-icon"/>
                        <p>¿Desea activar al usuario?</p>
                    </div>
                    <div id="modal-modal-description">
                        <div className='modal-description-text'>
                            <p>Si acepta, el beneficiario <span className='text-highlight'>{userSelected.nombre}</span>  podrá utilizar la app con normalidad. ¿Esta seguro que desea continuar?</p>                
                        </div>
                        <div className="modal-container-btns">
                            <button className='modal-btn-cancelar' onClick={()=>setOpenModalActive(false)}>Cancelar</button>
                            <button className="modal-btn-aceptar" onClick={()=>activateBeneficiary()}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </Modal>
    )
}


export default BeneficiariesModlaActivate;