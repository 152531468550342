//Styles
import '../../../Styles/Users.css';
import '../../../Styles/ApproveDoctor.css';
import '../../../Styles/Doctors.css';
import '../../../Styles/Notifications.css';
//Hooks
import { useState, useEffect } from 'react';
import {useTransition, animated} from 'react-spring';
//Components
import Pagination from '../Pagination';
import "../FontawsomeIcons/Index";
import Modal from '@mui/material/Modal';
import { TailSpin } from 'react-loader-spinner';
import OrderSelect from '../Frequent Locations/OrderSelect';
import { EspecialityToasts,  errorHabilitandoNotificacion, errorDeshabilitandoNotificacion, notificacionHabilitada, notificacionDeshabilitada } from '../Toastifys/EspecialityToasts';
//SVG and Images
import { iconsFolder } from '../../../data/allIcons';
import ATRAS from '../../../svg/ATRAS.svg';
import pill from '../../../svg/icons/CARGANDO.svg';
import lupa from '../../../svg/lupa.svg';
import dontNew from '../../../svg/dontNew.svg';
import checkNew from '../../../svg/checkNew.svg';
import pencilNew from '../../../svg/pencilNew.svg'
//Functions
import {handleOpenModalNotifications, handleClickEditNotifications } from '../../services/useFunctions';
import { useSelector } from "react-redux";
import { AnyObject } from 'immer/dist/internal';
import { getNotifications} from '../../../actions/actions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { NotificationsMemoSettings } from './Notifications_Services';
import NotificationsModal from './NotificationsModal';


const Notifications = () => {

    //UseDispatch (activate the actions of redux)
    const dispatch = useDispatch();

    //Fetch the users data CUANDO ESTE EL ENDPOINT CAMBIAR ESTE SETUSERSTATE
    const [userState, setUserState]:any[] = useState();
    const fetchUsers = () =>{
        setUserState(NotificationsRedux)    
    };

    //UseSelector of Users state in redux (Brings the state of redux to the component)
    const NotificationsRedux:any = useSelector((state:any) => state.notification.notification); 

    //user token in redux state
    const userToken: any = useSelector((state: any) => state.login.logData.token);

    //Fetch Data
    useEffect(()=>{
        dispatch(getNotifications(userToken))
        fetchUsers(); 
    }, []);
   
    //MODAL STATES
    //Open close modal (and hidde the HUD)
    const [openModalNotifications, setOpenModalNotifications] = useState(false);
    const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

    //STATES
    const [totalItems, setTotalItems] =useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE =  4;
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({field:"", order:""});
    const [sortingOrder, setSortingOrder] = useState("asc");
    const [sortingField, setSortingField] =useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popperFiltersSelected, setPopperFiltersSelected] = useState<AnyObject>([]);
    const [itemsSelected, setItemsSelected] = useState<AnyObject>({usersSelected: []});
    const [notificationSelected, setNotificationSelected] = useState<AnyObject>({id:"", icon:"", name:"", description:"", variables:0 ,when_notification:"", for_doctor:false, for_beneficiary:false});
    const [goToEdit, setGoToEdit] = useState(false);
    const [isActivated, setIsActivated] = useState(false);
  
    //useMemo of users for the table
    const usersMemo = NotificationsMemoSettings(NotificationsRedux,search,popperFiltersSelected,setTotalItems,sorting,currentPage,ITEMS_PER_PAGE,userState)

    //SORT CHANGING STATES (recives asc, desc or wich collumn sort)
    const onSortingChange = (field:any) =>{
        const order = field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
        setSortingField(field);
        setSortingOrder(order);
        setSorting({field: field, order:order})
    }

    //SEARCH controller
    const onChangeSearch = (e:any) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };
    
    
    //Modal confirmation
    const confirmationModalAccept = (setOpenModalConfirmation:any, setOpenModalNotifications:any)=>{
        setOpenModalConfirmation(false);
        setOpenModalNotifications(false);
        setNotificationSelected({id:"", icon:[], name:"", description:"", variables:"",when_notification:"", for_doctor:false, for_beneficiary:false});
    }
    //Click in CANCELAR button in modal Notifications Create
    const handleClickCancelarModalNotifications = (notificationSelected:any, setNotificationSelected:any, setOpenModalNotifications:any)=>{
        setNotificationSelected({id:"", icon:[], name:"", description:"", variables:"",when_notification:"", for_doctor:false, for_beneficiary:false});
        setOpenModalNotifications(false);
}

    //Modal CREATE/EDIT Notification
    const [variablesState, setVariablesState]= useState(0);

    //Button Activate Desactivate Notification
    const activateDesactivateNotification = async (id:any, status:any, isActivated:any, setIsActivated:any) => {
        if(status==="Activo"){
            
            let postToSend1 = {"status": "0"};
            const {data}:{data:any} = await axios.put(`${process.env.REACT_APP_CONSULT_EDIT_NOTIFICATION}${id}`, postToSend1,{headers:{"Authorization":`Bearer ${userToken}`}});
            if(data.msg === "Se guardo correctamente "){
                notificacionDeshabilitada()
                setTimeout(()=>{
                    dispatch(getNotifications(userToken))
                }, 300);
                return
            }else{
                errorDeshabilitandoNotificacion()
                return
            }
        }else if (status==="Inactivo"){
            let postToSend = {"status": "1"};
            const {data}:{data:any} = await axios.put(`${process.env.REACT_APP_CONSULT_EDIT_NOTIFICATION}${id}`, postToSend,{headers:{"Authorization":`Bearer ${userToken}`}});
            if(data.msg === "Se guardo correctamente "){
                notificacionHabilitada()
                setTimeout(()=>{
                    dispatch(getNotifications(userToken))
                }, 300);
                return
            }else{
                errorHabilitandoNotificacion()
                return
            }
        }; 
    };

    let renderIcon = (arrayIcons:any, userIconName:any) => {
        let finded = arrayIcons.filter((icon:any)=>icon.name === userIconName);
        if(finded.length > 0){return (<img alt='icon' className="pillIcon" src={finded[0].src}/>)
            }else{
                return(<img alt='icon' className="pillIcon" src={pill}/>)
            }
    };

    //Use Transitions allow the react_spring library
    const transition = useTransition(NotificationsRedux.length, {
        from: {opacity: 0 },
        enter: {opacity:1},
        leave: {},
        config: {duration: 100},
    });
   
    return(
        <div>
         {transition(({opacity}:any, item)=>
         item === 0 ? <div className='loader-container'><TailSpin color='#6767AD'></TailSpin></div> : 
         <animated.div style={{ opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })}}>
            <div id="usersContainerFather" className="users-container-father">
                <div className='title-container'>
                    <h1 className='title-panel'>Notificaciones</h1>
                </div>
                <div className="header-0">
                    <div className="header-1">
                        <div className="search-and-icon-container">
                            <img className="input-search-icon" alt='searchIcon' src={lupa}></img>
                            <input placeholder='Buscar' onChange={onChangeSearch} value={search} className="search-input"></input>
                        </div>
                        <div >             
                            <OrderSelect setSorting={setSorting} onSortingChange={onSortingChange} ></OrderSelect>                                              
                        </div>  
                    </div>
                    
                    <div>
                        <button className='add-button brigthHover' onClick={()=>handleOpenModalNotifications(setGoToEdit, setOpenModalNotifications, setNotificationSelected)}>+ Nueva Notificación</button>
                    </div>
                </div>
                <div className="table-and-pagination-container">
                    <div className='table-container'>
                        <table id='data-table'>
                            <thead>
                                <tr className="tr-table-tittles">
                                    
                                    <th className="collumnStartAlign" id="first-row-table" onClick={()=>onSortingChange("icon")}><span className="nowrap">Icono   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "icon" ? "arrow-down" : sortingOrder === "desc" && sortingField === "icon" ? "arrow-up" : "visibilityHidden"} />
                                    )}</span></th>
                                    <th className="collumnStartAlign" onClick={()=>onSortingChange("template_code")}><span className="nowrap">Codigo   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "template_code" ? "arrow-down" : sortingOrder === "desc" && sortingField === "template_code" ? "arrow-up" : "visibilityHidden"} />
                                    )}</span></th>
                                    <th className="collumnStartAlign" onClick={()=>onSortingChange("name")}><span className="nowrap">Nombre   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "name" ? "arrow-down" : sortingOrder === "desc" && sortingField === "name" ? "arrow-up" : "visibilityHidden"} />
                                    )}</span></th>
                                    <th className="collumnStartAlign" onClick={()=>onSortingChange("description")}><span className="nowrap">Descripción   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "description" ? "arrow-down" : sortingOrder === "desc" && sortingField === "description" ? "arrow-up" : "visibilityHidden"} />
                                    )}</span></th>
                                    <th className="collumnCenterAlign" onClick={()=>onSortingChange("variables")}><span className="nowrap">Variables   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "variables" ? "arrow-down" : sortingOrder === "desc" && sortingField === "variables" ? "arrow-up" : "visibilityHidden"} />
                                    )}</span></th>
                                    <th className="collumnCenterAlign" onClick={()=>onSortingChange("for_doctor")}><span className="nowrap">Doctor   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "for_doctor" ? "arrow-down" : sortingOrder === "desc" && sortingField === "for_doctor" ? "arrow-up" : "visibilityHidden"} />
                                    )}</span></th>
                                    <th className="collumnCenterAlign" onClick={()=>onSortingChange("for_beneficiary")}><span className="nowrap">Benefeciario   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "for_beneficiary" ? "arrow-down" : sortingOrder === "desc" && sortingField === "for_beneficiary" ? "arrow-up" : "visibilityHidden"} />
                                    )}</span></th>
                                    <th className="collumnCenterAlign" onClick={()=>onSortingChange("status")}><span className="nowrap">Estado   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "status" ? "arrow-down" : sortingOrder === "desc" && sortingField === "status" ? "arrow-up" : "visibilityHidden"} />
                                    )}</span></th>
                                    <th className="collumnCenterAlign" id="last-row-table">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {usersMemo.length === 0 &&
                                
                                    <tr>
                                        <td >
                                            <p className="no-results-td-table">No hay resultados</p>
                                        </td>
                                    </tr>
                                }
                                {usersMemo && usersMemo.map((user:any)=>(
                            
                                    <tr key={user.name} id={user.name} className="">
                                        
                                        <td className="collumnStartAlign">{renderIcon(iconsFolder, user.icon)}
                                        </td>
                                        <td className="collumnStartAlign">{user.template_code}</td>
                                        <td className="collumnStartAlign">{user.name}</td>
                                        <td className="collumnStartAlign">{user.description}</td>
                                        <td className="collumnCenterAlign">{user.variables}</td>
                                        <td className="collumnCenterAlign">{user.for_doctor }</td>
                                        <td className="collumnCenterAlign">{user.for_beneficiary } </td>
                                        <td className="collumnStartAlign">{user.status === "Activo" ? <button className='btn-activo'>Activo</button> : <button className='btn-inactivo'>Inactivo</button>}</td>
                                        <td className=" collumnCenterAlign"><img className="trashNew brigthHover" src={pencilNew} alt="editIcon" onClick={()=>handleClickEditNotifications(setGoToEdit, setOpenModalNotifications, user.name, user.description, user.variables, user.when_notification, user.for_doctor, user.for_beneficiary, setNotificationSelected, user.id, notificationSelected, user.icon)}/> <img className="trashNew brigthHover" src={user.status==="Activo" ? dontNew : checkNew} alt='desactivate' onClick={()=>activateDesactivateNotification(user.id, user.status,isActivated, setIsActivated)}/></td>
                                    </tr>
                            
                                ))}
                                
                            </tbody>
                        </table>
                    </div>
                    <div className='pagination-container'>
                        <Pagination 
                            total={totalItems}
                            itemsPerPage={ITEMS_PER_PAGE}
                            currentPage={currentPage}
                            onPageChange={(page:any) => setCurrentPage(page)}/>
                    </div>
                </div>

                <NotificationsModal openModalNotifications={openModalNotifications}setOpenModalNotifications={setOpenModalNotifications}goToEdit={goToEdit}notificationSelected={notificationSelected}setNotificationSelected={setNotificationSelected}variablesState={variablesState}setOpenModalConfirmation={setOpenModalConfirmation}/>

                <Modal open={openModalConfirmation}
                onClose={()=>setOpenModalConfirmation(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                    <div className="father-Modal-confirmation">
                        <div className="modal-confirmation-title" id="modal-modal-title">
                            <p>¿Desea salir sin guardar?</p>
                        </div>               
                        <div className="modal-container-btns">
                            <button className='modal-btn-cancelar' onClick={()=>setOpenModalConfirmation(false)}>Cancelar</button>
                            <button className="modal-btn-aceptar" onClick={()=>confirmationModalAccept(setOpenModalConfirmation, setOpenModalNotifications)}>Aceptar</button>
                        </div> 
                    </div>
                </Modal>
                <EspecialityToasts/> 
            </div>
        </animated.div>
         )}   
        </div>
        
    )
};

export default Notifications;