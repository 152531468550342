import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import axios from 'axios';
//Styles
import '../../../Styles/Users.css';
import '../../../Styles/Beneficiaries.css';
//SVG and images
import dontNew from '../../../svg/dontNew.svg';
import { getBeneficiaryList } from '../../../actions/actions';
import { succesVariable, errorDeLogin } from '../Toastifys/EspecialityToasts';


const BeneficiariesModalDesactive = ({openModalDesactive, setOpenModalDesactive, userSelected, userToken}:any) => {

    //Use dispatch hook
    const dispatch = useDispatch();
    //Modal Acept Button DESACTIVE BENEFICIARY
    const desactiveBeneficiary = () => {
        let postToSend = {is_enabled: false};
        const desactiveBen = async(postToSend:object)=>{
            await axios.put(`${process.env.REACT_APP_ORGANIZATION_AVE_CHANGE_VALIDATED_BENEFICIARY}${userSelected.id}`, postToSend,{headers:{"Authorization":`Bearer ${userToken}`}})
            .then((response)=>{
                dispatch(getBeneficiaryList(userToken));
                succesVariable("El beneficiario fue desactivado con éxito");
                setOpenModalDesactive(false);
            }).catch((error)=>{
                errorDeLogin("Error desactivando al beneficiario")
            })
        }
        desactiveBen(postToSend);
    };


    return(
        <Modal open={openModalDesactive}
            onClose={()=>setOpenModalDesactive(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-Modal">
                    <div id="modal-modal-title">
                        <img src={dontNew} alt="desactivate icon" className="dont-modal-icon"/>
                        <p>¿Desea desactivar al usuario?</p>
                    </div>
                    <div id="modal-modal-description">
                        <div className='modal-description-text'>
                            <p>Si acepta, el usuario <span className='text-highlight'>{userSelected.nombre}</span> no podrá utilizar la app hasta que vuelva a activarlo. ¿Esta seguro que desea continuar?</p>              
                        </div>
                        <div className="modal-container-btns">
                            <button className='modal-btn-cancelar' onClick={()=>setOpenModalDesactive(false)}>Cancelar</button>
                            <button className="modal-btn-aceptar" onClick={()=>desactiveBeneficiary()}>Aceptar</button>
                        </div>
                    </div>
                </div>
        </Modal>
    )
}


export default BeneficiariesModalDesactive;
