//Components
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
//Styles
import '../../../Styles/FiltersPopper.css';
//Hooks
import {  useState } from 'react';
import { AnyObject } from 'immer/dist/internal';

export const FiltersPopperEstado = ({idPopperEstado, openEstado, anchorElEstado, setPopperFiltersSelected, popperFiltersSelected, setRoleFilterViewerEstado, roleFilterViewerEstado, handleClickPopper}:any) => {

    //States
    const [roleSelected, setRoleSelected] = useState<AnyObject>({activado:false, desactivado:false})

      // Close the dropdown menu if the user clicks outside of it
      window.onclick = function(event:any) {
        if (!event.target.matches('.dropbtn')) {
          var dropdowns = document.getElementsByClassName("dropdown-content");
          var i;
          for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
              openDropdown.classList.remove('show');
            }
          }
          
        }
      }

    //Delete all
    const deleteAll = () => {
        let inputContainer= document.getElementById('inputsContainer');
        let inputs = inputContainer?.querySelectorAll('input');
        inputs?.forEach((input:any)=>{
            input.checked = false
        })
        setRoleSelected({activado:false, desactivado:false})
        setRoleFilterViewerEstado("Estado")
        setPopperFiltersSelected([]);
    }
    //CheckBox handler for "ROL"
    const checkHandler = (e:any) => {
        let checked = e.target.checked;
        let name = e.target.name.split(".");
        let state = popperFiltersSelected;
        let id = e.target.id;
        let viewer = roleFilterViewerEstado;


        if(checked===true){
            if(id==="Activado"){
                setRoleSelected({...roleSelected, activado:true}) 
                if(viewer.includes("Estado")){viewer=""}
                if(!viewer.includes("Activado") && viewer.length > 0){
                    viewer= viewer  + "/" + "Activado"
                }else{
                    viewer= "Activado"
                }
            }
            if(id==="Desactivado"){
                setRoleSelected({...roleSelected, desactivado:true})
                if(viewer.includes("Estado")){viewer=""}
                if(!viewer.includes("Desactivado") && viewer.length > 0){
                    viewer= viewer +  "/" + "Desactivado"
                }else{
                    viewer= "Desactivado"
                }
            }
            setPopperFiltersSelected([...popperFiltersSelected, {secondFilter: name[1], firstFilter:name[0]}])
            setRoleFilterViewerEstado(viewer)

        }else if (checked ===false){
            if(id==="Activado"){
                setRoleSelected({...roleSelected, activado:false})
                if(!viewer.includes("/")){
                    viewer="Estado"
                }else if (viewer.includes("/")){
                    viewer= viewer.replace("/","")
                    viewer= viewer.replace("Activado","")
                }
            }
            if(id==="Desactivado"){
                setRoleSelected({...roleSelected, desactivado:false})
                if(!viewer.includes("/")){
                    viewer="Estado"
                }else if (viewer.includes("/")){
                    viewer= viewer.replace("/","")
                    viewer= viewer.replace("Desactivado","")
                }         
            }
            let filtered = state.filter((f:any)=> f.secondFilter !== name[1])
            setPopperFiltersSelected(filtered)
            setRoleFilterViewerEstado(viewer)
        }
    }




    return (
        <Popper id={idPopperEstado} open={openEstado} anchorEl={anchorElEstado} placement={'bottom-start'}>
            <Box sx={{width: 334, height: 192, p: 2, bgcolor: 'background.paper', borderRadius:6}} className="popperBox" id='inputsContainer'>
                <div className="filters-header">
                    <h3 className="filter-header-title">Estado</h3>
                    <h6 className="filter-aplicar-text" onClick={(e)=>handleClickPopper(e)}>Aplicar</h6>
                </div>
                <div className="section-filter-1">
                    <div className="section-inputs-cotainer">
                        <div className="section-filter-and-label-container">
                            <input name='is_verified.Activado'id='Activado' checked={roleSelected.activado} type='checkbox' onClick={(e)=>checkHandler(e)}></input>
                            <label className="section-filter-label" >Activado</label> 
                        </div>
                        <div className="section-filter-and-label-container">
                            <input name='is_verified.Desactivado' id='Desactivado' checked={roleSelected.desactivado} type='checkbox' onClick={(e)=>checkHandler(e)}></input>
                            <label className="section-filter-label">Desactivado</label>
                        </div>                   
                    </div>
                    <div className="deleteAll-container">
                        <h6 className="filter-deleteAll-text" onClick={()=>deleteAll()}>Borrar todo</h6>
                    </div>
                </div>
            </Box>
        </Popper>
    )
}