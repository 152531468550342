import axios from 'axios';
import {useState } from 'react';
import { useSelector } from "react-redux";
import { useSpring, animated } from 'react-spring';
//Svg and images
import deleteField from '../../../svg/deleteField.svg';
import { errorDeLogin, succesVariable } from '../Toastifys/EspecialityToasts';
import collapseIcon from '../../../svg/icons/ATRAS.svg';
//Components
import Field from './Field';

const Section = ({setSections,sections,sectionPosition, inputsInSection, setInputsInSection, id}:any) => {

    //State of fields
    const [fields, setFields] = useState<any[]>([]);

    //user token in redux state
    const userToken: any = useSelector((state: any) => state.login.logData.token);

    //Button X to delete the section
    const deleteSection = () => {
        setSections((prevState:any) => prevState.filter((s:any, index:any)=>index !== sectionPosition))
    };

    //Input to name the action
    const changeSectionName = (e:any)=>{
        let updated = sections.map((s:any, index:any)=>{
            if(index === sectionPosition){
                s.page_name = e.target.value
            }
            return s
        })
    setSections(updated)      
    };

    //Input to change the section name
    const changeSectionNumber = (e:any)=> {
        let updated = sections.map((s:any, index:any)=>{
            if(index === sectionPosition){
                s.page= e.target.value    
            }
            return s
        })
        setSections(updated)  
    };

    //Button to add new FIELD
    const addNewField = () => {
        setFields((prevState:any)=> [...prevState, {name:"", type:"", value:"", enabled:""}])
    };
    

    //Accept to apply the new myHealth
    const sendMyHealth = async() => {
        let cleaned = sections.map((section:any,index:any)=>{
            if(index===sectionPosition){
                section.json.data = section.json.data.filter((e:any)=>e.type !== "deleted")    
            }
            return section
        });
        let onlySection= cleaned.filter((e:any, index:number)=>index === sectionPosition)
        let postToSend = {page_name:onlySection[0].page_name, data:{data: onlySection[0].json.data} }
        await axios.post(`${process.env.REACT_APP_CONSULT_AVE_GENERATE_MYHEALTH_PAGE}${onlySection[0].page}`,postToSend,{headers:{"Authorization": `Bearer ${userToken}`}})
        .then((response)=>{

            succesVariable("Se guardo la plantilla correctamente")
        })
        .catch((error)=>{

            errorDeLogin("Error creando plantilla")
        })
    };

    //Delete the section here and in the db
    const deletePage = async() => {
        let cleaned = sections.map((section:any,index:any)=>{
            if(index===sectionPosition){
                section.json.data = section.json.data.filter((e:any)=>e.type !== "deleted")    
            }
            return section
        });
        deleteSection()
        let onlySection= cleaned.filter((e:any, index:number)=>index === sectionPosition)
        let id = onlySection[0].page;
        await axios.delete(`${process.env.REACT_APP_CONSULT_AVE_DELETE_MYHEALTH_PAGE}${id}`,{headers:{"Authorization": `Bearer ${userToken}`}})
        .then((response)=>{
            succesVariable(`Se elimino la sección N°${id}`)
        })
        .catch((error)=>{
            errorDeLogin("Error eliminando sección")
        })
    };

    //get value f input
    const findValueSection = () => {
        let index = sections[sectionPosition].page_name;
        return index
    }
    const findValueSectionNumber = () => {
        let index = sections[sectionPosition].page;
        return index
    };

    //Animations config
    const styles = useSpring({
        from:{opacity: 0, x:0},
        to: {opacity: 1, x:0},
        leave: {opacity: 0, x:0},
        config: {duration: 150}
     });

    //Collapse handler 
    const collapse = (e:any) => {
        const arrow = e.target;
        const sectionContainer= document.getElementById(`containerToCollapse${id}`);
        const sectionField = document.getElementById(`containerToDissaper1${id}`);
        const sectionButtons= document.getElementById(`containerToDissaper2${id}`);
        sectionContainer?.classList.toggle('section-father-container');
        sectionField?.classList.toggle('displayed');
        sectionButtons?.classList.toggle('section-buttons-container');
        arrow?.classList.toggle('rotated');
    }; 

    return (
        <animated.div style={{...styles}}>
            <div className="collapsed" id={`containerToCollapse${id}`}>
                <div className="section-input-and-delete-container" >
                    <input placeholder='Nombre Sección' id="sec-input-name" value={findValueSection()} onChange={(e)=>changeSectionName(e)}></input>
                    <label>N° Página:</label>
                    <input placeholder='N°' id='sec-input-number' value={findValueSectionNumber()} onChange={(e)=>changeSectionNumber(e)}></input>
                    <img alt='deleteSection' src={deleteField} onClick={()=>deleteSection()}></img>
                    <img alt='collapseSection' src={collapseIcon} className="colapseSection" onClick={(e)=>collapse(e)}/>
                </div>
                <div className="unDisplayed" id={`containerToDissaper1${id}`}>
                    <Field setSections={setSections} sections={sections} sectionPosition={sectionPosition} inputsInSection={inputsInSection} setInputsInSection={setInputsInSection} setFields={setFields} fields={fields}></Field>       
                </div>
                <div className="unDisplayed" id={`containerToDissaper2${id}`}>
                    <button className="section-button-cancelar" onClick={()=>deletePage()}>Eliminar</button>
                    <button className="section-button-guardar" onClick={()=>sendMyHealth()}>Guardar</button>
                </div>
            </div>
        </animated.div>
    )
};

export default Section;