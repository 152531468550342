import Switch from '@mui/material/Switch';
import { useSpring, animated } from 'react-spring';
//Svg and images
import deleteField from '../../../svg/deleteField.svg';

const PreguntaValidacion = ({sections, setSections,sectionPosition, id}:any) => {

    //Change name of the input
    const changeNameInput = (e:any) => {
        let updated = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data[id].name = e.target.value
            }   
            return section   
        })
        setSections(updated)
    }

    //Change the validation question
    const changeInputValidationName = (e:any) => {
        let updated = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data[id].trueName = e.target.value   
            }
            return section
        })
        setSections(updated)
    };
    
     //Delete this input
     const deleteInput = () => {
        let deleted = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data[id].type = "deleted"
            }    
            return section
        })
        setSections(deleted)
    };

    //get value f input
    const findValueValidation = () => {
        let index = sections[sectionPosition].json.data[id].trueName;
        return index
    };
    //get value f input
    const findValueQuestion = () => {
        let index = sections[sectionPosition].json.data[id].name;
        return index
    };

    //Animations config
    const styles = useSpring({
        from:{opacity: 0, x:0},
        to: {opacity: 1, x:0},
        leave: {opacity: 0, x:0},
        config: {duration: 150}
     });

    return(
        <animated.div style={{...styles}}>
            <div className="validacion-container">
                <div className="validacion-name-container">
                    <input placeholder="Pregunta..." value={findValueQuestion()} onChange={(e)=>changeNameInput(e)}/>
                </div>
                <div className="validacion-switch-container">
                    <div>
                        <label>SI</label>
                        <Switch                            
                            sx={{width:'65px',
                                '& .MuiSwitch-track':{
                                maxWidth:'50px',
                                width:'50px',
                                height:'24px',
                                borderRadius:'14px'},
                                '& .MuiSwitch-switchBase':{
                                    height:'20px',
                                    marginTop:'14px',
                                    marginLeft:'3px'
                                },
                                '& .MuiSwitch-input':{
                                    left:'14px'
                                }
                            }}/>
                        <label>NO</label>
                    </div>
                    <div >
                        <img alt='deleteSection' src={deleteField} onClick={()=>deleteInput()}></img>
                    </div>
                </div>
                <div className="validacion-validation-container">
                    <input placeholder="En caso de responder si..." value={findValueValidation()}  onChange={(e)=>changeInputValidationName(e)}/>
                </div>
                
            </div>
        </animated.div>
    )
};

export default PreguntaValidacion;