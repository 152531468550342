//Styles
import '../../../Styles/Users.css';
import '../../../Styles/Beneficiaries.css';
//SVG and images
import loaderDisplay from '../../../svg/loaderDisplay.svg';



export const DisplayInformationHeader = ({beneficiaryByIdRedux}:any) => {
    return(
        <div style={{height:'30%'}}>
            {typeof beneficiaryByIdRedux === 'object' && !Array.isArray(beneficiaryByIdRedux) ? 
                <div className="display-information-header">
                    <img alt='profilePicture' className='profile-picture' src={`http://files.dev-ave.online/file/download?url=${beneficiaryByIdRedux.image}`}/>
                    <p className="profile-picture-text">{beneficiaryByIdRedux.name}</p>
                </div> 
                : 
                <div className="display-information-header-loader">
                    <img alt='loader' className='header-loader' src={loaderDisplay}/>
                </div>
            }
        </div>
        
    )
} 