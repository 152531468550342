import Modal from '@mui/material/Modal';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getRoles } from '../../../actions/actions';
import { succesVariable, errorDeLogin } from '../Toastifys/EspecialityToasts';
//Components
import RolesCheckboxes from './RolesCheckboxes';
import RolesCheckboxesEdit from './RolesCheckboxesEdit';

const RolesModalAdd = ({roleIdToEditOnly, userToken, roleToEditState, functions, openModalAddRole,checkHandlerFatherEdit, setOpenModalAddRole,goToEdit, newRole, setNewRole, actions, checkHandlerFather,rolesFunctions, setOpenModalConfirmation2, setOpenModalConfirmation }:any)=>{

    const dispatch= useDispatch();
    //Button Accept to EDIT a role
    const editRole = () => {
        let postToSend = {functions: roleToEditState.functions, name: newRole.name, description: newRole.description}
        const sendEditOfRole = async(post:any) =>{
            await axios.put(`${process.env.REACT_APP_AUTH_AVE_GET_ROLE_BY_ID}${roleIdToEditOnly}`,post, {headers:{"Authorization":`Bearer ${userToken}`}})
            .then((response)=>{
                setOpenModalAddRole(false);
                dispatch(getRoles(userToken));
                succesVariable("El rol fue editado correctamente");
            }).catch((error)=>{
                console.log(error)
                errorDeLogin("Error editando el rol")
            })
        } 
        sendEditOfRole(postToSend)
    }

    return(
        <Modal open={openModalAddRole}
            onClose={()=>setOpenModalAddRole(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-Modal-addRole">
                    <div className="modal-AddRole-title-container" id="modal-modal-title">
                        <p>{goToEdit===true ? "Editar Rol" : "Nuevo Rol"}</p>
                    </div>
                    <div className="modal-addRole-inputs-container">
                        <p className="modal-addRole-subtitle">Nombre</p>
                        <input className="modal-AddRole-input-name-role" placeholder='Nombre' value={newRole.name} onChange={(e:any)=>setNewRole({...newRole, name:e.target.value})}></input>
                    </div>
                    <div className="modal-addRole-inputs-container">
                        <p className="modal-addRole-subtitle">Descripción</p>
                        <input className="modal-AddRole-input-name-role" placeholder='Descripción' value={newRole.description} onChange={(e:any)=>setNewRole({...newRole, description:e.target.value})}></input>
                    </div>
                    <div>
                        <p className="modal-addRole-subtitle">Secciones y acciones</p>
                    </div>
                    <div className="modal-AddRole-description-container" id="modal-modal-description">
           
                            {goToEdit===true ? <RolesCheckboxesEdit  roleToEditState={roleToEditState} functions={functions} actions={actions} checkHandlerFatherEdit={checkHandlerFatherEdit} checkHandlerFather={checkHandlerFather} rolesFunctions={rolesFunctions}  setNewRole={setNewRole}></RolesCheckboxesEdit> : 
                                                <RolesCheckboxes actions={actions} checkHandlerFather={checkHandlerFather} rolesFunctions={rolesFunctions}  setNewRole={setNewRole}></RolesCheckboxes>
                            }

                        
                        
                        <div className="modal-container-btns">
                            <button className='modal-btn-cancelar' onClick={ ()=>setOpenModalConfirmation(true) }>Cancelar</button>
                            <button className= "modal-btn-aceptar"  onClick={goToEdit===false ? ()=>setOpenModalConfirmation2(true) : ()=>editRole()}>Aceptar</button>
                        </div>
                    </div>
                    
                </div>
        </Modal>
    )
};

export default RolesModalAdd;