//Styles
import '../../../Styles/Users.css';
import '../../../Styles/FrecuentLocationsMap.css';
//Hooks
import { useState, useEffect, useMemo } from 'react';
import {useTransition, animated} from 'react-spring';
//Data HardCoded
import usersData from '../../../data/tableHardCoded.json';
//Components
import Pagination from '../Pagination';
import "../FontawsomeIcons/Index";
import Modal from '@mui/material/Modal';
import { TailSpin } from 'react-loader-spinner';
import { FrecuentLocationsMap } from '../Maps/FrecuentLocationsMap';
//SVG and Images
import ATRAS from '../../../svg/ATRAS.svg';
import lupa from '../../../svg/lupa.svg';
//Functions
import {handleCloseModalMap} from '../../services/useFunctions';
import { useSelector } from "react-redux";
import { getFrequentLocations} from '../../../actions/actions';
import { useDispatch } from 'react-redux';
import OrderSelect from './OrderSelect';

const FrequentLocations = () =>{

    //UseDispatch (activate the actions of redux)
    const dispatch = useDispatch();

    //Fetch the users data
    const [userState, setUserState]:any[] = useState(usersData);
    const fetchUsers = () =>{
        setUserState(locationsRedux)    
    };

    //UseSelector of Users state in redux (Brings the state of redux to the component)
    const locationsRedux:any = useSelector((state:any) => state.frequentLocations.frequentLocations);

    //user token in redux state
    const userToken: any = useSelector((state: any) => state.login.logData.token);

    //Fetch Data
    useEffect(()=>{
        dispatch(getFrequentLocations(userToken))
        fetchUsers(); 
    }, []);

   
    //MODAL STATES
    //Open close modal (and hidde the HUD)
    const [openModalMap, setOpenModalMap] = useState(false);


    //STATES
    const [totalItems, setTotalItems] =useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE =  9;
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({field:"", order:""});
    const [sortingOrder, setSortingOrder] = useState("asc");
    const [sortingField, setSortingField] =useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElMap, setAnchorElMap] = useState<null | HTMLElement>(null);
    const [popperFiltersSelected, setPopperFiltersSelected] = useState([]);

    
    //useMemo of users for the table
    const usersMemo = useMemo(()=>{
        let data= locationsRedux;
        let mappedData:any= data.map((u:any)=>{
            if(u.status ==="1")u.status= "activo";
            if(u.status ==="0")u.status="inactivo";
            return u
        });
        let computedUsers = mappedData;
        if(search){
            computedUsers = computedUsers.filter(
                (user:any) => 
                String(user.name).toLowerCase().includes(search.toLowerCase()) ||
                String(user.city).toLowerCase().includes(search.toLowerCase()) ||
                String(user.state).toLowerCase().includes(search.toLowerCase()) ||
                String(user.street).toLowerCase().includes(search.toLowerCase()) ||
                String(user.reference).toLowerCase().includes(search.toLowerCase()) ||
                String(user.status).toLowerCase() === (search.toLowerCase())
            )
        }
        if(popperFiltersSelected){
            popperFiltersSelected.forEach((e:any)=>{
                return computedUsers = computedUsers.filter(
                    (user:any) =>
                        user[e.firstFilter]?.toLowerCase().includes(e.secondFilter.toLowerCase()) ||
                        user.first_surname?.toLowerCase().includes(e.secondFilter.toLowerCase())
                )
            })
        }
        setTotalItems(computedUsers.length);
        //sorting Users
        if (sorting.field){
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedUsers = computedUsers.sort((a:any, b:any)=> reversed * a[sorting.field]?.localeCompare(b[sorting.field]))
        };
        //CURRENT PAGES SLICE
        return computedUsers.slice( (currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE );
    },[userState, currentPage, search, sorting, popperFiltersSelected, locationsRedux]);

    //POPPER
    const handleClickPopper = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClickPopperMap = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMap(anchorElMap ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const openMap = Boolean(anchorElMap);
    const id = open ? 'simple-popper' : undefined;
    const idMap = openMap ? 'simple-popper' : undefined;

    const removeFilter = (filter:any) => {
        setPopperFiltersSelected(prevState => prevState.filter((i:any)=>(i.secondFilter !== filter)))
    }

    //SORT CHANGING STATES (recives asc, desc or wich collumn sort)
    const onSortingChange = (field:any) =>{
        const order = field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
        setSortingField(field);
        setSortingOrder(order);
        setSorting({field: field, order:order})
    }

    //SEARCH controller
    const onChangeSearch = (e:any) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };
    
    //Open map handler
    const[coords, setCoords]= useState({longitude:"", latitude:""})
    const openMapView = (longitude:any, latitude:any) => {
        setCoords({longitude:longitude, latitude:latitude});
        setOpenModalMap(true)
    }

    //Use Transitions allow the react_spring library
    const transition = useTransition(locationsRedux.length, {
        from: {opacity: 0 },
        enter: {opacity:1},
        leave: {},
        config: {duration: 100},
    });
   
    return(
        <div>
            {transition(({opacity}:any, item)=>
            item === 0 ? <div className='loader-container'><TailSpin color='#6767AD'></TailSpin></div> :
            <animated.div style={{ opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })}}>
                <div id="usersContainerFather" className="users-container-father">
            <div className='title-container'>
                <h1 className='title-panel'>Centros de atención</h1>
            </div>

            <div className="header-search-and-order-container">
                <div className="search-and-icon-container">
                    <img className="input-search-icon" alt='searchIcon' src={lupa}></img>
                    <input placeholder='Buscar' onChange={onChangeSearch} value={search} className="search-input"></input>
                </div>
                <div >             
                    <OrderSelect setSorting={setSorting} onSortingChange={onSortingChange} ></OrderSelect>                                              
                </div>         
            </div>

            <div className="table-and-pagination-container">
                <div className='table-container'>
                    <table id='data-table'>
                        <thead>
                            <tr>
                                <th  className="collumnCenterAlign" id="first-row-table" onClick={()=>onSortingChange("name")}><span className="nowrap">Nombre   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "name" ? "arrow-down" : sortingOrder === "desc" && sortingField === "name" ? "arrow-up" : "visibilityHidden"} />
                                )}</span></th>
                                <th className="collumnCenterAlign" onClick={()=>onSortingChange("street")}><span className="nowrap">Dirección   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "street" ? "arrow-down" : sortingOrder === "desc" && sortingField === "street" ? "arrow-up" : "visibilityHidden"} />
                                )}</span></th>

                                <th className="collumnCenterAlign" id="last-row-table">Cantidad de Médicos AVE</th>
                            </tr>
                        </thead>
                         <tbody>
                            {usersMemo.length === 0 &&
                            
                                <tr>
                                    <td >
                                        <p className="no-results-td-table">No hay resultados</p>
                                    </td>
                                </tr>
                            }
                            {usersMemo && usersMemo.map((user:any)=>(
                           
                                <tr key={user.name} id={user.name} className="">
                                    <td className="collumnCenterAlign">{user.name}</td>
                                    <td className="collumnCenterAlign"><p className="link-to-address" onClick={()=>openMapView(user.address.longitude, user.address.latitude) }>{user.address.state} - {user.address.city} - {user.address.street}</p></td>
                                    <td className="collumnCenterAlign">{user.doctorCount} </td>
                                </tr>
                           
                            ))}
                            
                        </tbody>
                    </table>
                </div>
                
                <div className='pagination-container'>
                    <Pagination 
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page:any) => setCurrentPage(page)}/>
                </div>
                

            </div>
            <Modal open={openModalMap}
            onClose={()=>handleCloseModalMap(setOpenModalMap)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-map-container">
                    <FrecuentLocationsMap center={{lat: coords.latitude, lng: coords.longitude}} zoom={11} data={coords}></FrecuentLocationsMap>
                </div>
            </Modal>
                </div>
            </animated.div>
            )}
        </div>
        
    )
};

export default FrequentLocations;