import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { TailSpin } from  'react-loader-spinner'
//Styles
import '../../../Styles/MyHealth.css';
//Components
import Section from './Section';
import { getMyHealth } from '../../../actions/actions';
import { EspecialityToasts } from '../Toastifys/EspecialityToasts';

const MyHealth = () => {

    //UseDispatch (activate the actions of redux)
    const dispatch = useDispatch();

    //UseSelector of Roles 
    const templateRedux = useSelector((state:any)=> state.template.myHealth);
    //user token in redux state
    const userToken: any = useSelector((state: any) => state.login.logData.token);

    //State to save the sections
    const [sections, setSections] = useState<any[]>([]);

    const fetchTemplate = (templateRedux:any) =>{
        setSections(templateRedux)    
    };

    //UseEffect to fill the state
    useEffect(()=>{
        dispatch(getMyHealth(userToken))
    },[])
    useEffect(()=>{
        setTimeout(()=>{
            setSections(templateRedux)   
        },300);
    },[templateRedux])



    //Function to create a new section - In the data key we save the diferent 
    const newSection = () => {
        setSections(prevState=>[{ page_name:"", page:"", json:{data:[]} }, ...prevState  ])
    }

    //State to allow the inputs in the Section
    const [inputsInSection, setInputsInSection] = useState<any[]>([]);


    

    return (
        <div className="myHealth-father-container">
            <div className="health-title-container">
                <p>Mi Salud</p>
            </div>
            <div className="health-new-section-container">
                <button onClick={()=>newSection()}>+ Nueva Sección</button>
            </div>
            <div className="sections-container">
                {sections.length=== 0 ? 
                <div className='loader-container'><TailSpin color='#6767AD'></TailSpin></div>
                : 
                sections.map((s:any, index:any)=>(
                     <Section sectionPosition={index} setSections={setSections} sections={sections} inputsInSection={inputsInSection} setInputsInSection={setInputsInSection} key={index} id={index}></Section>
                ))}
                {/* {templateState.map((s:any, index:any)=>(
                    <Section sectionPosition={index} setSections={setSections} sections={sections} inputsInSection={inputsInSection} setInputsInSection={setInputsInSection} key={s.id} id={s.id}></Section>
                ))} */}
            </div>
            <EspecialityToasts/>
        </div>
    )
}

export default MyHealth;