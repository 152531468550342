
//Styles
import '../../../Styles/Users.css';
import '../../../Styles/ApproveDoctor.css';
import '../../../Styles/Doctors.css';
//Hooks
import { useState, useEffect, useMemo } from 'react';
import {useTransition, animated} from 'react-spring';
//Components
import DoctorModalDocuments from './DoctorModalDocuments';
import Pagination from '../Pagination';
import "../FontawsomeIcons/Index";
import { EspecialityToasts } from '../Toastifys/EspecialityToasts';
import { TailSpin } from 'react-loader-spinner';
//SVG and Images
import lupa from '../../../svg/lupa.svg';
import ATRAS from '../../../svg/ATRAS.svg';
import filterButton from '../../../svg/filterButton.svg';
import resetPass from '../../../svg/resetPass.svg';
//Functions
import {handleOpenModalEspecialitXdoctor } from '../../services/useFunctions';
import { useSelector } from "react-redux";
import { AnyObject } from 'immer/dist/internal';
import {getEspecialityXdoctor, getDoctorPendingApproveDocuments} from '../../../actions/actions';
import { useDispatch } from 'react-redux';
import DoctorModalConfirmation1 from './DoctorModalConfirmation1';
import DoctorModalConfirmation2 from './DoctorModalConfirmation2';
import FilterPopperEstadoDoctor from '../FiltersPopper/FilterPopperEstadoDoctor';
import UsersModalResetPass from '../Users/UsersModalResetPass';

 
const Doctors = () => {

    //UseDispatch (activate the actions of redux)
    const dispatch = useDispatch();

    //Fetch the users data CUANDO ESTE EL ENDPOINT CAMBIAR ESTE SETUSERSTATE
    const [userState, setUserState]:any[] = useState();
    const fetchUsers = () =>{
        setUserState(especialityXdoctorRedux)    
    };

    //UseSelector of Users state in redux (Brings the state of redux to the component)
    const especialityXdoctorRedux:any = useSelector((state:any) => state.users.especialityXdoctor); 

    //user token in redux state
    const userToken: any = useSelector((state: any) => state.login.logData.token);

    //UseSelector of doctor by id in redux 
    const doctorByIdRedux:any = useSelector((state:any)=> state.users.doctorById);

    //Fetch Data
    useEffect(()=>{
        dispatch(getEspecialityXdoctor(userToken))
        fetchUsers(); 
    }, []);

    
    //MODAL STATES
    //Open close modal (and hidde the HUD)
    const [openModalEspecialityXdoctor, setOpenModalEspecialityXdoctor] = useState(false);
    const [openModalConfirmation1, setOpenModalConfirmation1] = useState(false);
    const [openModalConfirmation2, setOpenModalConfirmation2] = useState(false);
    const [doctorxespecialitySelected, setDoctorxespecialitySelected] = useState({name:"", cuil:"", dni:"", email:"", phone:"", status:"", doctor_enrollment_id:"",first_surname:"", second_surname:"", id:"", profile_picture:""});

    //STATES
    const [totalItems, setTotalItems] =useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE =  9;
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({field:"", order:""});
    const [sortingOrder, setSortingOrder] = useState("asc");
    const [sortingField, setSortingField] =useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popperFiltersSelected, setPopperFiltersSelected] = useState([]);
    const [doctorSelectedArchives, setDoctorSelectedArchives] = useState<AnyObject>({});
    const [roleFilterViewer, setRoleFilterViewer]= useState("Rol");
    const [openModalResetPass, setOpenModalResetPass] = useState(false);
    const [userSelected, setUserSelected] = useState({nombre:"", apellido:"", id:"", phone:"", email:""});

    
    //useMemo of users for the table
    const usersMemo = useMemo(()=>{
        let data= especialityXdoctorRedux;
        let mappedData:any= data.map((u:any)=>{
            if(u.validated ==="1")u.validated= "Activado";
            if(u.validated ==="0")u.validated="Desactivado";
            if(u.specialities.length > 1)u.specialitiesString = `${u.specialities[0].name}, ...`;
            if (u.specialities.length === 1)u.specialitiesString = u.specialities[0].name;   
            if (u.specialities.length === 0)u.specialitiesString = "No tiene especialidades";
            return u
        });
        let computedUsers = mappedData;
        if(search){
            computedUsers = computedUsers.filter(
                (user:any) => 
                    String(user.name).toLowerCase().includes(search.toLowerCase()) ||
                    String(user.cuil).toLowerCase().includes(search.toLowerCase()) 
            )
        }
        if(popperFiltersSelected){
            popperFiltersSelected.forEach((e:any)=>{ 
                return computedUsers = computedUsers.filter(
                (user:any) =>
                    user[e.firstFilter]?.toLowerCase() === e.secondFilter.toLowerCase() 
                )  
            })
        }
        setTotalItems(computedUsers.length);
        //sorting Users
        if (sorting.field){
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedUsers = computedUsers.sort((a:any, b:any)=> reversed * a[sorting.field]?.localeCompare(b[sorting.field]))
        };
        //CURRENT PAGES SLICE
        return computedUsers.slice( (currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE );
    },[userState, currentPage, search, sorting,  especialityXdoctorRedux, popperFiltersSelected]);

    //POPPER
    const [roleFilterViewerEstado, setRoleFilterViewerEstado]= useState("Estado");
    const [anchorElEstado, setAnchorElEstado] = useState<null | HTMLElement>(null);
    const handleClickPopperEstado = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElEstado(anchorElEstado ? null : event.currentTarget);
    };
    const openEstado = Boolean(anchorElEstado);
    const idEstado = openEstado ? 'simple-popper' : undefined;

    const removeFilter = (filter:any) => {
        setPopperFiltersSelected(prevState => prevState.filter((i:any)=>(i.secondFilter !== filter)))
    }

    //SORT CHANGING STATES (recives asc, desc or wich collumn sort)
    const onSortingChange = (field:any) =>{
        const order = field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
        setSortingField(field);
        setSortingOrder(order);
        setSorting({field: field, order:order})
    }

    //SEARCH controller
    const onChangeSearch = (e:any) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };
    

    //Function Helper to fetch the documents to Approve
    const fetchDocumentsHelper = async (id:any) => {
        await dispatch(getDoctorPendingApproveDocuments(userToken, id));  
    };


    //Handler click of each row in the table
    const [userClicked, setUserClicked]= useState({name:""})
    const clickRowTable = (name:any) => {
        setUserClicked({...userClicked, name: name});
        setOpenModalEspecialityXdoctor(true)
    };


    //Use Transitions allow the react_spring library
    const transition = useTransition(especialityXdoctorRedux.length, {
        from: {opacity: 0 },
        enter: {opacity:1},
        leave: {},
        config: {duration: 100},
    });

    //Reset password
    const resetPassword = (userCode:string, name:string) => {
        setUserSelected({nombre:name, apellido:"", id:userCode, phone:"", email:""});
        setOpenModalResetPass(true)
    }

    return(
        <div>
            {transition(({opacity}:any, item,)=>
            item === 0 ? <div className='loader-container'><TailSpin color='#6767AD'></TailSpin></div> :
            <animated.div style={{ opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })}}>
                <div id="usersContainerFather" className="users-container-father">
                <div className='title-container'>
                    <h1 className='title-panel'>Médicos</h1>
                </div>
                <div className="container-filters-and-searchbar">
                    <div>
                    <div className={anchorElEstado ? "active-filter-tag-clicked" :"active-filter-tag" }onClick={handleClickPopperEstado}><p className="active-filter-tag-p">{roleFilterViewerEstado} </p> <button className={anchorElEstado ? "active-filter-tag-button-clicked":"active-filter-tag-button"}><img alt='deleteIcon' src={filterButton}/></button></div>
                        <FilterPopperEstadoDoctor idPopperEstado={idEstado} openEstado={openEstado} anchorElEstado={anchorElEstado} setPopperFiltersSelected={setPopperFiltersSelected} popperFiltersSelected={popperFiltersSelected} setRoleFilterViewerEstado={setRoleFilterViewerEstado} roleFilterViewerEstado={roleFilterViewerEstado} handleClickPopper={handleClickPopperEstado}/>
                    </div>
                    <div>
                        <img className="input-search-icon" alt='searchIcon' src={lupa}></img>
                        <input placeholder='Buscar' onChange={onChangeSearch} value={search} className="search-input"></input>
                    </div>
                </div>
                <div className="table-and-pagination-container">
                    <div className='table-container'>
                        <table id='data-table'>
                            <thead>
                                <tr className="tr-table-tittles">
                                    
                                    <th className="collumnStartAlign" id="first-row-table" onClick={()=>onSortingChange("name")}><span className="nowrap">Nombre   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "name" ? "arrow-down" : sortingOrder === "desc" && sortingField === "name" ? "arrow-up" : "visibilityHidden"} />
                                    )}</span></th>
                                    <th className="collumnStartAlign" onClick={()=>onSortingChange("cuil")}><span className="nowrap">Cuil   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "cuil" ? "arrow-down" : sortingOrder === "desc" && sortingField === "cuil" ? "arrow-up" : "visibilityHidden"} />
                                    )}</span></th>
                                    <th className="collumnCenterAlign" onClick={()=>onSortingChange("specialitiesString")}><span className="nowrap">Especialidades   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "specialitiesString" ? "arrow-down" : sortingOrder === "desc" && sortingField === "specialitiesString" ? "arrow-up" : "visibilityHidden"} />
                                    )}</span></th>
                                    <th className="collumnCenterAlign"  onClick={()=>onSortingChange("validated")}><span className="nowrap">Estado   {(<img alt='icon' src={ATRAS} className={sortingOrder === "asc" && sortingField === "validated" ? "arrow-down" : sortingOrder === "desc" && sortingField === "validated" ? "arrow-up" : "visibilityHidden"} />
                                    )}</span></th>
                                    <th className="collumnCenterAlign" id="last-row-table">Acciones</th>                    
                                </tr>
                            </thead>
                            <tbody>
                                {usersMemo.length === 0 &&
                                
                                    <tr>
                                        <td >
                                            <p className="no-results-td-table">No hay resultados</p>
                                        </td>
                                    </tr>
                                }
                                {usersMemo && usersMemo.map((user:any)=>(
                                    <tr key={user.name} id={user.name} className="tr-row-doctors" >
                                        <td className="collumnStartAlign" onClick={()=>handleOpenModalEspecialitXdoctor(user.doctor_id, userToken, setOpenModalEspecialityXdoctor )}>{user.name}</td>
                                        <td className="collumnStartAlign" onClick={()=>handleOpenModalEspecialitXdoctor(user.doctor_id, userToken, setOpenModalEspecialityXdoctor )}>{user.cuil}</td>
                                        <td className="collumnCenterAlign" onClick={()=>handleOpenModalEspecialitXdoctor(user.doctor_id, userToken, setOpenModalEspecialityXdoctor )}>{user.specialitiesString}</td>
                                        <td className="collumnCenterAlign" onClick={()=>handleOpenModalEspecialitXdoctor(user.doctor_id, userToken, setOpenModalEspecialityXdoctor )}>{user.validated === "Activado" ? <button className='btn-activo'>Activado</button> : <button className='btn-inactivo'>Desactivado</button>}</td>
                                        <td className="collumnCenterAlign" id="row-no-events"> <img alt='editIcon' onClick={()=>resetPassword(user.user_code, user.name)} className="brigthHover" src={resetPass}/> </td>
                                    </tr>
                            
                                ))}
                                
                            </tbody>
                        </table>
                    </div>
                    <div className='pagination-container'>
                        <Pagination 
                            total={totalItems}
                            itemsPerPage={ITEMS_PER_PAGE}
                            currentPage={currentPage}
                            onPageChange={(page:any) => setCurrentPage(page)}/>
                    </div>
                </div>

                {!Array.isArray(doctorByIdRedux) ?
                <DoctorModalDocuments doctorByIdRedux={doctorByIdRedux} setOpenModalConfirmation1={setOpenModalConfirmation1} setOpenModalConfirmation2={setOpenModalConfirmation2} openModalEspecialityXdoctor={openModalEspecialityXdoctor} setOpenModalEspecialityXdoctor={setOpenModalEspecialityXdoctor}/>
                :
                <></>
                }
                <UsersModalResetPass userToken={userToken} userSelected={userSelected} openModalResetPass={openModalResetPass} setOpenModalResetPass={setOpenModalResetPass}/>
                <DoctorModalConfirmation1 openModalConfirmation1={openModalConfirmation1} setOpenModalConfirmation1={setOpenModalConfirmation1} setOpenModalEspecialityXdoctor={setOpenModalEspecialityXdoctor}/>
                <DoctorModalConfirmation2 openModalConfirmation2={openModalConfirmation2} setOpenModalConfirmation2={setOpenModalConfirmation2} setOpenModalEspecialityXdoctor={setOpenModalEspecialityXdoctor}/>
                <EspecialityToasts/> 
                </div>
            </animated.div>
            )}
        </div>
        
    )
};

export default Doctors;