//Styles
import '../../../Styles/FrecuentLocationsMap.css';
//Components
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
    createTheme,
    ThemeProvider,
  } from '@mui/material/styles';
//Images and SVG
import filterButton from '../../../svg/filterButton.svg';

const OrderSelect = ({setSorting, onSortingChange}:any) => {

    
    const theme = createTheme({
        typography: {
          fontFamily: [
            'Poppins'
          ].join(','),
        },
      });

    const handleChangeSelect = (event: SelectChangeEvent) => {
        let splited = event.target.value.split(".")
        let order = splited[1]
        let field = splited[0]
        setSorting({field: field, order:order})
        onSortingChange(field)
    }



    return (
        <div className="select-and-placeholder-container">
            <ThemeProvider theme={theme}>
            <p className="select-placeholder">Ordenar por</p>
                <Select
                    displayEmpty
                    label={0}
                    onChange={handleChangeSelect}
                    className="select-component"
                    inputProps={{ 'aria-label': 'Without label' }}
                    IconComponent={(props)=>(<div className="icon-select-container" {...props}><img  alt="icon" className='icon-select-img' src={filterButton}/></div>)}
                    MenuProps={{
                        PaperProps: {sx: {borderRadius:'12px',
                                        width:'304px',
                                        left:'540px !important',
                                        color: '#595959'                                       
                                        }}
                    }}
                    sx={{width: '304px',height:'36px', pl:'112px',fontFamily:'Poppins', backgroundColor:'#FFFFFF;', zIndex: '2', color:'#6767AD',  borderColor:'#FFFFFF',borderRadius:'12px',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
                            borderColor:'#FFFFFF',
                            borderRadius:'12px'
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline':{
                            borderColor:'#FFFFFF',
                            borderRadius:'12px'
                        },
                        '&.MuiOutlinedInput-root':{
                            borderColor:'#FFFFFF',
                            borderRadius:'12px'
                        },
                        '& .MuiOutlinedInput-notchedOutline':{
                            borderColor:'#FFFFFF',
                            borderRadius:'12px'
                        }          
                        }}
                    >
                    <MenuItem value={'name.asc'}>A-Z</MenuItem>
                    <MenuItem value={'name.desc'}>Z-A</MenuItem>
                    <MenuItem value={'created_at.asc'}>Más recientes </MenuItem>
                    <MenuItem value={'created_at.desc'}>Más antiguas </MenuItem>
                </Select>
                </ThemeProvider>
        </div>
    )
}

export default OrderSelect;