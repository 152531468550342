import Modal from '@mui/material/Modal';
import { handleCloseModalEspecialityXdoctor } from '../../services/useFunctions';

const DoctorModalConfirmation2 = ({openModalConfirmation2, setOpenModalConfirmation2, setOpenModalEspecialityXdoctor}:any) => {
    return(
        <Modal open={openModalConfirmation2}
                onClose={()=>setOpenModalConfirmation2(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                    <div className="father-Modal-Confirmation1">
                        <div className="confirmation1-title-container">
                            <p className="confirmation1-title">¿Desea guardar los cambios?</p>
                        </div>
                        <div className="confirmation1-buttons-container">
                            <button className="confirmation1-button-cancelar" onClick={()=>setOpenModalConfirmation2(false)}>Cancelar</button>
                            <button className="confirmation1-button-aceptar" onClick={()=>{setOpenModalConfirmation2(false); handleCloseModalEspecialityXdoctor(setOpenModalEspecialityXdoctor)}}>Aceptar</button>
                        </div>
                    </div> 
        </Modal>
    )
}

export default DoctorModalConfirmation2;