import { useState, useEffect } from 'react';
//Styles
import '../../../Styles/ClinicHistory.css';
//Components
import ClinicSection from './ClinicSection';


const ClinicHistory = () =>{

    //State to save the sections
    const [sections, setSections] = useState<any[]>([]);

    //Create a new section button
    const createNewSection = () => {
        setSections(prevState=>[...prevState, { page_name:"", page:"", json:{data:[{name:"", type:"Text", value:"", enabled: false}]} } ])
    };
    // useEffect(()=>{console.log(sections)},[sections])



    return(
        <div className="clinicHistory-father-container">
            <div className="clinicHistory-title-container">
                <p>Historia Clínica</p>
            </div>
            <div className="clinicHistory-new-section-container">
                <button onClick={()=>createNewSection()}>+ Nueva Sección</button>
            </div>
            <div className="clinicHistory-sections-container">
                {sections.map((s:any, index:any)=>{
                    if(s.page_name !== "deleted"){
                        return <ClinicSection sectionPosition={index} setSections={setSections} sections={sections}  key={index} id={index}/>
                    }
                })}
                
            </div>

        </div>
    )
};

export default ClinicHistory;