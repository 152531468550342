import checkNew from '../../../svg/checkNew.svg';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { especialidadActivada } from '../Toastifys/EspecialityToasts';
import { getSpecialitys } from '../../../actions/actions';

const SpecialitysModalActive = ({openModalActive,setOpenModalActive,userSelected, userToken}:any) => {

    //Use dispatch hook
    const dispatch = useDispatch();
    //Button Modal Activate Accept
    const acceptActivateEspeciality = (id:any ) => {
    const activateEspeciality = async(id:string, token:string)=> {
        try{
            let postToSend = {id:id, validated:"1"};
            const {data}:{data:any} = await axios.post(`${process.env.REACT_APP_ORGANIZATION_AVE_CHANGE_VALIDATED_SPECIALITY}`, postToSend, {headers:{"Authorization":`Bearer ${token}`}});
            if(data.msg==="Se valido la especialidad "){
                dispatch(getSpecialitys(token));
                especialidadActivada();
                setOpenModalActive(false)
            }else{
            }
        }catch(error){
            console.log(error)
        }
    }
    activateEspeciality(id, userToken);
    setOpenModalActive(false);
}

    return(
            <Modal open={openModalActive}
                            onClose={()=>setOpenModalActive(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description">
                                <div className="father-Modal-especiality">
                                    <div id="modal-modal-title">
                                        <img src={checkNew} alt="activate Icon" className="check-modal-icon"/>
                                        <p>¿Desea activar la especialidad?</p>
                                    </div>
                                    <div id="modal-modal-description">
                                        <div className='modal-description-text'>
                                            <p>Si acepta, la especialidad <span className='text-highlight'>{userSelected.nombre}</span>  quedara activada. ¿Esta seguro que desea continuar?</p>                
                                        </div>
                                        <div className="modal-container-btns">
                                            <button className='modal-btn-cancelar' onClick={()=>setOpenModalActive(false)}>Cancelar</button>
                                            <button className="modal-btn-aceptar" onClick={()=> acceptActivateEspeciality (userSelected.id )}>Aceptar</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
    )
}

export default SpecialitysModalActive;