import Select, { SelectChangeEvent }  from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
//Components
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
//Images and SVG
import filterButton from '../../../svg/filterButton.svg';
//Styles
import '../../../Styles/FiltersPopper.css';
//Hooks
import {  useEffect, useState } from 'react';
import { AnyObject } from 'immer/dist/internal';

export const FiltersPopperRol = ({rolesRedux, idPopper, open, anchorEl, setPopperFiltersSelected, popperFiltersSelected, setRoleFilterViewer, roleFilterViewer, handleClickPopper}:any) => {

    //States
    const [roleSelected, setRoleSelected] = useState<AnyObject>({admin:false, super:false})

      // Close the dropdown menu if the user clicks outside of it
      window.onclick = function(event:any) {
        if (!event.target.matches('.dropbtn')) {
          var dropdowns = document.getElementsByClassName("dropdown-content");
          var i;
          for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
              openDropdown.classList.remove('show');
            }
          }
          
        }
      }

    //Delete all
    const deleteAll = () => {
        let inputContainer= document.getElementById('inputsContainer');
        let inputs = inputContainer?.querySelectorAll('input');
        inputs?.forEach((input:any)=>{
            input.checked = false
        })
        setRoleSelected({admin:false, super:false})
        setRoleFilterViewer("Rol")
        setPopperFiltersSelected([]);
    }
    //CheckBox handler for "ROL"
    const checkHandler = (e:any) => {
        let checked = e.target.checked;
        let name = e.target.name.split(".");
        let state = popperFiltersSelected;
        let id = e.target.id;
        let viewer = roleFilterViewer;

        if(checked===true){
            if(id==="admin"){
                setRoleSelected({...roleSelected, admin:true}) 
                if(viewer.includes("Rol")){viewer=""}
                if(!viewer.includes("Admin") && viewer.length > 0){
                    viewer= viewer  + "/" + "Admin"
                }else{
                    viewer= "Admin"
                }
            }
            if(id==="super"){
                setRoleSelected({...roleSelected, super:true})
                if(viewer.includes("Rol")){viewer=""}
                if(!viewer.includes("Super") && viewer.length > 0){
                    viewer= viewer +  "/" + "Super"
                }else{
                    viewer= "Super"
                }
            }
            setPopperFiltersSelected([...popperFiltersSelected, {secondFilter: name[1], firstFilter:name[0]}])
            setRoleFilterViewer(viewer)

        }else if (checked ===false){
            if(id==="admin"){
                setRoleSelected({...roleSelected, admin:false})
                if(!viewer.includes("/")){
                    viewer="Rol"
                }else if (viewer.includes("/")){
                    viewer= viewer.replace("/","")
                    viewer= viewer.replace("Admin","")
                }
            }
            if(id==="super"){
                setRoleSelected({...roleSelected, super:false})
                if(!viewer.includes("/")){
                    viewer="Rol"
                }else if (viewer.includes("/")){
                    viewer= viewer.replace("/","")
                    viewer= viewer.replace("Super","")
                }         
            }
            let filtered = state.filter((f:any)=> f.secondFilter !== name[1])
            setPopperFiltersSelected(filtered)
            setRoleFilterViewer(viewer)
        }
    }

    const handleChangeSelectAdd = (event: SelectChangeEvent) => {
        setPopperFiltersSelected((prevState:any)=> ([prevState.filter((f:any)=>f.firstFilter !=="role")]) )
        
        setPopperFiltersSelected([ {secondFilter:event.target.value, firstFilter:"role"}]);
        
        setRoleFilterViewer(event.target.value)
    }




    return (
        <Popper id={idPopper} open={open} anchorEl={anchorEl} placement={'bottom-start'}>
            <Box sx={{width: 334, height: 192, p: 2, bgcolor: 'background.paper', borderRadius:6}} className="popperBox" id='inputsContainer'>
                <div className="filters-header">
                    <h3 className="filter-header-title">Rol</h3>
                    <h6 className="filter-aplicar-text" onClick={(e)=>handleClickPopper(e)}>Aplicar</h6>
                </div>
                <div className="section-filter-1">
                    <div className="section-inputs-cotainer">
                        <Select
                            className="select-role-modal"
                            sx={{width: "100%", mb:"30px", fontWeight:'poppins',pl:'5px', background:'#EFF0F6', borderRadius:'12px', fontFamily:'Poppins',
                                '&.MuiSelect-icon MuiSelect-iconStandard css-19xtc65-MuiSelect-icon': {top:'5px' , right:'10px'}}}
                            disableUnderline
                            IconComponent={(props)=>(<img className='iconSelect' alt="icon" src={filterButton}{...props}/>)}
                            variant='standard'
                            label='Rol'
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            onChange={handleChangeSelectAdd}
                            >
                                {rolesRedux.length > 0 && rolesRedux.map((r:any, index:number)=>(
                                    <MenuItem  sx={{fontFamily:'Poppins'}} key={index} value={r.name}>{r.name}</MenuItem>
                                ))}
                               
                        </Select>              
                    </div>
                    <div className="deleteAll-container">
                        <h6 className="filter-deleteAll-text" onClick={()=>deleteAll()}>Borrar todo</h6>
                    </div>
                </div>
            </Box>
        </Popper>
    )
}