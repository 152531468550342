import Modal from '@mui/material/Modal';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addSuccesToast, errorCreando1Especialidad } from '../Toastifys/EspecialityToasts';
import { getSpecialitys } from '../../../actions/actions';

const SpecialitysModalAdd = ({openModalAdd, setOpenModalAdd, setAddSpecialityPost, addSpecialityPost, userToken}:any) => {

    //Use dispatch hook
    const dispatch = useDispatch();
     //Button of Modal Add ACCEPT
     const handleClickBtnModalAceptarAdd = (e:any, post:any ) =>{
        e.preventDefault()
        if(post.nombre.length < 1 || post.descripcion.length < 1 || post.code < 4){
            return alert("faltan completar datos")
        }else {
            const CreateNewSpeciality = async (addSpecialityPost:any) => {
                try{
                    let postToSend = {name:addSpecialityPost.nombre, description: addSpecialityPost.descripcion, code:addSpecialityPost.code}
                    const {data}:{data:any} = await axios.post(`${process.env.REACT_APP_ORGANIZATION_AVE_NEW_SPECIALITY}`, postToSend, {headers:{"Authorization":`Bearer ${userToken}`}});
                    if(data.msg === "Se agrego correctamente la especialidad"){ 
                        setOpenModalAdd(false)
                        dispatch(getSpecialitys(userToken))
                        addSuccesToast()
                        
                    }else{
                        errorCreando1Especialidad()
                    }
                }catch(error){
                    console.log("error sending the new spciality", error)
                }
            };
            CreateNewSpeciality(addSpecialityPost);
        }
};


    return(
        <Modal open={openModalAdd}
            onClose={()=>setOpenModalAdd(false)}
            >
                <div className="father-Modal-Add">
                    <div id="modal-modal-title-add">
                        <p>Agregar nueva especialidad</p>
                    </div>
                    <div id="modal-modal-description-add">
                        <div className='modal-description-text-add'>
                            <p>Descripcion de la especialidad</p>                
                        </div>
                        <div className="">
                            <form className='form-add-container'>
                                <label className="modal-input-text-label">Nombre de la especialidad</label>
                                <input className="modal-add-input-name" onChange={(e:any)=>setAddSpecialityPost({...addSpecialityPost, nombre: e.target.value})}></input>
                                <label className="modal-input-text-label">Descripcion</label>
                                <textarea className="modal-textarea" onChange={(e:any)=>setAddSpecialityPost({...addSpecialityPost, descripcion: e.target.value})}></textarea>
                                <label className="modal-input-text-label">Codigo (5 caracteres)</label>
                                <input className="modal-add-input-code" maxLength={5} minLength={5} onChange={(e:any)=>setAddSpecialityPost({...addSpecialityPost, code: e.target.value.toUpperCase()})}></input>

                                <div className="modal-container-btns-add">
                                    <button className='modal-btn-cancelar' onClick={()=>setOpenModalAdd(false)}>Cancelar</button>
                                    <button className="modal-btn-aceptar" onClick={(e)=>handleClickBtnModalAceptarAdd(e, addSpecialityPost)}>Aceptar</button>
                                </div>
                            </form>
                        </div> 
                    </div>
                </div>
        </Modal>
    )
};

export default SpecialitysModalAdd;