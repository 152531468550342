import dontNew from '../../../svg/dontNew.svg';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { especialidadDesactivada } from '../Toastifys/EspecialityToasts';
import { getSpecialitys } from '../../../actions/actions';


const SpecialitysModalDesactive = ({openModalDesactive, setOpenModalDesactive, userSelected, userToken}:any) =>{

    //Use dispatch hook
    const dispatch = useDispatch();
    //Button of Modal Desactivate User Accept
    const handleClickBtnModalAceptarDesactive = ( id:string) =>{
        const desactivateEspeciality = async(id:string, token:string) => {
            try{
                let postToSend= {id:id, validated:"2"};
                const {data}:{data:any} = await axios.post(`${process.env.REACT_APP_ORGANIZATION_AVE_CHANGE_VALIDATED_SPECIALITY}`, postToSend, {headers:{"Authorization":`Bearer ${token}`}} );
                if(data.msg === "Se valido la especialidad "){
                    dispatch(getSpecialitys(token));
                    especialidadDesactivada()
                }else{

                }
            }catch(error){
            }
    }
    desactivateEspeciality(id, userToken)
    setOpenModalDesactive(false);
};



    return(
        <Modal open={openModalDesactive}
            onClose={()=>setOpenModalDesactive(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-Modal-especiality">
                    <div id="modal-modal-title">
                        <img src={dontNew} alt="desactivate icon" className="dont-modal-icon"/>
                    <p>¿Desea desactivar esta especialidad?</p>
                     </div>
                    <div id="modal-modal-description">
                        <div className='modal-description-text'>
                            <p>Si acepta, la especialidad <span className='text-highlight'>{userSelected.nombre}</span> quedara desactivada hasta que vuelvas a activarla. ¿Esta seguro que desea continuar?</p>                
                        </div>
                        <div className="modal-container-btns">
                            <button className='modal-btn-cancelar' onClick={()=> setOpenModalDesactive(false)}>Cancelar</button>
                            <button className="modal-btn-aceptar" onClick={()=>handleClickBtnModalAceptarDesactive( userSelected.id)}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </Modal>
    )
};

export default SpecialitysModalDesactive;