import { IActions } from "../interfaces/interfaces";

const actions : IActions = {
    LOGIN: 'LOGIN',
    LOG_OUT: 'LOG_OUT',
    GET_USERS: 'GET_USERS',
    GET_SPECIALITYS: 'GET_SPECIALITYS',
    GET_FREQUENT_LOCATIONS: 'GET_FREQUENT_LOCATIONS',
    GET_DOCTORS_TO_APPROVE: 'GET_DOCTORS_TO_APPROVE',
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    GET_ESPECIALITYXDOCTOR: 'GET_ESPECIALITYXDOCTOR',
    GET_DOCTOR_PENDING_APPROVE_DOCUMENTS: 'GET_DOCTOR_PENDING_APPROVE_DOCUMENTS',
    GET_ROLES_FUNCTIONS: 'GET_ROLES_FUNCTIONS',
    GET_ROLES:'GET_ROLES',
    GET_ROLE_BY_ID:'GET_ROLE_BY_ID',
    GET_BENEFICIARY_LIST:'GET_BENEFICIARY_LIST',
    GET_ENSURANCE_LIST:'GET_ENSURANCE_LIST',
    GET_BENEFICIARY_ID:'GET_BENEFICIARY_ID',
    CLEAN_BENEFICIARY_ID:'CLEAN_BENEFICIARY_ID',
    CLEAN_PENDING_APPROVE_DOCUMENTS:'CLEAN_PENDING_APPROVE_DOCUMENTS',
    GET_DOCTOR_BY_ID:'GET_DOCTOR_BY_ID',
    CLEAN_DOCTOR_BY_ID:'CLEAN_DOCTOR_BY_ID',
    GET_MY_HEALTH_TEMPLATE:'GET_MY_HEALTH_TEMPLATE'
    
}

export default actions; 
