import actions from "../actions_types/actions_types";


const initialState = {
  notification: []
};

export const notificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.GET_NOTIFICATIONS:
        return {
        ...state,
        notification: action.payload,
    };
    default:
    return state;
  }
};