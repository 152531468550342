import Select, { SelectChangeEvent }  from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
//Images and SVG
import filterButton from '../../../svg/filterButton.svg';


const UsersModalAdd = ({rolesRedux, setRoleOrUser, openModalAdd, roleOrUser, setOpenModalAdd, setOpenModalAdd2}:any)=>{

    //Handler of Select input in Modal ADD
    const handleChangeSelectAdd = (event: SelectChangeEvent) => {
        setRoleOrUser(event.target.value);    
    };

    //Handler Accept button in this modal (open another modal)
    const acceptButton = () =>{
        setOpenModalAdd2(true);
    }
    return(
        <Modal open={openModalAdd}
            onClose={()=>setOpenModalAdd(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-Modal">
                    <div id="modal-modal-title">
                        <p>Nuevo Usuario</p>
                    </div>
                    <div id="modal-modal-description">
                        <div className='modal-description-text-addmodal'>
                            <p> Elegir Rol</p>                   
                        </div>
                        <div>
                            <Select
                                className="select-role-modal"
                                sx={{width: "100%", mb:"30px", fontWeight:'poppins',pl:'5px', background:'#EFF0F6', borderRadius:'12px', fontFamily:'Poppins',
                                    '&.MuiSelect-icon MuiSelect-iconStandard css-19xtc65-MuiSelect-icon': {top:'5px' , right:'10px'}}}
                                disableUnderline
                                IconComponent={(props)=>(<img className='iconSelect' alt="icon" src={filterButton}{...props}/>)}
                                variant='standard'
                                label='Rol'
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                onChange={handleChangeSelectAdd}
                                >
                                    {rolesRedux.length > 0 && rolesRedux.map((r:any,index:string)=>(
                                        <MenuItem  sx={{fontFamily:'Poppins'}} key={index} value={r.name}>{r.name}</MenuItem>
                                    ))}
                                
                            </Select>
                        </div>
                        <div className="modal-container-btns">
                            <button className='modal-btn-cancelar' onClick={()=>setOpenModalAdd(false)}>Cancelar</button>
                            <button className={roleOrUser=== ""?"modal-btn-aceptar-desabled" : "modal-btn-aceptar"} disabled={roleOrUser=== ""?true:false} onClick={()=>acceptButton()}>Aceptar</button>
                        </div>
                    </div>
                </div>
        </Modal>
    )
}

export default UsersModalAdd;