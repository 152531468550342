//Styles
import '../../../Styles/Users.css';
import '../../../Styles/Beneficiaries.css';
//SVG and images
import Verificado from '../../../svg/Verificado.svg';
import Noverificado from '../../../svg/Noverificado.svg';
//typeof beneficiaryByIdRedux === 'object' && !Array.isArray(beneficiaryByIdRedux)

export const DisplayInformationBody = ({beneficiaryByIdRedux}:any) => {
    return(
        <div  style={{height:'70%'}}>
            {typeof beneficiaryByIdRedux === 'object' &&  !Array.isArray(beneficiaryByIdRedux) ?
                <div className="display-information-texts-container">
                    <div className="information-line-container">
                        <p className="display-information-title">Datos personales</p>
                    </div>
                    <div className="information-line-container">
                        <p className="display-information-text">Nombre y apellido:  {beneficiaryByIdRedux.name}</p>
                    </div>
                    <div className="information-line-container">
                        <p className="display-information-text">Fecha de nacimiento:  {beneficiaryByIdRedux.birthdate}</p>
                    </div>
                    <div className="information-line-container">
                        <p className="display-information-text">Género:  {beneficiaryByIdRedux.genero === "M" ? "Masculino" : "Femenino"}</p>
                    </div>
                    <div className="information-line-container">
                        <p className="display-information-text">Dirección:  {beneficiaryByIdRedux.address}</p>
                    </div>
                    <div className="information-line-container">
                        <p className="display-information-text">Cuil:  {beneficiaryByIdRedux.cuil}</p>
                    </div>
                    <div className="information-line-container">
                        <p className="display-information-text">Teléfono: {beneficiaryByIdRedux.phone_number}</p>
                    </div>
                    <div className="information-line-container">
                        <p className="display-information-text">Email:  {beneficiaryByIdRedux.email}</p>
                    </div>
                    <div className="information-line-container">
                        <p className="display-information-text">Ultima sesión:  {beneficiaryByIdRedux.last_login ? beneficiaryByIdRedux.last_login.slice(0, 10) : "No registrado"}</p>
                    </div>
                    <div className="information-line-container">
                        <p className="display-information-title">Cobertura</p>
                    </div>
                    <div className='information-ensurances-container'>
                         {beneficiaryByIdRedux.id !== null && Array.isArray(beneficiaryByIdRedux.beneficiary_insurance_company) && beneficiaryByIdRedux.beneficiary_insurance_company.map((e:any)=>(
                            <div className="information-line-container2">
                                <div className="subcontainerFlex">
                                    <img alt='verifyIcon' className='verifyIcon' src={e.status==="1" ? Verificado : Noverificado}/>
                                    <p className="display-information-text">{e.insurance_company.name}</p>
                                </div>
                                <div>
                                    <p className="display-information-text">{e.plan.name}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                   
                    
                </div>
                :
                        
                <div className="display-information-texts-container-loader">
                    <p className="display-information-text-loader">Ningún usuario seleccionado</p>
                </div>
            }
        </div>
    )
}