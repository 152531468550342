import trashNew from '../../../svg/trashNew.svg';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { deleteSuccesToast, errorCreando1Especialidad } from '../Toastifys/EspecialityToasts';
import { getSpecialitys } from '../../../actions/actions';

const SpecialitysModalDelete = ({openModalDelete, setOpenModalDelete, userSelected, userToken}:any) => {

    //Use dispatch hook
    const dispatch = useDispatch();
    //Button of Modal Delete ACCEPT
    const handleClickBtnModalAceptarDelete = (id:string, token:string) =>{
        const DeleteEspeciality = async (id:string) => {
        try{     
            const {data}:{data:any} = await axios.delete(`${process.env.REACT_APP_ORGANIZATION_AVE_DELETE_ESPECIALITY}${id}`, {headers:{"Authorization":`Bearer ${token}`}} );
            if(data.data.deleted === true){
                dispatch(getSpecialitys(token));
                deleteSuccesToast();
                setOpenModalDelete(false);
            }
        }catch(error){
            console.log(error)
        }
    }
    DeleteEspeciality(id);
    
};

    return(
        <Modal open={openModalDelete}
            onClose={()=>setOpenModalDelete(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div className="father-Modal-especiality">
                <div id="modal-modal-title">
                    <img src={trashNew} alt="delete Icon" className="trash-modal-icon"/>
                    <p >¿Desea eliminar la especialidad?</p>
                </div>
                <div id="modal-modal-description">
                    <div className='modal-description-text'>
                        <p>Si acepta, eliminará a <span className='text-highlight'>{userSelected.nombre} {userSelected.apellido}</span> de especialidades. ¿Esta seguro que desea continuar?</p>                
                    </div>
                    <div className="modal-container-btns">
                        <button className='modal-btn-cancelar' onClick={()=> setOpenModalDelete(false)}>Cancelar</button>
                        <button className="modal-btn-aceptar" onClick={()=>handleClickBtnModalAceptarDelete(userSelected.id, userToken)}>Aceptar</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default SpecialitysModalDelete;