import * as React from "react";
//Components
import Layout from '../components/layout/Layout';
import Login from '../components/Login/Login';
import Users from '../components/Users/Users';
import Beneficiaries from '../components/beneficiaries/Beneficiaries';
import ProtectedRoute from './ProtectedRoute';
import Specialitys from '../components/Specialitys/Specialitys';
import FrequentLocations from "../components/Frequent Locations/FrequentLocations";
import Doctors from "../components/Doctors/Doctors";
import Notifications from "../components/Notifications/Notifications";
import MyHealth from "../components/MyHealth/MyHealth";
import ClinicHistory from "../components/ClinicHistory/ClinicHistory";
//Hooks
import { useSelector } from "react-redux";
import { Routes, Route, Navigate} from 'react-router-dom';



const AppRoutes = () => {


  const userLogged: any = useSelector((state: any) => state.login)
  const isAuthenticated = !userLogged.logData ? false : true;
  

  return (

    <Routes>
      <Route path="/" element={<Login/>} />

      <Route path="/home" element={<ProtectedRoute isAunthenticated={isAuthenticated} component={<Layout/>}/>}>
        <Route path="/home/doctors" element={<ProtectedRoute isAunthenticated={isAuthenticated} component={<Doctors/>}/>} />
        <Route path="/home/beneficiaries" element={<ProtectedRoute isAunthenticated={isAuthenticated} component={<Beneficiaries/>}/>}/>
        <Route path="/home/usersAdmin" element={<ProtectedRoute isAunthenticated={isAuthenticated} component={<Users/>}/>}/>
        <Route path="/home/specialitys" element={<ProtectedRoute isAunthenticated={isAuthenticated} component={<Specialitys/>}/>}/>
        <Route path="/home/frequentLocation" element={<ProtectedRoute isAunthenticated={isAuthenticated} component={<FrequentLocations/>}/>}/>
        <Route path="/home/myhealth" element={<ProtectedRoute isAunthenticated={isAuthenticated} component={<MyHealth/>}/>} />
        <Route path="/home/clinicHistory" element={<ProtectedRoute isAunthenticated={isAuthenticated} component={<ClinicHistory/>}/>} />
        <Route path="/home/notifications" element={<ProtectedRoute isAunthenticated={isAuthenticated} component={<Notifications/>}/>}/>
      </Route>

      <Route path="*" element={<Navigate replace to="/"/>}/>
    </Routes>

  );
};

export default AppRoutes;
