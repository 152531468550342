import { useSpring, animated } from 'react-spring';
//Svg and images
import deleteField from '../../../svg/deleteField.svg';
import calendarIcon from '../../../svg/icons/CALENDARIO.svg';


const PreguntaFecha = ({sections, setSections,sectionPosition, id}:any) => {

    //Delete this input
    const deleteInput = () => {
        let deleted = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data[id].type = "deleted"
            }    
            return section
        })
        setSections(deleted)
    }
    //Change name of the input
    const changeNameInput = (e:any) => {
        let updated = sections.map((section:any, index:any)=>{
            if(index === sectionPosition){
                section.json.data[id].name = e.target.value
            }   
            return section   
        })
        setSections(updated)
    }

    //get value f input
    const findValueQuestion = () => {
        let index = sections[sectionPosition].json.data[id].name;
        return index
    };

    //Animations config
    const styles = useSpring({
        from:{opacity: 0, x:0},
        to: {opacity: 1, x:0},
        leave: {opacity: 0, x:0},
        config: {duration: 150}
     });

    return(
        <animated.div style={{...styles}}>
            <div className="preguntaFecha-container">
                <img alt='inputicon' src={calendarIcon} className="calendar-icon"/>
                <input placeholder="Pregunta de fecha..." value={findValueQuestion()} onChange={(e)=>changeNameInput(e)}></input>
                <img alt='deleteSection' src={deleteField} onClick={()=>deleteInput()}></img>
            </div>
        </animated.div>
    )
};

export default PreguntaFecha;