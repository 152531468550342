import Modal from '@mui/material/Modal';
import axios from 'axios';
import { useDispatch } from 'react-redux';
//Functions
import { getRoles } from '../../../actions/actions';
import { rolCreado, errorDeLogin } from '../Toastifys/EspecialityToasts';
import { showHUD } from '../../services/useFunctions';

const RolesModalConfirmation2 = ({openModalConfirmation2, setOpenModalConfirmation2 , userToken,setOpenModalAddRole, functions, newRole, setGoToEdit  }:any)=>{

    //Hook dispatch
    const dispatch = useDispatch();
    //Modal Confirmation in Users Adding a new ROLE
    const confirmationModalAccept2 = ()=> {
        let postToSend = {name:newRole.name, description: newRole.description, functions: functions};
        const createNewRole = async(post:object) => {
            try{
                const {data}:{data:any}= await axios.post(`${process.env.REACT_APP_AUTH_AVE_CREATE_NEW_ROLE}`, post, {headers:{"Authorization":`Bearer ${userToken}`}});
                if(data.msj==="Se guardo correctamente "){
                    dispatch(getRoles(userToken));
                    setOpenModalConfirmation2(false);
                    setOpenModalAddRole(false);
                    showHUD();
                    rolCreado();
                }else{
                    errorDeLogin(data.msj)
                }
            }catch(error){
                console.log(error)
                errorDeLogin(error)
            }
        }
        createNewRole(postToSend);
        setGoToEdit(false)
    };  

    return(
        <Modal open={openModalConfirmation2}
            onClose={()=>setOpenModalConfirmation2(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-Modal-confirmation">
                    <div className="modal-confirmation-title" id="modal-modal-title">
                        <p>¿Desea guardar los cambios?</p>
                    </div>                
                    <div className="modal-container-btns">
                        <button className='modal-btn-cancelar' onClick={()=>setOpenModalConfirmation2(false)}>Cancelar</button>
                        <button className="modal-btn-aceptar" onClick={()=>confirmationModalAccept2()}>Aceptar</button>
                    </div> 
                </div>
        </Modal>
    )
}

export default RolesModalConfirmation2;