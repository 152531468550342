import Eliminar from '../../../svg/trashNew.svg';
import Desactivar from '../../../svg/dontNew.svg';
import Activar from '../../../svg/checkNew.svg';
import Editar from '../../../svg/pencilNew.svg';
import Agregar from '../../../svg/addNew.svg';
import Ver from '../../../svg/seeNew.svg';

const RolesCheckboxesEdit = ({roleToEditState, functions, actions, checkHandlerFather, rolesFunctions, checkHandlerFatherEdit}:any) => {
    let icons:any= [{name:"Eliminar", src:Eliminar}, {name:"Desactivar", src:Desactivar}, {name:"Activar", src:Activar}, {name:"Editar", src:Editar}, {name:"Agregar", src:Agregar}, {name:"Ver", src:Ver} ];


    const findIndexFather = (roleToEditState:any, Fcode:any)=>{
        return roleToEditState?.functions[roleToEditState.functions.findIndex((a:any)=>a.code===Fcode)].status
    }
    const findIndexSon = (roleToEditState:any, Fcode:any, Ocode:any)=>{

        return roleToEditState?.functions[roleToEditState.functions.findIndex((a:any)=>a.code===Fcode)].options[roleToEditState?.functions[roleToEditState.functions.findIndex((a:any)=>a.code===Fcode)].options.findIndex((i:any)=>i.code===Ocode)].status
    }

    

    return(
                        <div className="modalAddRole-father-container" >
                            {rolesFunctions.length > 1 && rolesFunctions.map((f:any)=>{
                                return(
                                    <div className="inputs-container-per-role" id={f.code}>
                                        <div className="modalAddRole-input-icon-label-container">
                                            <input name={f.code} className="modalAddRole-check-father"checked={findIndexFather(roleToEditState, f.code)} type='checkbox' onChange={(e)=>{ checkHandlerFatherEdit(e, "father")}}></input>
                                            <label className="modalAddRole-label-father">{f.name}</label> 
                                        </div>
                                        {f.options.map((o:any)=>{
                                            return(
                                                <div className="modalAddRole-input-icon-label-container">
                                                    <input className="modalAddRole-check" name={f.code} checked={findIndexSon(roleToEditState, f.code, o.code)} type='checkbox' id={o.code} disabled={findIndexFather(roleToEditState, f.code)=== true ? false : true} onChange={(e)=>{ checkHandlerFatherEdit(e, "son")}}></input>
                                                    {icons.map((i:any)=>{if(o.name===i.name)return (<img className="modalAddRole-icon" alt='icon' src={`${i.src}`}/>)})}
                                                    <label className="modalAddRole-label">{o.name}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
    )
}

export default RolesCheckboxesEdit;