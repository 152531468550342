import Modal from '@mui/material/Modal';


const RolesModalConfirmation = ({openModalConfirmation, setOpenModalConfirmation, setOpenModalAddRole, setNewRole, setGoToEdit}:any)=>{

    //Accept Button
    const confirmationModalAccept = ( )=> {
        setOpenModalConfirmation(false);
        setOpenModalAddRole(false);
        setGoToEdit(false)
        setNewRole({description:"", name:""})
    };

    return(
        <Modal open={openModalConfirmation}
            onClose={()=>setOpenModalConfirmation(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="father-Modal-confirmation">
                    <div className="modal-confirmation-title" id="modal-modal-title">
                        <p>¿Desea salir sin guardar?</p>
                    </div>               
                    <div className="modal-container-btns">
                        <button className='modal-btn-cancelar' onClick={()=>setOpenModalConfirmation(false)}>Cancelar</button>
                        <button className="modal-btn-aceptar" onClick={()=>confirmationModalAccept()}>Aceptar</button>
                    </div> 
                </div>
        </Modal>
    )
};

export default RolesModalConfirmation;