import Modal from '@mui/material/Modal';
import axios from 'axios';
//Hooks
import { useDispatch } from 'react-redux';
//Functions and services
import { getUsers } from '../../../actions/actions';
//Styles
import '../../../Styles/Users.css';
import { usuarioAdminCreado,usuarioAdminError, errorDeLogin } from '../Toastifys/EspecialityToasts';

const UserModalEdit = ({openModalEdit, setOpenModalEdit, userSelected, setUserSelected, userToken}:any) => {

    //Use dispatch hook
    const dispatch = useDispatch();
    //Modal Edit User CANCEL button
    const handleBtnCancelEdit = (setOpenModalEdit:any, setUserSelected:any) => {
    setOpenModalEdit(false);
    setUserSelected({nombre:"", phone:"", email:"", id:""});
    }
    //Modal Edit User ACCEPT button
    const handleBtnAcceptEdit = () =>{
        const editUser = async ()=>{
            let postToSend = {phone: userSelected.phone, email: userSelected.email};
            await axios.post(`${process.env.REACT_APP_AUTH_AVE}${userSelected.id}`,postToSend,{headers:{"Authorization":`Bearer ${userToken}`}})
            .then((response)=>{
                setOpenModalEdit(false);
                dispatch(getUsers(userToken));
                usuarioAdminCreado();
            }).catch((error)=>{
                usuarioAdminError()
                errorDeLogin(error.response.data.error.message)
            })
        } 
        editUser()
    };


    return(
        <Modal open={openModalEdit}
        onClose={()=>setOpenModalEdit(false)}
        ria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <div className="father-modal-edit-user">
                <div className="modal-edit-user-title-container">
                    <p>{userSelected.nombre}</p>
                </div>
                <div className="modal-edit-user-inputs-container">
                    <div className="modal-edit-input-container">
                        <p className="modal-edit-label">Número</p>
                        <input placeholder='Número de teléfono' value={userSelected.phone} onChange={(e)=>setUserSelected({...userSelected, phone: e.target.value})}></input>
                    </div>
                    <div className="modal-edit-input-container">
                        <p className="modal-edit-label">Email</p>
                        <input placeholder='Email' value={userSelected.email} onChange={(e)=>setUserSelected({...userSelected, email: e.target.value})}></input>
                    </div>
                </div>
                <div className="modal-edit-user-buttons-container">
                    <button className='modal-btn-cancelar' onClick={()=>handleBtnCancelEdit(setOpenModalEdit, setUserSelected)}>Cancelar</button>
                    <button className='modal-btn-aceptar' onClick={()=>handleBtnAcceptEdit()}>Guardar</button>
                </div>
            </div>

        </Modal>
    )
}

export default UserModalEdit;