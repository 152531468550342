import { useMemo} from 'react';//React hooks

export const NotificationsMemoSettings = (NotificationsRedux:any[], search:string,popperFiltersSelected:null|any,setTotalItems:any, sorting:any, currentPage:number, ITEMS_PER_PAGE:number,userState:any) => {
    return useMemo(()=>{
        let data= NotificationsRedux;
        let mappedData:any= data.map((u:any)=>{
            if(u.for_beneficiary=== true)u.for_beneficiary="Si";
            if(u.for_beneficiary=== false)u.for_beneficiary="No";
            if(u.for_doctor=== true)u.for_doctor="Si";
            if(u.for_doctor=== false)u.for_doctor="No";
            if(typeof u.variables == 'number' )u.variables= u.variables.toString();
            if(u.status === "1")u.status= "Activo";
            if(u.status === "0")u.status="Inactivo"
            
            return u
        });
        let computedUsers = mappedData;
        if(search){
            computedUsers = computedUsers.filter(
                (user:any) => 
                    user.name.toLowerCase().includes(search.toLowerCase()) ||
                    user.template_code.toLowerCase().includes(search.toLowerCase()) ||
                    user.description.toLowerCase().includes(search.toLowerCase()) ||
                    user.variables === search.toString() ||
                    user.status.toLowerCase().includes(search.toLowerCase())
            )
        }
        if(popperFiltersSelected){
            popperFiltersSelected.forEach((e:any)=>{ 
                if(e.firstFilter === "status"){
                    return computedUsers = computedUsers.filter(
                        (user:any)=>
                            user.status.includes(e.secondFilter)
                    )
                }else{
                    return computedUsers = computedUsers.filter(
                        (user:any) =>
                            user[e.firstFilter]?.toLowerCase().includes(e.secondFilter.toLowerCase()) ||
                            user.status?.includes(e.secondFilter)
                )  
                }
                
            })
        }
        setTotalItems(computedUsers.length);
        //sorting Users
        if (sorting.field){
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedUsers = computedUsers.sort((a:any, b:any)=> reversed * a[sorting.field]?.localeCompare(b[sorting.field]))
        };
        //CURRENT PAGES SLICE
        return computedUsers.slice( (currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE );
    },[userState, currentPage, search, sorting,  NotificationsRedux, popperFiltersSelected]);
}