//Styles
import '../../../Styles/Users.css';
//Hooks
import { useState, useEffect, useMemo } from 'react';
import {useTransition, animated} from 'react-spring';
//Components
import Pagination from '../Pagination';
import UserModalEdit from './UsersModalEdit';
import UserModalDelete from './UserModalDelete';
import "../FontawsomeIcons/Index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { TailSpin } from  'react-loader-spinner'
//SVG and Images
import trashNew from '../../../svg/trashNew.svg';
import dontNew from '../../../svg/dontNew.svg';
import checkNew from '../../../svg/checkNew.svg';
import lupa from '../../../svg/Search.svg';
import pencilNew from '../../../svg/pencilNew.svg'
import filterButton from '../../../svg/filterButton.svg';
import resetPass from '../../../svg/resetPass.svg';
//Functions
import { activeMorethanOne, desactiveMoreThanOne } from '../../services/UserServices';
import { templateRoles } from './rolesTemplate';
import {handleOpenModalDesactive,handleOpenModalAddUser, handleOpenModalAddRole, handleOpenModalActive} from '../../services/useFunctions';
import { useSelector, useDispatch } from "react-redux";
import { FiltersPopperRol } from '../FiltersPopper/FiltersPopperRol';
import { AnyObject } from 'immer/dist/internal';
import { getUsers, getRoles} from '../../../actions/actions';
import { FiltersPopperEstado } from '../FiltersPopper/FilterPopperEstado';
import RolesTable from './RolesTable';
import { EspecialityToasts} from '../Toastifys/EspecialityToasts';
import UserModalActive from './UsersModalActive';
import UserModalDesactivate from './UsersModalDesactivate';
import UsersModalAdd from './UsersModalAdd';
import UsersModalAdd2 from './UsersModalAdd2';
import RolesModalConfirmation from './RolesModalConfirmation';
import RolesModalConfirmation2 from './RolesModalConfirmation2';
import UsersModalResetPass from './UsersModalResetPass';
import { changeColor, TransitionSettings, UsersMemoSettings, checkAllLogic, individualCheckLogic } from './Users_Services';


const Users = () =>{

    //UseDispatch (activate the actions of redux)
    const dispatch = useDispatch();

    //Fetch the users data
    const [userState, setUserState]:any[] = useState();
    const fetchUsers = () =>{
        setUserState(usersRedux)    
    };
    
    //UseSelector of Roles Functions to render the modal of new roles
    const rolesFunctions = useSelector((state:any)=> state.users.rolesFunctions);
    //UseSelector of Users state in redux (Brings the state of redux to the component)
    const usersRedux:any[] = useSelector((state:any) => state.users.users);
    //user token in redux state
    const userToken: any = useSelector((state: any) => state.login.logData.token);
    //Only the data of 1 role called when i go to edit a role
    const roleByIdToEdit = useSelector((state:any)=> state.users.roleToEdit[0]);
    //UseSelector of Roles 
    const rolesRedux = useSelector((state:any)=> state.users.roles);
    
    //Fetch Data
    useEffect(()=>{
        dispatch(getUsers(userToken))
        fetchUsers(); 
        dispatch(getRoles(userToken))
    }, []);

    //MODAL STATES
    //Open close modal (and hidde the HUD)
    const [openModalDesactive, setOpenModalDesactive] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalActive, setOpenModalActive] = useState(false);
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [openModalAdd2, setOpenModalAdd2] = useState(false);
    const [openModalAddRole, setOpenModalAddRole] = useState(false);
    const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
    const [openModalConfirmation2, setOpenModalConfirmation2] = useState(false);
    const [openModalEdit, setOpenModalEdit]= useState(false);
    const [userSelected, setUserSelected] = useState({nombre:"", apellido:"", id:"", phone:"", email:""});
    const [goToEdit, setGoToEdit] = useState(false);
    const [openModalResetPass, setOpenModalResetPass] = useState(false);

    //filters searchbar sorting items per page STATES
    const [totalItems, setTotalItems] =useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE =  9;
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({field:"", order:""});
    const [sortingOrder, setSortingOrder] = useState("asc");
    const [sortingField, setSortingField] =useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElEstado, setAnchorElEstado] = useState<null | HTMLElement>(null);
    const [popperFiltersSelected, setPopperFiltersSelected] = useState<null | any>([]);
    const [itemsSelected, setItemsSelected] = useState<AnyObject>({usersSelected: []});
    const [typeOfTable, setTypeOfTable]= useState("users"); 
    const [roleFilterViewer, setRoleFilterViewer]= useState("Rol");
    const [roleFilterViewerEstado, setRoleFilterViewerEstado]= useState("Estado");

    //Enable react srping settings
    const transition= TransitionSettings(typeOfTable);

    //useMemo of users for the table
    const usersMemo = UsersMemoSettings(usersRedux, search, popperFiltersSelected, setTotalItems, sorting, currentPage, ITEMS_PER_PAGE, userState)

    //POPPER
    const handleClickPopper = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleClickPopperEstado = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElEstado(anchorElEstado ? null : event.currentTarget);
    };
    const openEstado = Boolean(anchorElEstado);
    const idEstado = openEstado ? 'simple-popper' : undefined;


    //SORT CHANGING STATES (recives asc, desc or wich collumn sort)
    const onSortingChange = (field:any) =>{
        const order = field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
        setSortingField(field);
        setSortingOrder(order);
        setSorting({field: field, order:order})
    }

    //SEARCH controller
    const onChangeSearch = (e:any) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };
    
    //Table checkboxes Individualy
    const individualCheck = (e:any) => {
        individualCheckLogic(e, itemsSelected, setItemsSelected)
    };
  
    //Table checkboxes Select All 
    const checkAll = (e:any)=>{
        checkAllLogic(e, itemsSelected, setItemsSelected)
    };
  
    //Handler of Select input in Modal ADD
    const [roleOrUser, setRoleOrUser]= useState("")
    const [newUser, setNewUser]= useState({names:"", email:"", last_name:"", second_last_name:"", username:"", number:""})

    //CheckList Handlers
    
    const [actions, setActions]=useState({BENEFICIARY:false, USERS:false, ROLES:false ,DOCTOR:false, HEALTH_CARE_CENTER:false, especialitys:false, MY_HEALTH:false, CLINIC_HISTORY:false, notifications:false, reports:false, passwords:false});
    const [newRole, setNewRole]= useState({name:"", description:""});
    const [functions, setFunctions]= useState({});
    const checkHandlerFather = (e:any, fatherOrSon:string) => {
        let templateToPost = templateRoles;
        let checked = e.target.checked;
        let name = e.target.name;
        let idInput = e.target.id;
        if(fatherOrSon==="father"){            
            if(checked===true){
                setActions({...actions, [name]:true})
                templateToPost.functions[ templateToPost.functions.findIndex((f:any)=>f.code===name)].status = true;
                setFunctions(templateToPost.functions)
            }
            if(checked===false){
                setActions({...actions, [name]:false});
                templateToPost.functions[ templateToPost.functions.findIndex((f:any)=>f.code===name)].status = false;
                setFunctions(templateToPost.functions)
                let fatherContainer = document.getElementById(name)
                let inputsInside = fatherContainer?.querySelectorAll('input')
                inputsInside?.forEach((input:any)=>{
                    input.checked= false
                })
            }
        }else if(fatherOrSon==="son"){
            if(checked===true){
                let containerFunction = templateToPost.functions[templateToPost.functions.findIndex((f:any)=>f.code===name)].options;
                let functionToChange = containerFunction[containerFunction.findIndex((f:any)=>f.icon===idInput)];
                functionToChange.status = true;
                setFunctions(templateToPost.functions)
            }
            if(checked===false){
                let containerFunction = templateToPost.functions[templateToPost.functions.findIndex((f:any)=>f.code===name)].options;
                let functionToChange = containerFunction[containerFunction.findIndex((f:any)=>f.icon===idInput)];
                functionToChange.status = false;
                setFunctions(templateToPost.functions)
            }
        }  
    }

    const [roleToEditState, setRoleToEditState]= useState(templateRoles)
    let copyOfRoleToEdit = roleToEditState;
    useEffect(()=>{setRoleToEditState(roleByIdToEdit)},[roleByIdToEdit])
    useEffect(()=>{setRoleToEditState(roleToEditState); },[roleToEditState])

    const checkHandlerFatherEdit = (e:any, fatherOrSon:string) =>{
        let templateToPost = roleToEditState;
        let checked = e.target.checked;
        let name = e.target.name;
        let idInput = e.target.id;

        if(fatherOrSon ==="father"){
            if(checked===true){
              setActions({...actions, [name]:true})
                templateToPost.functions[ templateToPost.functions.findIndex((f:any)=>f.code===name)].status = true;
                setFunctions(templateToPost.functions)
                setRoleToEditState(templateToPost)  
            }
            if(checked===false){
                setActions({...actions, [name]:false});
                templateToPost.functions[ templateToPost.functions.findIndex((f:any)=>f.code===name)].status = false;
                setFunctions(templateToPost.functions)
                setRoleToEditState(templateToPost)
                let fatherContainer = document.getElementById(name)
                let inputsInside = fatherContainer?.querySelectorAll('input')
                inputsInside?.forEach((input:any)=>{
                    input.checked= false;
                })
            }
        }else if(fatherOrSon==="son"){
            if(checked===true){
                templateToPost.functions[templateToPost.functions.findIndex((f:any)=>f.code===name)].options[templateToPost.functions[templateToPost.functions.findIndex((f:any)=>f.code===name)].options.findIndex((f:any)=>f.code===idInput)].status=true;
                setRoleToEditState((prevState:any)=>({...prevState, functions:[...prevState.functions, prevState.functions[prevState.functions.findIndex((f:any)=>f.code===name)].options[prevState.functions[prevState.functions.findIndex((f:any)=>f.code===name)].options.findIndex((f:any)=>f.code===idInput)].status= true  ]  }))

                setFunctions(templateToPost.functions)
            }
            if(checked===false){              
                templateToPost.functions[templateToPost.functions.findIndex((f:any)=>f.code===name)].options[templateToPost.functions[templateToPost.functions.findIndex((f:any)=>f.code===name)].options.findIndex((f:any)=>f.code===idInput)].status=false;
       
                setRoleToEditState((prevState:any)=>({...prevState, functions:[...prevState.functions, prevState.functions[prevState.functions.findIndex((f:any)=>f.code===name)].options[prevState.functions[prevState.functions.findIndex((f:any)=>f.code===name)].options.findIndex((f:any)=>f.code===idInput)].status= false  ]  }))
                setFunctions(templateToPost.functions)
            }
        }  
    }
    
    //Button in table Edit User
    const handleEditUser = (setOpenModalEdit:any, setUserSelected:any, phone:any, email:any, name:any, id:any) => {
        setOpenModalEdit(true);
        setUserSelected({nombre:name, phone:phone, email:email, id:id});
    };

    //Reset password
    const resetPassword = (userCode:string, name:string, email:string) => {
        setUserSelected({nombre:name, apellido:"", id:userCode, phone:"", email:email});
        setOpenModalResetPass(true)
    }

    return(
        <div>
        {usersRedux.length === 0 ? <div className='loader-container'><TailSpin color='#6767AD'></TailSpin></div> : <div id="usersContainerFather" className="users-container-father">
            <div className='title-container'>
                <h1 className='title-panel'>{typeOfTable==="users"? "Usuarios": "Roles"}</h1>
            </div>
            <div className="input-filters-add-container">
                    <div className="container-input">
                        <div>
                            <div>
                                <img className="input-search-icon" alt='searchIcon' src={lupa}></img>
                                <input placeholder='Buscar' onChange={onChangeSearch} value={search} className="search-input"></input>
                            </div>                         
                        </div>

                        <div>
                            <button className='add-button brigthHover' onClick={typeOfTable=== "users" ? ()=>handleOpenModalAddUser(setOpenModalAdd) : ()=>handleOpenModalAddRole(setOpenModalAddRole, userToken)}>{typeOfTable=== "users" ? "+ Nuevo Usuario" : "+ Nuevo Rol"}</button>
                        </div>
                    </div>
                    <div className={typeOfTable==="roles" ? "filters-selected-container visibilityOff":"filters-selected-container"}>
                        <div>
                            <p className='filters-selected'>Filtros seleccionados</p>
                        </div>
                        <div className='filters-active-container'>
                            <div className={anchorEl ? "active-filter-tag-clicked" :"active-filter-tag" }onClick={handleClickPopper}><p className="active-filter-tag-p">{roleFilterViewer} </p> <button className={anchorEl ? "active-filter-tag-button-clicked":"active-filter-tag-button"}><img alt='deleteIcon' src={filterButton}/></button></div>
                            <FiltersPopperRol rolesRedux={rolesRedux} idPopper={id} open={open} anchorEl={anchorEl} setPopperFiltersSelected={setPopperFiltersSelected} popperFiltersSelected={popperFiltersSelected} setRoleFilterViewer={setRoleFilterViewer} roleFilterViewer={roleFilterViewer} handleClickPopper={handleClickPopper}></FiltersPopperRol>
                            <div className={anchorElEstado ? "active-filter-tag-clicked" :"active-filter-tag" }onClick={handleClickPopperEstado}><p className="active-filter-tag-p">{roleFilterViewerEstado} </p> <button className={anchorElEstado ? "active-filter-tag-button-clicked":"active-filter-tag-button"}><img alt='deleteIcon' src={filterButton}/></button></div>
                            <FiltersPopperEstado idPopperEstado={idEstado} openEstado={openEstado} anchorElEstado={anchorElEstado} setPopperFiltersSelected={setPopperFiltersSelected} popperFiltersSelected={popperFiltersSelected} setRoleFilterViewerEstado={setRoleFilterViewerEstado} roleFilterViewerEstado={roleFilterViewerEstado} handleClickPopper={handleClickPopperEstado}></FiltersPopperEstado>
                        </div>                                                
                    </div>

                    <div className='buttonsAll-container'>
                        <div>
                            {itemsSelected.usersSelected.length=== 0 && <button className="buttonsAllNew visibilityOff"><img className="trash"src={trashNew} alt="deleteIcon" />eliminar</button>}                     
                            {itemsSelected.usersSelected.length!== 0 && !itemsSelected.usersSelected.some((u:any) => u.status === "Desactivado") && itemsSelected.usersSelected.some((u:any) => u.status === "Activado") && <div> <button className="buttonsAllNew" onClick={()=>desactiveMoreThanOne(itemsSelected.usersSelected, userToken)}><img className="trashNew"src={dontNew} alt="desactiveIcon" /></button></div>}
                            {itemsSelected.usersSelected.length!== 0 && itemsSelected.usersSelected.some((u:any) => u.status === "Desactivado") && !itemsSelected.usersSelected.some((u:any) => u.status === "Activado") && <div> <button className="buttonsAllNew"  onClick={()=>activeMorethanOne(itemsSelected.usersSelected, userToken)}><img className="checkAll" src={checkNew} alt="activateicon" /> </button></div>}
                            {itemsSelected.usersSelected.length!== 0 && itemsSelected.usersSelected.some((u:any) => u.status === "Desactivado") && itemsSelected.usersSelected.some((u:any) => u.status === "Activado") && <button className="buttonsAllNew visibilityOff"><img className="trashNew"src={trashNew} alt="deleteIcon" /></button>}
                        </div>
                        <div className="roles-users-container">
                            <div className={typeOfTable=== "users" ? "roles-users-subcontainer-upper" : "roles-users-subcontainer"}>
                                <p className={typeOfTable=== "users" ? "roles-users-text-selected" :"roles-users-text"} onClick={()=>setTypeOfTable("users")}>Usuarios</p>
                            </div>
                            <div className={typeOfTable=== "roles" ? "roles-users-subcontainer-upper" : "roles-users-subcontainer"}>
                                <p className={typeOfTable=== "roles" ? "roles-users-text-selected" :"roles-users-text"} onClick={()=>setTypeOfTable("roles")}>Roles</p>
                            </div>
                        </div>                                                
                    </div>
            </div>
            {transition(({opacity}:any, item)=>
            item==="roles" ? <RolesTable roleByIdToEdit={roleByIdToEdit} roleToEditState={roleToEditState} functions={functions} openModalAddRole={openModalAddRole} rolesFunctions={rolesFunctions} setOpenModalConfirmation2={setOpenModalConfirmation2} setOpenModalConfirmation={setOpenModalConfirmation} typeOfTable={typeOfTable} actions={actions} checkHandlerFather={checkHandlerFather}  checkHandlerFatherEdit={checkHandlerFatherEdit} newRole={newRole} setNewRole={setNewRole} goToEdit={goToEdit} opacity={opacity} search={search} popperFiltersSelected={popperFiltersSelected}  onSortingChange={onSortingChange} sortingField={sortingField} sortingOrder={sortingOrder} setOpenModalDesactive={setOpenModalDesactive} setOpenModalActive={setOpenModalActive} setOpenModalDelete={setOpenModalDelete} setGoToEdit={setGoToEdit} setOpenModalAddRole={setOpenModalAddRole}></RolesTable>: 
            
                <div className="table-and-pagination-container">
                    <animated.div style={{position: 'relative', width:'100%',opacity: opacity.to({  range: [1.0, 0.0], output: [1, 0] })}} >
                    <div className='table-container'>
                        <table id='data-table'>
                            <thead>
                                <tr className="tr-table-tittles">
                                    <th className="collumnCenterAlign" id="first-row-table"><input name="select_all" value="1" type="checkbox" onChange={(e:any)=>checkAll(e)}></input></th>   
                                    <th onClick={()=>onSortingChange("name")}>Nombre   {sortingField && sortingField === "name" && (
                                        <FontAwesomeIcon  icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}/>
                                    )}</th>
                                    <th onClick={()=>onSortingChange("role")}>Rol    {sortingField && sortingField === "role" && (
                                        <FontAwesomeIcon icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}/>
                                    )}</th>
                                    <th onClick={()=>onSortingChange("email")}>Email    {sortingField && sortingField === "email" && (
                                        <FontAwesomeIcon icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}/>
                                    )}</th>
                                    <th onClick={()=>onSortingChange("phone_number")}>Teléfono   {sortingField && sortingField === "phone_number" && (
                                        <FontAwesomeIcon icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}/>
                                    )}</th>
                                    <th onClick={()=>onSortingChange("last_login")}>Ultima actividad   {sortingField && sortingField === "last_login" && (
                                        <FontAwesomeIcon icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}/>
                                    )}</th>
                                    <th className="collumnCenterAlign" onClick={()=>onSortingChange("is_verified")}>Estado   {sortingField && sortingField === "is_verified" && (
                                        <FontAwesomeIcon icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}/>
                                    )}</th>
                                    <th className="collumnCenterAlign" id="last-row-table">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {usersMemo.length === 0 &&
                                
                                    <tr>
                                        <td >
                                            <p className="no-results-td-table">No hay resultados</p>
                                        </td>
                                    </tr>
                                }
                                {usersMemo && usersMemo.map((user:any)=>(
                            
                                    <tr key={user.id} id={user.id} className="" >
                                        <td className="collumnCenterAlign"><input type="checkbox" id={user.id} data-status={user.is_verified} onChange={(e:any)=>individualCheck(e.target)}></input></td>
                                        <td>{user.name}</td>
                                        <td>{user.role}</td>
                                        <td>{user.email}</td> 
                                        <td>{user.phone_number}</td>
                                        <td>{user.last_login}</td>
                                        <td className="collumnCenterAlign">{user.is_verified === "Activado" ? <button className='btn-activo'>Activado</button> : <button className='btn-inactivo'>Desactivado</button>}</td>
                                        <td className="collumnCenterAlign"><img className="dont brigthHover"src={user.is_verified === "Activado" ? dontNew : checkNew} alt="desactiveIcon" onClick={user.is_verified === "Activado" ? ()=>handleOpenModalDesactive(user.name, user.first_surname, user.id,setUserSelected, setOpenModalDesactive) : ()=>handleOpenModalActive(user.name, user.id, setUserSelected, setOpenModalActive)}/>  <img alt='editIcon' className="brigthHover" src={pencilNew} onClick={()=>handleEditUser(setOpenModalEdit, setUserSelected, user.phone_number, user.email, user.name, user.id )}/> <img alt='editIcon' onClick={()=>resetPassword(user.user_code, user.name, user.email)} className="brigthHover" src={resetPass}/> </td>
                                    </tr>
                            
                                ))}
                                
                            </tbody>
                        </table>
                    </div>
                </animated.div>
                <div className='pagination-container'>
                    <Pagination 
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page:any) => setCurrentPage(page)}/>
                </div>
                
            </div>
            
            )}
            
            <RolesModalConfirmation  setGoToEdit={setGoToEdit} setOpenModalConfirmation={setOpenModalConfirmation} setNewRole={setNewRole} setOpenModalAddRole={setOpenModalAddRole} openModalConfirmation={openModalConfirmation}></RolesModalConfirmation>
            <RolesModalConfirmation2 setGoToEdit={setGoToEdit} newRole={newRole} functions={functions} setOpenModalAddRole={setOpenModalAddRole} userToken={userToken} openModalConfirmation2={openModalConfirmation2} setOpenModalConfirmation2={setOpenModalConfirmation2}></RolesModalConfirmation2>
            <UsersModalAdd2 openModalAdd2={openModalAdd2} userToken={userToken} setOpenModalAdd2={setOpenModalAdd2} roleOrUser={roleOrUser} setNewUser={setNewUser} newUser={newUser}></UsersModalAdd2>
            <UsersModalAdd rolesRedux={rolesRedux} openModalAdd={openModalAdd} setOpenModalAdd2={setOpenModalAdd2} setOpenModalAdd={setOpenModalAdd} roleOrUser={roleOrUser} setRoleOrUser={setRoleOrUser}></UsersModalAdd>
            <UserModalDesactivate openModalDesactive={openModalDesactive} setOpenModalDesactive={setOpenModalDesactive} userSelected={userSelected} userToken={userToken}></UserModalDesactivate>
            <UserModalActive openModalActive={openModalActive} setOpenModalActive={setOpenModalActive} userSelected={userSelected} userToken={userToken}></UserModalActive>
            <UserModalDelete openModalDelete={openModalDelete} setOpenModalDelete={setOpenModalDelete} userSelected={userSelected}  userToken={userToken}></UserModalDelete>
            <UserModalEdit openModalEdit={openModalEdit} setOpenModalEdit={setOpenModalEdit} userSelected={userSelected} setUserSelected={setUserSelected} userToken={userToken}></UserModalEdit>
            <UsersModalResetPass userToken={userToken} userSelected={userSelected} openModalResetPass={openModalResetPass} setOpenModalResetPass={setOpenModalResetPass}/>
        </div>}
        <EspecialityToasts></EspecialityToasts></div>
        
    )
};

export default Users;