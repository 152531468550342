import { createStore,  compose, applyMiddleware  } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { rootReducer } from '../reducers/rootReducer'

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose ;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['login']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,
  composeEnhancers(
      applyMiddleware(thunk)
  ),

)


export const persistedStore = persistStore(store)
