import Modal from '@mui/material/Modal';
import axios from 'axios';
import { getSpecialitys } from '../../../actions/actions';
import { useDispatch } from 'react-redux';
import { succesVariable, errorDeLogin} from '../Toastifys/EspecialityToasts';

const EspecialitysModalEdit = ({openModalEditSpeciality, setOpenModalEditSpeciality, specialityToEdit, setSpecialityToEdit, userToken}:any) => {

    //Use dispatch hook
    const dispatch = useDispatch();
    //Edit the speciality button accept
    const editTheSpeciality = () => {
        const sendFetch = async() => {
            let postToSend = {id:specialityToEdit.id, name: specialityToEdit.name, code: specialityToEdit.code, description: specialityToEdit.description};
            await axios.put(`${process.env.REACT_APP_ORGANIZATION_AVE_DELETE_ESPECIALITY}`, postToSend, {headers:{"Authorization":`Bearer ${userToken}`}})
            .then((response)=>{
                succesVariable("La especialidad fue editada")
                setOpenModalEditSpeciality(false)
                dispatch(getSpecialitys(userToken));
            }).catch((error)=>{
                errorDeLogin("Error al editar la especialidad")
            })
        }
        sendFetch()
    }
    return(
        <Modal open={openModalEditSpeciality}
            onClose={()=>setOpenModalEditSpeciality(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                <div className="modal-edit-speciality-father-container">
                    <div className="modal-edit-speciality-title-container">
                        <p>{specialityToEdit.name}</p>
                    </div>
                    <div className="modal-edit-speciality-inputs-container">
                        <div>
                            <label>Nombre</label>
                            <input value={specialityToEdit.name} onChange={(e)=>setSpecialityToEdit({...specialityToEdit, name: e.target.value})}></input>
                        </div>
                        <div>
                            <label>Código</label>
                            <input value={specialityToEdit.code} maxLength={5} onChange={(e)=>setSpecialityToEdit({...specialityToEdit, code: e.target.value})}></input>
                        </div>
                        <div>
                            <label>Descripción</label>
                            <textarea value={specialityToEdit.description} onChange={(e)=>setSpecialityToEdit({...specialityToEdit, description: e.target.value})}></textarea>
                        </div>
                    </div>
                    <div className="modal-edit-speciality-buttons-container">
                        <button className='modal-btn-cancelar' onClick={()=>setOpenModalEditSpeciality(false)}>Cancelar</button>
                        <button className="modal-btn-aceptar" onClick={()=>editTheSpeciality()}>Aceptar</button>
                    </div>
                </div>
        </Modal>
    )
}

export default EspecialitysModalEdit
