import { errorDeLogin, succesVariable } from "../components/Toastifys/EspecialityToasts" 
import axios from "axios";
import { store } from "../../redux/configureStore";
import { getUsers } from "../../actions/actions";

 //Buttons ACTIVE  More than One
export const activeMorethanOne = async(usersArray:any, userToken:string) => {
    let postToSend = {is_verified: true};
    for(let i:any=0; i<usersArray.length;i++){
        console.log(i.id)
        const {data}:{data:any}= await axios.put(`${process.env.REACT_APP_ORGANIZATION_AVE_CHANGE_STATUS_USER_ADMIN}${usersArray[i].id}`, postToSend, {headers:{"Authorization":`Bearer ${userToken}`}}); 
        if(data.msg==="Se proceso correctamente"){
            continue
        }else{
            errorDeLogin("Error activando un usuario")
        }
    }
    store.dispatch(getUsers(userToken));
    succesVariable("Se han activado correctamente todos los usuarios")
};


//Buttons DESACTIVE More than one
export const desactiveMoreThanOne = async(usersArray:any, userToken:string)=>{
    let postToSend = {is_verified: false};
    for(let i:any=0; i<usersArray.length;i++){
        const {data}:{data:any}= await axios.put(`${process.env.REACT_APP_ORGANIZATION_AVE_CHANGE_STATUS_USER_ADMIN}${usersArray[i].id}`, postToSend, {headers:{"Authorization":`Bearer ${userToken}`}});
        if(data.msg==="Se proceso correctamente"){
            continue
        }else{
            errorDeLogin("Error desactivando un usuario")
        }
    }
    store.dispatch(getUsers(userToken));
    succesVariable("Se han desactivado correctamente todos los usuarios")
}

