import Modal from '@mui/material/Modal';
import axios from 'axios';
import { iconsFolder } from '../../../data/allIcons';
import { useDispatch, useSelector  } from 'react-redux';
import { getNotifications} from '../../../actions/actions';
import {  completeLosCampos, notificacionCreada, errorCreandoNotificacion, notificacionEditada,errorEditandoNotificacion, errorHabilitandoNotificacion, errorDeshabilitandoNotificacion, notificacionHabilitada, notificacionDeshabilitada } from '../Toastifys/EspecialityToasts';

const NotificationsModal = ({openModalNotifications,setOpenModalNotifications,goToEdit,notificationSelected,setNotificationSelected,variablesState,setOpenModalConfirmation}:any) => {

    //user token in redux state
    const userToken: any = useSelector((state: any) => state.login.logData.token);
    //Use dispatch hook
    const dispatch = useDispatch();
    //Modal select icon and paint the background
    const selecIcon = async (e:any, name:any) => {
        let divElement = e.currentTarget;
        let divs = await document.getElementsByClassName("item-icon");
        for(var i=0; i< divs.length; i++) {
            divs[i].classList.remove('selectedItemIcon')
        }
        
        divElement.classList.add('selectedItemIcon')
        setNotificationSelected({...notificationSelected, icon: name});
    };

    const createEditNotification = async () => {
        // handleClickGuardarModalNotifications(goToEdit, notificationSelected, setNotificationSelected)
        if(goToEdit){
            if(!notificationSelected.name || !notificationSelected.description || !notificationSelected.icon || !notificationSelected.when_notification){
                return completeLosCampos()
            }else{
                let postToSend = {name: notificationSelected.name, description: notificationSelected.description, when_notification: notificationSelected.when_notification, for_doctor: notificationSelected.for_doctor, for_beneficiary: notificationSelected.for_beneficiary, icon: notificationSelected.icon, type_user:(notificationSelected.for_doctor && notificationSelected.for_beneficiary ? "both" : !notificationSelected.for_doctor && notificationSelected.for_beneficiary? "ben" : "doc")};
                const {data}:{data:any} = await axios.put(`${process.env.REACT_APP_CONSULT_EDIT_NOTIFICATION}${notificationSelected.id}`, postToSend,{headers:{"Authorization":`Bearer ${userToken}`}});
                    if(data.msg === "Se guardo correctamente "){
                        notificacionEditada()
                        setOpenModalNotifications(false)
                        setTimeout(()=>{
                            dispatch(getNotifications(userToken))
                        }, 300);
                        return
                    }else{
                        errorEditandoNotificacion()
                        return
                    }
            }
        }else{
            if(!notificationSelected.name || !notificationSelected.description || !notificationSelected.icon || !notificationSelected.variables || !notificationSelected.when_notification){
                return completeLosCampos()
            }else{
                let postToSend = {name: notificationSelected.name, description: notificationSelected.description, when_notification: notificationSelected.when_notification, for_doctor: notificationSelected.for_doctor, for_beneficiary: notificationSelected.for_beneficiary, icon: notificationSelected.icon, type_user:(notificationSelected.for_doctor && notificationSelected.for_beneficiary ? "both" : !notificationSelected.for_doctor && notificationSelected.for_beneficiary? "ben" : "doc")};
                const {data}:{data:any} = await axios.post(`${process.env.REACT_APP_CONSULT_CREATE_NOTIFICATION}`, postToSend,{headers:{"Authorization":`Bearer ${userToken}`}});
                    if (data.msg === "Se guardo correctamente "){
                        notificacionCreada() 
                        setOpenModalNotifications(false)
                        setTimeout(()=>{
                            dispatch(getNotifications(userToken))
                        }, 300);
                        return
                    } else{
                        errorCreandoNotificacion();
                        return
                }
            }
        }
    };

    return(
        <Modal open={openModalNotifications}
        onClose={()=>setOpenModalNotifications(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <div className="father-Modal-notifications">
                <div className='modal-notifications-data-container'>
                    <div className="modal-notifications-title-container">
                        <p className="modal-notifications-title">{goToEdit===true ? "Editar notificación" : "Nueva notificación"}</p>
                    </div>
                    <div className="modal-notifications-left-and-rigth-container">               
                        <div className="modal-notifications-left-container">                               
                            <div className="label-line-modal-notifications">
                                <label className="label-notifications">Nombre</label>
                                <input placeholder='Nombre' value={notificationSelected.name} onChange={(e:any)=>setNotificationSelected({...notificationSelected, name: e.target.value})}></input>
                            </div>
                            <div className="label-line-modal-notifications">
                                <label className="label-notifications">Descripción</label>
                                <textarea className="notifications-textarea" placeholder='Descripción' value={notificationSelected.description} onChange={(e:any)=>setNotificationSelected({...notificationSelected, description: e.target.value})}></textarea>
                            </div>
                            <div className="label-line-modal-notifications3">
                                <label className="label-notifications">N° variables</label><p  className="label-notifications margin-left-20">{variablesState}</p>
                            </div>
                            <div className="label-line-modal-notifications">
                                <label className="label-notifications">Instancia</label>
                                <input placeholder='Instancia' value={notificationSelected.when_notification} onChange={(e:any)=>setNotificationSelected({...notificationSelected, when_notification: e.target.value})}></input>
                            </div>
                            <div className="label-line-modal-notifications">
                                <label className="label-notifications">Usuario</label>
                                <div className="row">
                                    <div className='label-checkboxes-notifications'>
                                        <input type="checkbox" checked={notificationSelected.for_beneficiary} value={notificationSelected.for_beneficiary} onChange={(e:any)=>setNotificationSelected({...notificationSelected, for_beneficiary: !notificationSelected.for_beneficiary})}></input>
                                        <label className="label-notifications margin-left-20">Beneficiario</label> 
                                    </div>
                                    <div className='label-checkboxes-notifications'>
                                        <input type="checkbox"  checked={notificationSelected.for_doctor} value={notificationSelected.for_doctor} onChange={(e:any)=>setNotificationSelected({...notificationSelected, for_doctor: !notificationSelected.for_doctor})}></input>
                                        <label className="label-notifications margin-left-20">Médico</label>
                                    </div>
                                </div>                                   
                            </div>
                        </div>
                        <div className="modal-notifications-rigth-container">
                            <div className="label-line-modal-notifications4">
                                <label className="label-notifications">Ícono</label>
                                {iconsFolder.map((i:any,index:any)=>(
                                    i.name === notificationSelected.icon ? 
                                            <div key={index} className="item-icon selectedItemIcon" >
                                                <img alt='icon' src={`${i.src}`} className="icons-modal"/>
                                                <p className="text-icon">{i.name}</p>
                                            </div>
                                    :
                                            null
                                ))}
                            </div>
                            <div className="label-icons-notifications">                                   
                                {iconsFolder.map((i:any,index:any)=>(
                                    i.name === notificationSelected.icon ? 
                                                
                                            <div key={index} className="item-icon selectedItemIcon" onClick={(e)=>selecIcon(e, i.name)}>
                                                <img alt='icon' src={`${i.src}`} className="icons-modal"/>
                                                <p className="text-icon">{i.name}</p>
                                            </div>
                                    :
                                                <div key={index} className="item-icon" onClick={(e)=>selecIcon(e, i.name)}>
                                                    <img alt='icon' src={`${i.src}`} className="icons-modal"/>
                                                    <p className="text-icon">{i.name}</p>
                                                </div>
                                ))}
                                        
                            </div>
                        </div>
                    </div>    
                    <div className="modal-notifications-buttons-container">
                        <button className='btn-cancelar' onClick={()=>setOpenModalConfirmation(true)}>Cancelar</button>
                        <button className='btn-guardar' onClick={()=>createEditNotification()}>Guardar</button>
                    </div>
                </div>
            </div> 
        </Modal>
    )
}

export default NotificationsModal;