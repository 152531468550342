//Styles
import '../../../Styles/Users.css';
import '../../../Styles/Beneficiaries.css';
//Hooks
import { useState, useEffect, useMemo } from 'react';
import {useTransition, animated} from 'react-spring';
//Data HardCoded
import usersData from '../../../data/tableHardCodedDoc.json';
//Components
import { DisplayInformationHeader } from './DisplayInformationHeader';
import Pagination from '../Pagination';
import "../FontawsomeIcons/Index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { TailSpin } from  'react-loader-spinner'
import BeneficiariesModalDesactive from './BeneficiariesModalDesactivate';
//SVG and Images
import trashNew from '../../../svg/trashNew.svg';
import dontNew from '../../../svg/dontNew.svg';
import checkNew from '../../../svg/checkNew.svg';
import lupa from '../../../svg/Search.svg';
import filterButton from '../../../svg/filterButton.svg';
import resetPass from '../../../svg/resetPass.svg';
//Functions
import {handleOpenModalDesactive,handleCloseModalDesactive, handleOpenModalDelete, handleCloseModalDelete, handleOpenModalActive} from '../../services/useFunctions';
import { useSelector, useDispatch } from "react-redux";
import { FiltersPopperCobertura } from '../FiltersPopper/FiltersPopperCobertura';
import { AnyObject } from 'immer/dist/internal';
import { cleanBeneficiaryById, getBeneficiaryById, getBeneficiaryList} from '../../../actions/actions';
import { FiltersPopperEstadoBeneficiaries } from '../FiltersPopper/FilterPopperEstadoBeneficiaries';
import BeneficiariesModlaActivate from './BeneficiariesModalActivate';
import { EspecialityToasts } from '../Toastifys/EspecialityToasts';
import BeneficiariesModalDelete from './BeneficiariesModalDelete';
import { DisplayInformationBody } from './DisplayInformationBody';
import UsersModalResetPass from '../Users/UsersModalResetPass';



const Beneficiaries = () =>{

    //UseDispatch (activate the actions of redux)
    const dispatch = useDispatch();
    //Fetch the users data
    const [userState, setUserState]:any[] = useState(usersData);
    const fetchUsers = () =>{
        setUserState(beneficiariesRedux)    
    };
    //UseSelector of Users state in redux (Brings the state of redux to the component)
    const beneficiariesRedux:any = useSelector((state:any) => state.users.beneficiaries);
     //UseSelector of User selected by id when click a row in the table
     const beneficiaryByIdRedux:any = useSelector((state:any) => state.users.beneficiaryById);
    //user token in redux state
    const userToken: any = useSelector((state: any) => state.login.logData.token);
    //Fetch Data
    useEffect(()=>{
        dispatch(getBeneficiaryList(userToken))
        fetchUsers(); 
    }, []);

    //Change color of Row when you check the checkbox
    const changeColor = () =>{
        let dataTable:any = document.getElementById('data-table');
        let inputs:any = dataTable?.querySelectorAll('tbody>tr>td>input');

        inputs?.forEach((input:any)=>{
            if(input.checked){
                let parentRow = input.parentNode?.parentNode
                parentRow.classList.add("bg-selected")
            }
            if (!input.checked){
                let parentRow = input.parentNode?.parentNode
                parentRow.classList.remove("bg-selected")
            }
        })
        
    };
 
    //MODAL STATES
    //Open close modal (and hidde the HUD)
    const [openModalDesactive, setOpenModalDesactive] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalActive, setOpenModalActive] = useState(false);
    const [userSelected, setUserSelected] = useState({nombre:"", apellido:"", id:"", email:""});

    //STATES
    const [totalItems, setTotalItems] =useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE =  9;
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({field:"", order:""});
    const [sortingOrder, setSortingOrder] = useState("asc");
    const [sortingField, setSortingField] =useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElEstado, setAnchorElEstado] = useState<null | HTMLElement>(null);
    const [popperFiltersSelected, setPopperFiltersSelected] = useState([]);
    const [itemsSelected, setItemsSelected] = useState<AnyObject>({usersSelected: []});
    const [typeOfTable, setTypeOfTable]= useState("users"); 
    const [roleFilterViewer, setRoleFilterViewer]= useState("Cobertura");
    const [roleFilterViewerEstado, setRoleFilterViewerEstado]= useState("Estado");
    const [openModalResetPass, setOpenModalResetPass] = useState(false);

    
    //useMemo of users for the table
    const usersMemo = useMemo(()=>{
        let data= beneficiariesRedux;
        let mappedData:any= data.map((u:any)=>{
            if(u.validated === "1")u.validated="Activado";
            if(u.validated === "0")u.validated="Desactivado";
            if(Array.isArray(u.coberturas)){
                let aux = u.coberturas;
                let final = "";
                let coberturaMapped= aux.map((c:any)=>{final= final + ` ${c.name}`});
                u.coberturaString= final;
            }else if (u.coberturas === null){
                u.coberturaString="No tiene coberturas";
            }
            return u
        });
        let computedUsers = mappedData;
        if(search.length){
            computedUsers = computedUsers.filter(
                (user:any) => 
                    user.name.toLowerCase().includes(search.toLowerCase()) ||
                    user.coberturaString.toLowerCase().includes(search.toLowerCase()) ||
                    user.validated.toLowerCase() === (search.toLowerCase())
            )
        }
        if(popperFiltersSelected){
            popperFiltersSelected.forEach((e:any)=>{
                return computedUsers = computedUsers.filter(
                    (user:any) =>
                        user[e.firstFilter].includes(e.secondFilter) 
                )
            })
        }
        setTotalItems(computedUsers.length);
        //sorting Users
        if (sorting.field){
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedUsers = computedUsers.sort((a:any, b:any)=> reversed * a[sorting.field]?.localeCompare(b[sorting.field]))
        };
        //CURRENT PAGES SLICE
        return computedUsers.slice( (currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE );
    },[userState, currentPage, search, sorting, popperFiltersSelected, beneficiariesRedux]);

    //POPPER
    const handleClickPopper = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleClickPopperEstado = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElEstado(anchorElEstado ? null : event.currentTarget);
    };
    const openEstado = Boolean(anchorElEstado);
    const idEstado = openEstado ? 'simple-popper' : undefined;


    //SORT CHANGING STATES (recives asc, desc or wich collumn sort)
    const onSortingChange = (field:any) =>{
        const order = field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
        setSortingField(field);
        setSortingOrder(order);
        setSorting({field: field, order:order})
    }

    //SEARCH controller
    const onChangeSearch = (e:any) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };
    

    //Table checkboxes Individualy
    const individualCheck = (target:any) =>{
        if(target.checked=== true){
            if(!itemsSelected.usersSelected.includes(target.id)){
                setItemsSelected((prevState) => ({usersSelected: [...prevState.usersSelected, {id:target.id, status: target.dataset.status}]}))
                changeColor()                 
            }
        }
        if(target.checked=== false){
                    setItemsSelected((prevState)=> ({usersSelected: [...prevState.usersSelected.filter((u:any)=> u.id !== target.id)] }))
                    changeColor()   
        }

    };
    //Table checkboxes Select All 
    const checkAll = (e:any) => {
        let dataTable = document.getElementById('data-table');
        let inputs:any = dataTable?.querySelectorAll('tbody>tr>td>input');
        if(e.target.checked===true){
            inputs.forEach((input:any)=>{
                input.checked = true;
                changeColor();
                individualCheck(input);
            });
        };
        if(e.target.checked ===false){
            inputs.forEach((input:any)=>{
                input.checked = false;
                changeColor();
                individualCheck(input);
            })
        };
    };

    //CheckList Handlers
    const [actions, setActions]=useState({beneficiary:false, medics:false, atentionsCenters:false, especialitys:false, miSalud:false, historyClinic:false, notifications:false, reports:false, passwords:false})
    const checkHandlerFather = (e:any) => {
        let checked = e.target.checked;
        let name = e.target.name
        if(checked===true){
            setActions({...actions, [name]:true})
        }
        if(checked===false){
            setActions({...actions, [name]:false})
        }
    }

    //Use Transitions allow the react_spring library
    const transition = useTransition(beneficiariesRedux.length, {
        from: {opacity: 0 },
        enter: {opacity:1},
        leave: {},
        config: {duration: 100},
    });

    //Select a beneficiarie to display the info 
    const selectBeneficiarieToDispaly = (e:any) => {
        let dataTable:any = document.getElementById('data-table');
        let teErres:any = dataTable?.querySelectorAll('tbody>tr');
        let rowId = e.parentNode.id

        if(e.nodeName === "TD"){
            if(String(rowId) === String(beneficiaryByIdRedux.id_beneficiary)){
                teErres.forEach((tr:any)=>{
                    if(tr.id === rowId){
                        tr.classList.remove("bg-selected")
                    }                                           
                }) 
                dispatch(cleanBeneficiaryById())
            }else{
                dispatch(getBeneficiaryById(userToken, rowId));
                teErres?.forEach((tr:any)=>{
                    if(tr.id === rowId){
                        tr.classList.add("bg-selected")
                    }else {
                        tr.classList.remove("bg-selected")
                    }
                    
                })   
            } 
        }
    };


    //Reset password
    const resetPassword = (userCode:string, name:string) => {
        setUserSelected({nombre:name, apellido:"", id:userCode, email:""});
        setOpenModalResetPass(true)
    }

    return(
        <div>
         {transition(({opacity}:any, item)=>
         item === 0 ? <div className='loader-container'><TailSpin color='#6767AD'></TailSpin></div> :
         <animated.div style={{ opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })}}>
            <div id="usersContainerFather" className="users-container-father">
            <div className='title-container'>
                <h1 className='title-panel'>Beneficiarios</h1>
            </div>

            <div className="input-filters-add-container">
                
                    <div className="container-input">
                        <div className="filters-selected-container">
                            <div className='filters-active-container'>
                                <div className={anchorEl ? "active-filter-tag-clicked" :"active-filter-tag" }onClick={handleClickPopper}><p className="active-filter-tag-p">{roleFilterViewer} </p> <button className={anchorEl ? "active-filter-tag-button-clicked":"active-filter-tag-button"}><img alt='deleteIcon' src={filterButton}/></button></div>
                                <FiltersPopperCobertura userToken={userToken} idPopper={id} open={open} anchorEl={anchorEl} setPopperFiltersSelected={setPopperFiltersSelected} popperFiltersSelected={popperFiltersSelected} setRoleFilterViewer={setRoleFilterViewer} roleFilterViewer={roleFilterViewer} handleClickPopper={handleClickPopper}></FiltersPopperCobertura>
                                <div className={anchorElEstado ? "active-filter-tag-clicked" :"active-filter-tag" }onClick={handleClickPopperEstado}><p className="active-filter-tag-p">{roleFilterViewerEstado} </p> <button className={anchorElEstado ? "active-filter-tag-button-clicked":"active-filter-tag-button"}><img alt='deleteIcon' src={filterButton}/></button></div>
                                <FiltersPopperEstadoBeneficiaries idPopperEstado={idEstado} openEstado={openEstado} anchorElEstado={anchorElEstado} setPopperFiltersSelected={setPopperFiltersSelected} popperFiltersSelected={popperFiltersSelected} setRoleFilterViewerEstado={setRoleFilterViewerEstado} roleFilterViewerEstado={roleFilterViewerEstado} handleClickPopper={handleClickPopperEstado}></FiltersPopperEstadoBeneficiaries>
                            </div>                                                
                        </div>
                        <div>
                            <img className="input-search-icon" alt='searchIcon' src={lupa}></img>
                            <input placeholder='Buscar' onChange={onChangeSearch} value={search} className="search-input"></input>
                        </div>                         
                        
                    </div>
                    

                    <div className='buttonsAll-container'>
                        <div>
                            {itemsSelected.usersSelected.length=== 0 && <button className="buttonsAllNew visibilityOff"><img className="trash"src={trashNew} alt="deleteIcon" />eliminar</button>}                     
                            {itemsSelected.usersSelected.length!== 0  && <button className="buttonsAllNew"><img className="trashNew"src={trashNew} alt="deleteIcon" /></button> }
                            {itemsSelected.usersSelected.length!== 0 && !itemsSelected.usersSelected.some((u:any) => u.status === "inactivo") && itemsSelected.usersSelected.some((u:any) => u.status === "activo") && <button className="buttonsAllNew"><img className="trash"src={trashNew} alt="deleteIcon" /></button> && <button className="buttonsAllNew"><img className="trashNew"src={dontNew} alt="desactiveIcon" /></button>}
                            {itemsSelected.usersSelected.length!== 0 && itemsSelected.usersSelected.some((u:any) => u.status === "inactivo") && !itemsSelected.usersSelected.some((u:any) => u.status === "activo") && <button className="buttonsAllNew"><img className="trash"src={trashNew} alt="deleteIcon" /></button> && <button className="buttonsAllNew"><img className="checkAll" src={checkNew} alt="activateicon" /> </button>}
                        </div>                                              
                    </div>
            </div>

            
            <div className="table-and-pagination-container">
                <div className="table-display-container">
                    <div className='table-container-beneficiaries'>
                        <table id='data-table'>
                            <thead>
                                <tr className="tr-table-tittles">
                                    <th className="collumnCenterAlign" id="first-row-table"><input name="select_all" value="1" type="checkbox" onChange={(e:any)=>checkAll(e)}></input></th>   
                                    <th onClick={()=>onSortingChange("name")}>Nombre   {sortingField && sortingField === "name" && (
                                        <FontAwesomeIcon icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}/>
                                    )}</th>
                                    <th onClick={()=>onSortingChange("coberturaString")}>Cobertura   {sortingField && sortingField === "coberturaString" && (
                                        <FontAwesomeIcon icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}/>
                                    )}</th>
                                    <th onClick={()=>onSortingChange("cuil")}>Cuil   {sortingField && sortingField === "cuil" && (
                                        <FontAwesomeIcon icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}/>
                                    )}</th>
                                    <th className="collumnCenterAlign" onClick={()=>onSortingChange("validated")}>Estado   {sortingField && sortingField === "validated" && (
                                        <FontAwesomeIcon icon={sortingOrder === "asc" ? "arrow-down" : "arrow-up"}/>
                                    )}</th>
                                    <th className="collumnCenterAlign" id="last-row-table">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {usersMemo.length === 0 &&
                                
                                    <tr>
                                        <td >
                                            <p className="no-results-td-table">No hay resultados</p>
                                        </td>
                                    </tr>
                                }
                                {usersMemo && usersMemo.map((user:any)=>(
                            
                                    <tr key={user.id} id={user.id} className="tr-beneficiary-row" onClick={(e)=>selectBeneficiarieToDispaly(e.target)}>
                                        <td className="collumnCenterAlign"><input type="checkbox" id={user.id} data-status={user.status} onChange={(e:any)=>individualCheck(e.target)}></input></td>
                                        <td>{user.name}</td>
                                        <td>{user.coberturaString}</td>
                                        <td>{user.cuil}</td>
                                        <td className="collumnCenterAlign">{user.validated === "Activado" ? <button className='btn-activo'>Activado</button> : <button className='btn-inactivo'>Desactivado</button>}</td>
                                        <td className="collumnCenterAlign"><img className="dont brigthHover"src={user.validated === "Activado" ? dontNew : checkNew} alt="desactiveIcon" onClick={user.validated === "Activado" ? ()=>handleOpenModalDesactive(user.name, user.first_surname, user.id,setUserSelected, setOpenModalDesactive) : ()=>handleOpenModalActive(user.name, user.id, setUserSelected, setOpenModalActive)}/> <img className="trashNew brigthHover"src={trashNew} alt="deleteIcon" onClick={()=>handleOpenModalDelete(user.name, user.id, setUserSelected, setOpenModalDelete)}/> <img alt='resetPassword' className="brigthHover" src={resetPass} onClick={()=>resetPassword(user.user_code, user.name)}/></td>
                                    </tr>
                            
                                ))}
                                
                            </tbody>
                        </table>
                    </div>
                    <div className="display-information-container-ben">
                            <DisplayInformationHeader beneficiaryByIdRedux={beneficiaryByIdRedux}></DisplayInformationHeader>
                            <DisplayInformationBody beneficiaryByIdRedux={beneficiaryByIdRedux}></DisplayInformationBody>
                    </div>
                </div>
                <div className='pagination-container'>
                    <Pagination 
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page:any) => setCurrentPage(page)}/>
                </div>
            </div>
    
            <UsersModalResetPass userToken={userToken} userSelected={userSelected} openModalResetPass={openModalResetPass} setOpenModalResetPass={setOpenModalResetPass}/>
            <BeneficiariesModalDelete userSelected={userSelected} userToken={userToken} setOpenModalDelete={setOpenModalDelete} openModalDelete={openModalDelete}></BeneficiariesModalDelete>   
            <BeneficiariesModalDesactive openModalDesactive={openModalDesactive} setOpenModalDesactive={setOpenModalDesactive} userSelected={userSelected} userToken={userToken} ></BeneficiariesModalDesactive>
            <BeneficiariesModlaActivate userSelected={userSelected} userToken={userToken} setOpenModalActive={setOpenModalActive} openModalActive={openModalActive}></BeneficiariesModlaActivate>
            <EspecialityToasts></EspecialityToasts>    
            </div>
         </animated.div>
         )}   
        </div>
        
    )
};

export default Beneficiaries;